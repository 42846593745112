import React, { FC } from 'react';
import { IconProps } from './types';

export const Emoji: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 24 24"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="sentiment_satisfied-24px" opacity="0.544">
                <path
                    id="Path_3759"
                    data-name="Path 3759"
                    d="M0,0H24V24H0Z"
                    fill="none"
                />
                <circle
                    id="Ellipse_101"
                    data-name="Ellipse 101"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(14 8)"
                    fill="currentColor"
                />
                <circle
                    id="Ellipse_102"
                    data-name="Ellipse 102"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(7 8)"
                    fill="currentColor"
                />
                <path
                    id="Path_3760"
                    data-name="Path 3760"
                    d="M12,16a4,4,0,0,1-3.45-2H6.88a5.495,5.495,0,0,0,10.24,0H15.45A4,4,0,0,1,12,16ZM11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default Emoji;
