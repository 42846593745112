import React, { useState } from 'react';
import styled from 'styled-components';
import { useTopBets } from './configs/store/state';
import { TOP_LIST_FILTER_INDEXES, TOP_LIST_INDEXES } from './configs/tools';
import OddsList from './lists/Odds.list';
import WinningsList from './lists/Winnings.list';
import MultiplierCashOutList from './lists/MultiplierCashOut.list';
import TopBetsActions from './components/TopBetsActions';

const StyledCurrentList = styled.div`
    background: '${props => props.theme.colors.bgMarine}';
`;

const StyledScrollableTable = styled.div`
    height: calc(100vh - 225px);
    overflow: auto;
    margin-top: 8px;
`;

const TopBetsList = () => {
    const [active, setActive] = useState(TOP_LIST_INDEXES.TOP_ODDS);
    const [activeFilter, setActiveFilter] = useState(
        TOP_LIST_FILTER_INDEXES.DAY
    );

    const topBets = useTopBets();

    return (
        <StyledCurrentList>
            <TopBetsActions
                active={active}
                setActive={setActive}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
            />
            <StyledScrollableTable>
                {active === TOP_LIST_INDEXES.TOP_ODDS && (
                    <OddsList
                        items={topBets.odds}
                        selectedFilter={activeFilter}
                    />
                )}
                {active === TOP_LIST_INDEXES.TOP_WINNINGS && (
                    <WinningsList
                        items={topBets.winnings}
                        selectedFilter={activeFilter}
                    />
                )}
                {active === TOP_LIST_INDEXES.TOP_CASH_OUT && (
                    <MultiplierCashOutList
                        items={topBets.multiCashOut}
                        selectedFilter={activeFilter}
                    />
                )}
            </StyledScrollableTable>
        </StyledCurrentList>
    );
};

export default TopBetsList;
