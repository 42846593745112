import React from 'react';
import BoxContainer from '../../components/BoxContainer/BoxContainer';
import { useGameHistory } from '../../config/store/state/app.state';
import ResultBox from '../../components/ResultBox/ResultBox';
import styled from 'styled-components';

const StyledOddsList = styled.div`
    height: 45px;
    position: relative;
    margin: 4px 10px;
`;

const StyledBoxContainer = styled(BoxContainer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const OddsListContent = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
`;

const OddsList = () => {
    const gameHistory = useGameHistory();

    return (
        <StyledOddsList>
            <StyledBoxContainer>
                <OddsListContent>
                    {gameHistory.map((game, index) => {
                        return <ResultBox key={game.ID} game={game} />;
                    })}
                </OddsListContent>
            </StyledBoxContainer>
        </StyledOddsList>
    );
};

export default OddsList;
