import React, { FC } from 'react';
import { IconProps } from './types';

export const ArrowRight: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 5.882 9.764"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                data-name="chevron-right (3)"
                d="M9,12.936l3.468-3.468L9,6"
                transform="translate(-7.586 -4.586)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};

export default ArrowRight;
