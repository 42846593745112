import { takeEvery } from 'redux-saga/effects';
import {
    ACTIONS,
    CashedOutPlayerParams,
    PlayerCashedOutParams,
    PlayerRegisteredParams,
    PlayerUnregisteredParams,
} from '../actions/app.actions';
import { put, delay } from 'redux-saga/effects';
import { ADD_MESSAGE } from '../../../features/errorMessages/store/actions';
import { KEYWORDS } from '../../language/keywords';
import {
    PLAY_BET_SOUND,
    PLAY_WIN_SOUND,
    STOP_BET_SOUND,
    STOP_WIN_SOUND,
} from '../../../features/audioPlayer/store/actions';
import {
    LIKE_CHAT_MESSAGE,
    SEND_CHAT_MESSAGE,
} from '../../../features/chat/store/actions';

export function* handleMessageLike(params: any) {
    yield takeEvery(LIKE_CHAT_MESSAGE, (action: any) => {
        params.socket.send(
            JSON.stringify({
                Message: 'SendLike',
                MessageID: action.MessageId,
            })
        );
    });
}

export function* handleMessageSend(params: any) {
    yield takeEvery(SEND_CHAT_MESSAGE, (action: any) => {
        params.socket.send(
            JSON.stringify({ Message: 'SendMessage', ...action.payload })
        );
    });
}

export function* handlePlayerRegister(params: any) {
    yield takeEvery(ACTIONS.REGISTER, (action: any) => {
        params.socket.send(
            JSON.stringify({ Message: 'Register', ...action.payload })
        );
    });
}

function* displayRegisterSuccessMessage() {
    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.USER_SUCCESSFULLY_REGISTERED,
            status: 'success',
        },
    });
    yield put({
        type: PLAY_BET_SOUND,
    });
    yield delay(500);
    yield put({
        type: STOP_BET_SOUND,
    });
}

export function* handlePlayerRegisterSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_REGISTERED,
        (action: {
            type: ACTIONS.PLAYER_REGISTERED;
            payload: PlayerRegisteredParams;
        }) => {
            if (action.payload.YouRegisteredOnGame) {
                return displayRegisterSuccessMessage();
            }
        }
    );
}

function* displayUnregisterSuccessMessage() {
    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.USER_SUCCESSFULLY_UNREGISTERED,
            status: 'success',
        },
    });
}

export function* handlePlayerUnregisterSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_UNREGISTERED,
        (action: {
            type: ACTIONS.PLAYER_UNREGISTERED;
            payload: PlayerUnregisteredParams;
        }) => {
            if (action.payload.YouUnRegisteredOnGame) {
                return displayUnregisterSuccessMessage();
            }
        }
    );
}

function* displayCashOutSuccessMessage(payload?: CashedOutPlayerParams) {
    let profit = 0;
    if (payload) {
        profit = payload.BuyIn * payload.Multiplier;
    }

    yield put({
        type: ADD_MESSAGE,
        error: {
            keyword: KEYWORDS.USER_SUCCESSFULLY_CASHED_OUT,
            message: `${profit.toFixed(2)} ₾`,
            status: 'success',
        },
    });
    yield put({
        type: PLAY_WIN_SOUND,
    });
    yield delay(500);
    yield put({
        type: STOP_WIN_SOUND,
    });
}

export function* handlePlayerCashOutSuccess() {
    yield takeEvery(
        ACTIONS.PLAYER_CASHED_OUT,
        (action: {
            type: ACTIONS.PLAYER_CASHED_OUT;
            payload: PlayerCashedOutParams;
        }) => {
            if (action.payload.YouCashedOut) {
                return displayCashOutSuccessMessage(
                    action.payload.YouCashedOut.CashedOutPlayer
                );
            }
        }
    );
}

export function* handlePlayerUnregister(params: any) {
    yield takeEvery(ACTIONS.UNREGISTER, (action: any) => {
        params.socket.send(JSON.stringify(action.payload));
    });
}

export function* handlePlayerCashOut(params: any) {
    yield takeEvery(ACTIONS.CASH_OUT, (action: any) => {
        params.socket.send(JSON.stringify(action.payload));
    });
}
