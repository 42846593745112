import React, { FC } from 'react';
import styled from 'styled-components';

const StyledRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: #ffffff08;
    }
`;

export const TableRow: FC<{
    className?: string;
    innerRef?: any;
}> = ({ children, className }) => {
    return <StyledRow className={className}>{children}</StyledRow>;
};
