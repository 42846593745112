import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ChatIcon from '../../../components/Icons/Chat';
import { useToggleOpenChatService } from '../store/services';

const ChatHeaderContainer = styled(motion.div)`
    position: relative;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.sea};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 16px;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const IconContainer = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    color: ${props => props.theme.colors.white};
`;

const ClosedChat: FC = () => {
    const handleOpen = useToggleOpenChatService();

    return (
        <ChatHeaderContainer onClick={() => handleOpen(true)}>
            <IconContainer>
                <ChatIcon />
            </IconContainer>
        </ChatHeaderContainer>
    );
};

export default ClosedChat;
