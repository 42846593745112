import { InitializeBetsParams } from './store/actions';
import { ListStatus } from '../../../../constants/interfaces/List';
import { formatUsername } from '../../../../helpers/functions/format-username';

export const mergeBets = (
    lastBets: InitializeBetsParams[],
    lastBetsCashedOut: InitializeBetsParams[]
) => {
    return lastBets
        .map(
            bets =>
                lastBetsCashedOut.find(
                    coBets =>
                        coBets.PanelIndex === bets.PanelIndex &&
                        coBets.Player.I === bets.Player.I
                ) || bets
        )
        .concat(
            lastBetsCashedOut.filter(
                coBets =>
                    !lastBets.find(
                        bets =>
                            coBets.PanelIndex === bets.PanelIndex &&
                            coBets.Player.I === bets.Player.I
                    )
            )
        );
};

export const mapCurrentBetsValues = (
    lastBets: InitializeBetsParams[],
    lastBetsCashedOut: InitializeBetsParams[]
) => {
    const lastGamePlayers = mergeBets(lastBets, lastBetsCashedOut);

    return lastGamePlayers.map(bet => {
        return {
            username: formatUsername(bet.Player.N),
            id: bet.Player.I,
            bet: bet.BuyIn,
            status: bet.Multiplier ? ListStatus.WON : ListStatus.DEFAULT,
            odds: bet.Multiplier,
            profit:
                bet.Multiplier &&
                Number((bet.Multiplier * bet.BuyIn).toFixed(2)),
            PanelIndex: bet.PanelIndex,
        };
    });
};

export const mapLastBetsValues = (
    lastBets: InitializeBetsParams[],
    lastBetsCashedOut: InitializeBetsParams[]
) => {
    const lastGamePlayers = mergeBets(lastBets, lastBetsCashedOut);

    return lastGamePlayers.map(bet => {
        return {
            username: formatUsername(bet.Player.N),
            id: bet.Player.I,
            bet: bet.BuyIn,
            status: bet.Multiplier ? ListStatus.WON : ListStatus.LOST,
            odds: bet.Multiplier,
            profit:
                bet.Multiplier &&
                Number((bet.Multiplier * bet.BuyIn).toFixed(2)),
            PanelIndex: bet.PanelIndex,
        };
    });
};
