import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

export interface AudioPlayerState {
    playBetSound: boolean;
    playButtonSound: boolean;
    playWinSound: boolean;
}

export type AudioPlayerStateSelector = (state: any) => AudioPlayerState;

export const AudioPlayerStateSelectorContext = createContext<
    AudioPlayerStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useAudioPlayerState(): AudioPlayerState {
    return useSelector(useContext(AudioPlayerStateSelectorContext));
}
