export const PLAY_BET_SOUND = 'PLAY_BET_SOUND';
export const STOP_BET_SOUND = 'STOP_BET_SOUND';
export const PLAY_BUTTON_SOUND = 'PLAY_BUTTON_SOUND';
export const STOP_BUTTON_SOUND = 'STOP_BUTTON_SOUND';
export const PLAY_WIN_SOUND = 'PLAY_WIN_SOUND';
export const STOP_WIN_SOUND = 'STOP_WIN_SOUND';

export function playButtonSound() {
    return {
        type: PLAY_BUTTON_SOUND,
    };
}

export function stopButtonSound() {
    return {
        type: STOP_BUTTON_SOUND,
    };
}
