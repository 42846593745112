import React, { FC } from 'react';
import { IconProps } from './types';

export const Security: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 12.439 14.803"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                fill="currentColor"
                d="M29.535 3.812c-.008-.4-.015-.777-.015-1.143a.518.518 0 0 0-.52-.518 7.144 7.144 0 0 1-5.31-2 .518.518 0 0 0-.722 0 7.143 7.143 0 0 1-5.309 2 .518.518 0 0 0-.518.518c0 .365-.007.743-.015 1.143-.071 3.719-.168 8.813 6.033 10.963a.518.518 0 0 0 .339 0c6.202-2.15 6.108-7.244 6.037-10.963z"
                transform="translate(-17.112)"
            />
            <path
                fill="#fff"
                d="M72.962 79.037l-2.5 2.5-1.067-1.067a.518.518 0 0 0-.733.733l1.434 1.434a.518.518 0 0 0 .733 0l2.867-2.867a.518.518 0 0 0-.733-.733z"
                transform="translate(-64.958 -73.436)"
            />
        </svg>
    );
};

export default Security;
