import {
    PLAY_BET_SOUND,
    PLAY_BUTTON_SOUND,
    PLAY_WIN_SOUND,
    STOP_BET_SOUND,
    STOP_BUTTON_SOUND,
    STOP_WIN_SOUND,
} from './actions';
import { AudioPlayerState } from './state';

const initialState: AudioPlayerState = {
    playBetSound: false,
    playButtonSound: false,
    playWinSound: false,
};

const audioPlayerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PLAY_BET_SOUND: {
            return {
                ...state,
                playBetSound: true,
            };
        }
        case STOP_BET_SOUND: {
            return {
                ...state,
                playBetSound: false,
            };
        }
        case PLAY_BUTTON_SOUND: {
            return {
                ...state,
                playButtonSound: true,
            };
        }
        case STOP_BUTTON_SOUND: {
            return {
                ...state,
                playButtonSound: false,
            };
        }
        case PLAY_WIN_SOUND: {
            return {
                ...state,
                playWinSound: true,
            };
        }
        case STOP_WIN_SOUND: {
            return {
                ...state,
                playWinSound: false,
            };
        }
        default:
            return state;
    }
};

export default audioPlayerReducer;
