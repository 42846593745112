import React, { FC } from 'react';
import { TopMultipliers } from '../../../../constants/interfaces/TopMultipliers';
import Table from '../../../../components/Table/Table';
import { TableHead } from '../../../../components/Table/TableHead';
import { TableHeaderRow } from '../../../../components/Table/TableHeaderRow';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell';
import RenderOddsTableRows from '../components/RenderOddsTableRows';
import styled from 'styled-components';
import { TableBody } from '../../../../components/Table/TableBody';
import { TOP_LIST_FILTER_INDEXES } from '../configs/tools';
import { KEYWORDS } from '../../../../config/language/keywords';
import useTranslate from '../../../../config/language';

const Container = styled.div``;
const StyledTableHeaderCell = styled(TableHeaderCell)`
    width: 30%;
`;

const OddsList: FC<{
    items?: TopMultipliers;
    selectedFilter: TOP_LIST_FILTER_INDEXES;
}> = ({ items, selectedFilter }) => {
    const translate = useTranslate();

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableHeaderRow>
                        <StyledTableHeaderCell>
                            {translate(KEYWORDS.TIME)}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell>
                            {translate(KEYWORDS.CRUSHED_AT)}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell />
                    </TableHeaderRow>
                </TableHead>
            </Table>
            <Table>
                <TableBody>
                    {items && (
                        <RenderOddsTableRows
                            items={items}
                            time={selectedFilter}
                        />
                    )}
                </TableBody>
            </Table>
        </Container>
    );
};

export default OddsList;
