import { useCallback, useRef } from 'react';
import { SoundPlayer } from '../../soundPlayer/soundPlayer';

export function usePlayButtonSoundService() {
    let timer = useRef<any>(null);
    return useCallback(() => {
        const sound = new SoundPlayer();
        sound.playButtonSound();
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            sound.stopButtonSound();
        }, 200);
    }, []);
}
