import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Ship } from './components/Ship';

const ShipContainer = styled(motion.div)`
    position: relative;
`;

export const Flying: FC<{ shipWidth: number }> = ({ shipWidth }) => {
    return (
        <ShipContainer
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Ship animateShip={true} width={shipWidth} />
        </ShipContainer>
    );
};
