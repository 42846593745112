import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { variables } from './constants/variables';
import rootReducer from './config/store/reducers';
import createAppStore from './config/store/store';
import {
    contextItem,
    ContextProviderItem,
    ContextProvider,
} from './components/ContextProvider/ContextProvider';
import { createContexts } from './config/context';
import LanguageProvider from './config/language/provider';

const store = createAppStore(rootReducer);

const contextItems: ContextProviderItem[] = createContexts().map(c =>
    contextItem(c.context, c.value)
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider>
                <ContextProvider items={contextItems}>
                    <ThemeProvider theme={variables}>
                        <App />
                    </ThemeProvider>
                </ContextProvider>
            </LanguageProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
