import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { GameContext } from '../../../game';

const ExplosionContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExplosionImageWrapper = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledExplosionImage = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    position: absolute;
    top: -30%;
    margin-left: 5%;
    > img {
        top: 0;
        width: 100%;
    }
`;

export const Explosion: FC<{ explosion: string[] }> = ({ explosion }) => {
    const [imageCount, setImageCount] = useState(0);
    const { gameWidth } = useContext(GameContext);
    const balloonWidth = gameWidth / 1.62;
    React.useEffect(() => {
        const interval = 2000 / explosion.length;

        const timeout = setInterval(() => {
            setImageCount(imageCount + 1);
        }, interval);

        if (imageCount === explosion.length - 1) {
            clearInterval(timeout);
        }

        return () => {
            clearInterval(timeout);
        };
    }, [imageCount, explosion.length]);

    return (
        <ExplosionContainer>
            <ExplosionImageWrapper width={balloonWidth}>
                {explosion.length > 0 ? (
                    <StyledExplosionImage width={balloonWidth / 0.2983}>
                        {explosion[imageCount]}
                    </StyledExplosionImage>
                ) : (
                    <StyledExplosionImage width={balloonWidth / 0.2983}>
                        <img src={explosion[0]} alt="explosion" />
                    </StyledExplosionImage>
                )}
            </ExplosionImageWrapper>
        </ExplosionContainer>
    );
};
