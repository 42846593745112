import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useAudioPlayerState } from './store/state';
import { useGameConfigs } from '../../config/store/state/app.state';
import { SoundPlayer } from '../soundPlayer/soundPlayer';

const StyledAudioPlayer = styled.div``;

export const AudioPlayer: FC = () => {
    const audioState = useAudioPlayerState();
    const configs = useGameConfigs();

    useEffect(() => {
        const audio = new SoundPlayer();

        if (audioState.playBetSound && configs.voiceEffects) {
            audio.playBetSound();
        }
        return () => {
            audio.stopBetSound();
        };
    }, [audioState.playBetSound, configs.voiceEffects]);

    useEffect(() => {
        const audio = new SoundPlayer();

        if (audioState.playWinSound && configs.voiceEffects) {
            audio.playWinSound();
        }
        return () => {
            audio.stopWinSound();
        };
    }, [audioState.playWinSound, configs.voiceEffects]);

    return <StyledAudioPlayer />;
};
