import styled from 'styled-components';
import { TableCell } from '../../../../components/Table/TableCell';

export const NeutralFirstTableCell = styled(TableCell)`
    width: 8%;
    padding: 8px 2px;
    padding-left: 8px;
    overflow: hidden;
    letter-spacing: 0;
    text-align: left;
    height: 40px;
    color: ${props=>props.theme.colors.white}
`;

export const NeutralSecondTableCell = styled(TableCell)`
    width: 5%;
    padding: 8px 2px;
    height: 40px;
    color: ${props=>props.theme.colors.white}
`;

export const NeutralThirdTableCell = styled(TableCell)`
    width: 45%;
    height: 40px;
    font-size: 12px;
    padding: 8px;
    color: ${props=>props.theme.colors.white}
`;

export const NeutralFourthTableCell = styled(TableCell)`
    width: 42%;
    padding: 8px;
    height: 40px;
    font-size: 12px;
    color: ${props=>props.theme.colors.white}
`;
