export const MULTIPLIER_COLORS = [
    [247, 211, 133],
    [227, 128, 73],
    [247, 211, 133],
    [142, 190, 147],
    [49, 109, 120],
    [3, 55, 81],
    [93, 62, 132],
    [121, 65, 125],
    [179, 74, 97],
    [213, 85, 76],
    [213, 85, 76],
];

export enum MULTIPLIER_BREAK_POINTS {
    RANGE_1 = 1.1,
    RANGE_2 = 1.9,
    RANGE_3 = 2.7,
    RANGE_4 = 3.5,
    RANGE_5 = 4.3,
    RANGE_6 = 5.1,
    RANGE_7 = 5.9,
    RANGE_8 = 6.7,
    RANGE_9 = 7.5,
    RANGE_10 = 8.3,
    RANGE_11 = Number.MAX_SAFE_INTEGER,
}
const STEP = 0.01;

export const MULTIPLIER_RANGE = [
    [1, MULTIPLIER_BREAK_POINTS.RANGE_1],
    [MULTIPLIER_BREAK_POINTS.RANGE_1 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_2],
    [MULTIPLIER_BREAK_POINTS.RANGE_2 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_3],
    [MULTIPLIER_BREAK_POINTS.RANGE_3 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_4],
    [MULTIPLIER_BREAK_POINTS.RANGE_4 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_5],
    [MULTIPLIER_BREAK_POINTS.RANGE_5 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_6],
    [MULTIPLIER_BREAK_POINTS.RANGE_6 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_7],
    [MULTIPLIER_BREAK_POINTS.RANGE_7 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_8],
    [MULTIPLIER_BREAK_POINTS.RANGE_8 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_9],
    [MULTIPLIER_BREAK_POINTS.RANGE_9 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_10],
    [MULTIPLIER_BREAK_POINTS.RANGE_10 + STEP, MULTIPLIER_BREAK_POINTS.RANGE_11],
];
