import React from 'react';
import MoonIcon from '../../../assets/images/background/Moon.png';
import styled from 'styled-components';

const MoonContainer = styled.div`
    width: 84.2px;
    height: 84.2px;
    left: 40%;
    top: 87px;
    position: absolute;
`;
const StyledMoon = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Moon = () => {
    return (
        <MoonContainer>
            <StyledMoon src={MoonIcon} alt="Moon" />
        </MoonContainer>
    );
};

export default Moon;
