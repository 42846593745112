import React, { FC } from 'react';
import styled from 'styled-components';
import BaseAvatar from '../../assets/images/avatars/0.png';

const AvatarContainer = styled.div<{ size?: number }>`
    width: ${props => (props.size ? props.size : 32)}px;
    height: ${props => (props.size ? props.size : 32)}px;
    border-radius: 5px;
    overflow: hidden;
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
`;

const UserAvatar: FC<{ image?: string; size?: number }> = ({ image, size }) => {
    return (
        <AvatarContainer size={size}>
            <AvatarImage src={image || BaseAvatar} />
        </AvatarContainer>
    );
};

export default UserAvatar;
