import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { TopList } from '../../../../../constants/interfaces/TopList';

export type TopBetsSelector = (state: any) => TopList;

export const TopBetsSelectorContext = createContext<TopBetsSelector>(() => {
    throw new Error('Not implemented');
});

export function useTopBets(): TopList {
    return useSelector(useContext(TopBetsSelectorContext));
}
