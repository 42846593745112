import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ArrowRight from '../../../components/Icons/ArrowRight';
import { useGameConfigs } from '../../../config/store/state/app.state';
import {
    useToggleConfigAnimationService,
    useToggleConfigBackgroundMusicService,
    useToggleConfigVoiceService,
} from '../configs/store/services';
import GameRulesModal from './GameRulesModal';
import ProvablyFairModal from './ProvablyFairModal';
import ArrowLeft from '../../../components/Icons/ArrowLeft';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';
import CustomToggleInput from '../../../components/ToggleInput/CustomToggleInput';

const StyledContainer = styled.div`
    padding: 16px 0;
`;

const ConfigurationItem = styled.div`
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 4px 8px;
    box-sizing: border-box;
`;

const Label = styled.div`
    color: ${props => props.theme.colors.white};
    font-family: 'Mediator Narrow';
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
`;

const ActionIcon = styled.div`
    height: 11px;
    display: flex;
`;

const Actions = styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
`;

const ArrowActions = styled.div`
    width: 50px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    &:hover {
        background: ${props => props.theme.colors.transparent3};
    }
`;

const GameConfigurations: FC = () => {
    const configs = useGameConfigs();
    const translate = useTranslate();
    const handleAnimate = useToggleConfigAnimationService();
    const handleBackgroundMusic = useToggleConfigBackgroundMusicService();
    const handleVoice = useToggleConfigVoiceService();
    const [openGameRules, setOpenGameRules] = useState(false);
    const [openProvablyFair, setOpenProvablyFair] = useState(false);

    return (
        <StyledContainer>
            {openGameRules && (
                <GameRulesModal closeModal={() => setOpenGameRules(false)} />
            )}
            {openProvablyFair && (
                <ProvablyFairModal
                    closeModal={() => setOpenProvablyFair(false)}
                />
            )}
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.SOUND)}</Label>
                <Actions>
                    <CustomToggleInput
                        active={configs.voiceEffects}
                        onChange={() => handleVoice(!configs.voiceEffects)}
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.MUSIC)}</Label>
                <Actions>
                    <CustomToggleInput
                        active={configs.backgroundMusic}
                        onChange={() =>
                            handleBackgroundMusic(!configs.backgroundMusic)
                        }
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.ANIMATIONS)}</Label>
                <Actions>
                    <CustomToggleInput
                        active={configs.animate}
                        onChange={() => handleAnimate(!configs.animate)}
                    />
                </Actions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>{translate(KEYWORDS.GAME_RULES)}</Label>
                <ArrowActions onClick={() => setOpenGameRules(true)}>
                    <ActionIcon>
                        {openGameRules ? <ArrowLeft /> : <ArrowRight />}
                    </ActionIcon>
                </ArrowActions>
            </ConfigurationItem>
            <ConfigurationItem>
                <Label>Provably Fair</Label>
                <ArrowActions onClick={() => setOpenProvablyFair(true)}>
                    <ActionIcon>
                        {openProvablyFair ? <ArrowLeft /> : <ArrowRight />}
                    </ActionIcon>
                </ArrowActions>
            </ConfigurationItem>
        </StyledContainer>
    );
};

export default GameConfigurations;
