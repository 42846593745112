import React, { FC } from 'react';
import styled from 'styled-components';
import BaseAvatar from '../../../assets/images/avatars/0.png';

const AvatarContainer = styled.div`
    width: 31px;
    height: 31px;
    margin-right: 5px;
    border-radius: 100px;
    flex-shrink: 0;
    overflow: hidden;
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
`;

const TableAvatar: FC<{ image?: string }> = ({ image }) => {
    return (
        <AvatarContainer>
            <AvatarImage src={image || BaseAvatar} />
        </AvatarContainer>
    );
};

export default TableAvatar;
