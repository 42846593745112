import React, { FC, useState } from 'react';
import styled from 'styled-components';
import DropDownItems from './DropDownItems';
import ClickAwayListener from '../../helpers/hooks/clickawayListener';
import ArrowDown from '../Icons/ArrowDown';
import ArrowUp from '../Icons/ArrowUp';
import useTranslate from '../../config/language';

const StyledContainer = styled.div`
    height: 26px;
    width: 100%;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.wheat};
    border: none;
    color: ${props => props.theme.colors.marine};
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
`;

const Text = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Mediator Narrow';
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconContainer = styled.div`
    position: absolute;
    width: 13px;
    height: 100%;
    color: ${props => props.theme.colors.marine};
    top: 0;
    right: 8px;
`;

const DropDown: FC<{
    items: { text: string; index: any }[];
    selected: any;
    selectItem(selected: number | string): void;
    className?: string;
}> = ({ selected, items, selectItem, className }) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();

    return (
        <StyledContainer onClick={() => setOpen(!open)} className={className}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Text>
                    {translate(
                        items &&
                            items.filter(item => item.index === selected)[0]
                                .text
                    )}
                </Text>
                {open && (
                    <DropDownItems
                        items={items}
                        selectItem={selectItem}
                        handleClose={() => setOpen(false)}
                    />
                )}
                <IconContainer>
                    {open ? <ArrowUp /> : <ArrowDown />}
                </IconContainer>
            </ClickAwayListener>
        </StyledContainer>
    );
};

export default DropDown;
