import styled from 'styled-components';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from './NeutralTableCell';

export const SuccessFirstTableCell = styled(NeutralFirstTableCell)`
    background: rgb(3, 56, 82, 0.32);
`;

export const SuccessSecondTableCell = styled(NeutralSecondTableCell)`
    background: rgb(3, 56, 82, 0.32);
`;

export const SuccessThirdTableCell = styled(NeutralThirdTableCell)`
    background: rgb(3, 56, 82, 0.32);
`;

export const SuccessFourthTableCell = styled(NeutralFourthTableCell)`
    background: rgb(3, 56, 82, 0.32);
`;
