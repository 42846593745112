import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';

const Title: FC = () => {
    const game = useGameStatusState();
    const translate = useTranslate();
    const [title, setTitle] = useState('Balloon');

    useEffect(() => {
        switch (game.state) {
            case GAME_STATE.RUNNING: {
                setTitle(`${game.multiplier && game.multiplier.toFixed(2)}x`);
                break;
            }
            case GAME_STATE.COUNTDOWN: {
                setTitle(`${translate(KEYWORDS.GAME_IS_STARTING)}`);
                break;
            }
            case GAME_STATE.CRUSHED: {
                setTitle(
                    `${translate(KEYWORDS.CRUSHED_AT)} ${game.multiplier}`
                );
                break;
            }
            default: {
                setTitle(`Balloon`);
            }
        }
    }, [game.state, game.multiplier, translate]);

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default Title;
