import React, { FC } from 'react';
import styled from 'styled-components';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const StyledSelectButton = styled.button<{
    selected?: boolean;
    disabled?: boolean;
}>`
    position: relative;
    font-size: 12px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.sea};
    font-weight: bold;
    border-radius: 50px;
    display: inline-flex;
    padding: 5px 2px;
    margin: 2px;
    align-items: center;
    justify-content: center;
    color: ${props =>
        props.selected ? props.theme.colors.white : props.theme.colors.sea};
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    background: ${props =>
        props.selected ? props.theme.colors.sea : props.theme.colors.wheat};
    &:active {
        background: ${props => props.theme.colors.sea};
        color: ${props => props.theme.colors.white};
    }
    &:disabled {
        background: ${props => props.theme.colors.transparent};
        opacity: 0.5;
        user-events: none;
    }
`;

const ButtonText = styled.span`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
`;

const SelectButton: FC<{
    text: string;
    selected?: boolean;
    onClick?(): void;
    disabled?: boolean;
    className?: string;
}> = ({ text, selected, onClick, disabled, className }) => {
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleClick = () => {
        if (voiceEffects) {
            playButton();
        }
        if (onClick) {
            onClick();
        }
    };

    return (
        <StyledSelectButton
            selected={selected}
            onClick={handleClick}
            disabled={disabled}
            className={className}
        >
            <ButtonText>{text}</ButtonText>
        </StyledSelectButton>
    );
};

export default SelectButton;
