import React, { FC } from 'react';
import { ListStatus, ListUser } from '../../../../constants/interfaces/List';
import { TableRow } from '../../../../components/Table/TableRow';
import {
    SuccessFirstTableCell,
    SuccessFourthTableCell,
    SuccessSecondTableCell,
    SuccessThirdTableCell,
} from '../../components/BetsTable/TableSuccessCell';
import TableAvatar from '../../components/TableAvatar';
import {
    FailureFirstTableCell,
    FailureFourthTableCell,
    FailureSecondTableCell,
    FailureThirdTableCell,
} from '../../components/BetsTable/TableFailureCell';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from '../../components/BetsTable/NeutralTableCell';
import styled from 'styled-components';

const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RenderTableRows: FC<{ items: ListUser[] }> = ({ items }): any => {
    return (
        items &&
        items.map((player, index) => {
            switch (player.status) {
                case ListStatus.WON: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <SuccessFirstTableCell>
                                <AvatarContainer>
                                    <TableAvatar />
                                    {player.username}
                                </AvatarContainer>
                            </SuccessFirstTableCell>
                            <SuccessSecondTableCell>
                                {player.bet} ₾
                            </SuccessSecondTableCell>
                            <SuccessThirdTableCell>
                                {player.odds ? player.odds : '-'}X
                            </SuccessThirdTableCell>
                            <SuccessFourthTableCell>
                                {player.profit ? `${player.profit} ₾` : '-'}
                            </SuccessFourthTableCell>
                        </TableRow>
                    );
                }
                case ListStatus.LOST: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <FailureFirstTableCell>
                                <AvatarContainer>
                                    <TableAvatar />
                                    {player.username}
                                </AvatarContainer>
                            </FailureFirstTableCell>
                            <FailureSecondTableCell>
                                {player.bet} ₾
                            </FailureSecondTableCell>
                            <FailureThirdTableCell>-</FailureThirdTableCell>
                            <FailureFourthTableCell>-</FailureFourthTableCell>
                        </TableRow>
                    );
                }
                default: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <NeutralFirstTableCell>
                                <AvatarContainer>
                                    <TableAvatar />
                                    {player.username}
                                </AvatarContainer>
                            </NeutralFirstTableCell>
                            <NeutralSecondTableCell>
                                {player.bet} ₾
                            </NeutralSecondTableCell>
                            <NeutralThirdTableCell>-</NeutralThirdTableCell>
                            <NeutralFourthTableCell>
                                {player.profit ? `${player.profit} ₾` : '-'}
                            </NeutralFourthTableCell>
                        </TableRow>
                    );
                }
            }
        })
    );
};

export default RenderTableRows;
