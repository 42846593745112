import { FC, useCallback, useContext } from 'react';
import { LanguageContext } from './provider';
import { KEYWORDS } from './keywords';

const useTranslate = () => {
    const languageContext = useContext(LanguageContext);
    return useCallback(
        (keyword?: KEYWORDS | string) => {
            if (!keyword) return '';

            return languageContext.dictionary[keyword as KEYWORDS] || keyword;
        },
        [languageContext]
    );
};

export default useTranslate;

export const Translate: FC<{ keyword: any }> = keyword => {
    const languageContext = useContext(LanguageContext);
    if (!keyword) return '';

    return languageContext.dictionary[keyword.keyword] || keyword;
};
