import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectButton from '../../../../components/SelectButton/SelectButton';
import Plus from '../../../../components/Icons/Plus';
import Minus from '../../../../components/Icons/Minus';
import {
    useCashOutService,
    usePendingService,
    useRegisterService,
    useUnregisterService,
} from '../../../../config/store/services';
import {
    RegisterState,
    useGameStatusState,
    usePanelState,
} from '../../../../config/store/state/app.state';
import { GAME_STATE } from '../../../../constants/interfaces/Game';
import RegisterPanel from './panels/RegisterPanel';
import UnregisterPanel from './panels/UnregisterPanel';
import CashOutPanel from './panels/CashOutPanel';
import AutoBetPanel from './AutoBetPanel';
import { useProfile } from '../../../profile/configs/store/state';
import { KEYWORDS } from '../../../../config/language/keywords';
import { snackbarVariants } from '../../../../components/InfoSnackbar/InfoSnackbar';
import { useShowMessage } from '../../../errorMessages/store/services';

const StyledActionBoard = styled.div`
    padding: 0 16px;
    max-width: 50%;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 8px;

    @media (max-height: ${850}px) {
        max-width: 50%;
    }

    @media (max-width: ${1500}px) {
        max-width: 60%;
    }

    @media (max-height: ${500}px), (max-width: ${1000}px) {
        max-width: 80%;
        padding: 0 8px;
    }

    @media (max-width: ${700}px) {
        max-width: 100%;
        padding: 0px;
        &:nth-child(2) {
            border-left: none;
        }
    }
`;

const FirstRow = styled.div<{ disabled?: boolean }>`
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    ${props =>
        props.disabled &&
        `
            pointer-events: none;
        }
    `}
    @media (max-height: ${550}px) {
        margin-bottom: 6px;
    }

`;

const SecondRow = styled.div`
    display: flex;
    margin-bottom: 10px;
    height: 52px;
    @media (max-height: ${550}px) {
        height: 55px;
        margin-bottom: 6px;
    }
`;

const ThirdRow = styled.div`
    display: flex;
    height: 54px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        height: 45px;
    }
`;

const StyledSelectButton = styled(SelectButton)`
    max-width: 55px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        font-size: 10px;
    }
`;

const MIN_VALUE = 0.1;
const MAX_VALUE = 500;

const MIN_AUTO_CASH_OUT_VALUE = 1.1;
const MAX_AUTO_CASH_OUT_VALUE = 50000;

const ActionBoard: FC<{
    activeBoards: number;
    boardIndex: number;
    addBoard(): void;
    removeBoard(): void;
}> = ({ activeBoards, boardIndex, addBoard, removeBoard }) => {
    const displayAdd = activeBoards < 2;
    const state = usePanelState().filter(
        board => board.PanelIndex === boardIndex
    )[0];

    const showMessage = useShowMessage();
    const balance = useProfile().B.WalletBalance;
    const gameStatus = useGameStatusState();
    const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
    const registerService = useRegisterService();
    const pendingService = usePendingService();
    const unregisterService = useUnregisterService();
    const cashOutService = useCashOutService();
    const registerState = state && state.registerState;
    const [amount, setAmount] = useState<number>(1);
    const [autoBet, setAutoBet] = useState(false);
    const [autoBetEnabled, setAutoBetEnabled] = useState(false);

    const [enableAutoCashOut, setEnableAutoCashOut] = useState(false);
    const [autoCashOut, setAutoCashOut] = useState(2.2);

    const handleRegister = useCallback(() => {
        registerService({
            BuyIn: amount,
            AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
            IsAutoBet: autoBet,
            PanelIndex: boardIndex,
            BalanceType: 1,
        });
    }, [
        enableAutoCashOut,
        autoCashOut,
        autoBet,
        boardIndex,
        amount,
        registerService,
    ]);

    const handlePending = useCallback(() => {
        pendingService({
            BuyIn: amount,
            AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
            IsAutoBet: autoBet,
            PanelIndex: boardIndex,
            BalanceType: 1,
        });
    }, [
        amount,
        enableAutoCashOut,
        autoCashOut,
        autoBet,
        boardIndex,
        pendingService,
    ]);

    const handleInitRegister = useCallback(() => {
        if (amount > balance) {
            showMessage(KEYWORDS.NOT_ENOUGH_BALANCE, snackbarVariants.error);
            return;
        }
        if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
            handlePending();
        } else {
            handleRegister();
        }
    }, [
        gameStatus,
        handlePending,
        handleRegister,
        amount,
        balance,
        showMessage,
    ]);

    const handleClickRegister = () => {
        if (autoBet) {
            setAutoBetEnabled(true);
        }
        handleInitRegister();
    };

    const handleClickUnregister = () => {
        if (autoBetEnabled) {
            setAutoBetEnabled(false);
        }
        unregisterService({
            PanelIndex: boardIndex,
        });
    };

    const handleClickCashOut = () => {
        cashOutService({
            PanelIndex: boardIndex,
            Won: amount * multiplier,
            Multiplier: multiplier,
        });
    };

    const handleValueChange = (value: number | null) => {
        if (!value) return;
        if (value < MIN_VALUE) {
            setAmount(MIN_VALUE);
            return;
        }
        if (value > MAX_VALUE) {
            setAmount(MAX_VALUE);
            return;
        }
        setAmount(value);
    };

    const handleAutoCashOutValueChange = (value: number | null) => {
        if (!value) return;
        if (value < MIN_AUTO_CASH_OUT_VALUE) {
            setAutoCashOut(MIN_AUTO_CASH_OUT_VALUE);
            return;
        }
        if (value > MAX_AUTO_CASH_OUT_VALUE) {
            setAutoCashOut(MAX_AUTO_CASH_OUT_VALUE);
            return;
        }
        setAutoCashOut(value);
    };

    useEffect(() => {
        if (state.registerState && gameStatus.state) {
            if (
                state.registerState === RegisterState.PENDING &&
                gameStatus.state === GAME_STATE.COUNTDOWN
            ) {
                handleRegister();
            }
            if (
                state.registerState === RegisterState.UNREGISTERED &&
                autoBetEnabled
            ) {
                handleInitRegister();
            }
        }
    }, [
        handleInitRegister,
        state.registerState,
        gameStatus.state,
        handleRegister,
        autoBetEnabled,
    ]);

    const handleRegisterStateView = () => {
        switch (registerState) {
            case RegisterState.UNREGISTERED: {
                return (
                    <RegisterPanel
                        amount={amount}
                        setAmount={handleValueChange}
                        toggleBoard={displayAdd ? addBoard : removeBoard}
                        handleRegister={handleClickRegister}
                        icon={displayAdd ? <Plus /> : <Minus />}
                        minValue={MIN_VALUE}
                        maxValue={MAX_VALUE}
                    />
                );
            }
            case RegisterState.REGISTER_INITIATED:
            case RegisterState.CASH_OUT_INITIATED:
            case RegisterState.REGISTERED: {
                if (gameStatus.state === GAME_STATE.COUNTDOWN) {
                    return (
                        <UnregisterPanel
                            amount={amount}
                            disabled={
                                registerState ===
                                RegisterState.REGISTER_INITIATED
                            }
                            handleUnregister={handleClickUnregister}
                        />
                    );
                } else {
                    return (
                        <CashOutPanel
                            amount={amount * multiplier}
                            disabled={
                                registerState ===
                                RegisterState.CASH_OUT_INITIATED
                            }
                            handleCashOut={handleClickCashOut}
                        />
                    );
                }
            }
            case RegisterState.UNREGISTER_INITIATED:
            case RegisterState.PENDING: {
                return (
                    <UnregisterPanel
                        amount={amount}
                        disabled={
                            registerState === RegisterState.UNREGISTER_INITIATED
                        }
                        handleUnregister={handleClickUnregister}
                    />
                );
            }
            default: {
                return (
                    <RegisterPanel
                        amount={amount}
                        setAmount={handleValueChange}
                        toggleBoard={displayAdd ? addBoard : removeBoard}
                        handleRegister={handleClickRegister}
                        icon={displayAdd ? <Plus /> : <Minus />}
                    />
                );
            }
        }
    };

    const disableActions = registerState !== RegisterState.UNREGISTERED;
    return (
        <StyledActionBoard>
            <SecondRow>{handleRegisterStateView()}</SecondRow>
            <FirstRow disabled={registerState !== RegisterState.UNREGISTERED}>
                <StyledSelectButton
                    text={'1.00 ₾'}
                    selected={amount === 1.0}
                    onClick={() => handleValueChange(1.0)}
                    disabled={disableActions}
                />
                <StyledSelectButton
                    text={'2.00 ₾'}
                    selected={amount === 2.0}
                    onClick={() => handleValueChange(2.0)}
                    disabled={disableActions}
                />
                <StyledSelectButton
                    text={'5.00 ₾'}
                    selected={amount === 5.0}
                    onClick={() => handleValueChange(5.0)}
                    disabled={disableActions}
                />
                <StyledSelectButton
                    text={'10.00 ₾'}
                    selected={amount === 10.0}
                    onClick={() => handleValueChange(10.0)}
                    disabled={disableActions}
                />
                <StyledSelectButton
                    text={'25.00 ₾'}
                    selected={amount === 25.0}
                    onClick={() => handleValueChange(25.0)}
                    disabled={disableActions}
                />
            </FirstRow>
            <ThirdRow>
                <AutoBetPanel
                    autoBet={autoBet}
                    setAutoBet={setAutoBet}
                    enableAutoCashOut={enableAutoCashOut}
                    setEnableAutoCashOut={setEnableAutoCashOut}
                    autoCashOut={autoCashOut}
                    setAutoCashOut={handleAutoCashOutValueChange}
                    minValue={MIN_AUTO_CASH_OUT_VALUE}
                    maxValue={MAX_AUTO_CASH_OUT_VALUE}
                    panelDisabled={disableActions}
                />
            </ThirdRow>
        </StyledActionBoard>
    );
};

export default ActionBoard;
