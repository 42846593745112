import React, { FC } from 'react';
import { IconProps } from './types';

export const ArrowLeft: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 5.474 8.947"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M0,3.059,3.059,0,6.119,3.059"
                transform="translate(1 7.533) rotate(-90)"
                fill="none"
                stroke="rgba(255,255,255,0.84)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};

export default ArrowLeft;
