import React, { FC } from 'react';
import { TableRow } from '../../../../components/Table/TableRow';
import {
    NeutralFirstTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from '../../components/OddsTable/NeutralTableCell';
import { TopMultipliers } from '../../../../constants/interfaces/TopMultipliers';
import { TOP_LIST_FILTER_INDEXES } from '../configs/tools';
import {
    formatDate,
    formatTime,
} from '../../../../helpers/functions/format-date';
import CheckGameHistory from './CheckGameHistory';

const RenderOddsTableRows: FC<{
    items: TopMultipliers;
    time: TOP_LIST_FILTER_INDEXES;
}> = ({ items, time }): any => {
    return (
        items &&
        items[time].map((game, index) => {
            return (
                <TableRow key={JSON.stringify(game + `${index}`)}>
                    <NeutralFirstTableCell>
                        {`${formatDate(game.TimeStamp)} ${formatTime(
                            game.TimeStamp
                        )}`}
                    </NeutralFirstTableCell>
                    <NeutralSecondTableCell>
                        {game.BustedAt} X
                    </NeutralSecondTableCell>
                    <NeutralThirdTableCell>
                        <CheckGameHistory game={game} />
                    </NeutralThirdTableCell>
                </TableRow>
            );
        })
    );
};

export default RenderOddsTableRows;
