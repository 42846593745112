import styled from 'styled-components';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import Balloon from '../../../components/Icons/Balloon';

const ShipContainer = styled(motion.div)`
    position: relative;
`;

const Spaceship = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    position: relative;
    z-index: 1;
`;

const shipTransition = {
    y: {
        duration: 1.5,
        yoyo: Infinity,
        delay: 0.5,
    },
};

// const DEFAULT_SHIP_WIDTH = 185;
// const DEFAULT_FIRE_WIDTH = 40;
// const DEFAULT_FIRE_LEFT = 75;
// const DEFAULT_FIRE_TOP = 235;
// const SHIP_TO_FIRE_WIDTH_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_WIDTH;
// const SHIP_TO_FIRE_LEFT_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_LEFT;
// const SHIP_TO_FIRE_TOP_RATIO = DEFAULT_SHIP_WIDTH / DEFAULT_FIRE_TOP;

export const Ship: FC<{
    width?: number;
    animateFire?: boolean;
    animateShip?: boolean;
}> = ({ width, animateShip }) => {
    const shipWidth = width || 185;
    return (
        <ShipContainer
            animate={animateShip ? { y: -10 } : undefined}
            transition={shipTransition}
        >
            <Spaceship width={shipWidth}>
                <Balloon />
            </Spaceship>
        </ShipContainer>
    );
};
