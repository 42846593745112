import React, { FC } from 'react';
import styled from 'styled-components';

const Td = styled.td<{
    width?: number;
    clickable?: boolean;
}>`
    height: 50px;
    color: ${props => props.theme.colors.marine};
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Mediator Narrow';
    padding: 9px 2px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    width: 34%;
    ${props => (props.width ? `width: ${props.width}%` : '')};
`;

export const TableCell: FC<{
    width?: number;
    className?: string;
}> = ({ width, className, children }) => (
    <Td className={className} width={width}>
        {children}
    </Td>
);

export const TableCellRoot = Td;
