import React, { FC } from 'react';
import styled from 'styled-components';

const StyledSidebar = styled.div`
    flex: 23;
    min-width: 250px;
    margin-left: 10px;

    @media (max-width: ${1000}px) {
        order: 2;
    }
`;

const Sidebar: FC = ({ children }) => {
    return <StyledSidebar>{children}</StyledSidebar>;
};

export default Sidebar;
