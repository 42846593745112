import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

export const Container = styled.div``;

export const Switch = styled(motion.div)<{
    active: boolean;
    disabled?: boolean;
}>`
    width: 46px;
    box-sizing: border-box;
    padding: 0px 3px;
    position: relative;
    display: flex;
    justify-content: ${props => (props.active ? 'flex-end' : 'flex-start')};
    &:disabled {
        opacity: 0.45;
        pointer-events: none;
    }
`;

export const Stick = styled(motion.div)<{ active: boolean }>`
    width: 100%;
    height: 16px;
    border-radius: 100px;
    display: flex;
    cursor: pointer;
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.paleRed}3d`
            : `${props.theme.colors.sea}3d`};
`;

export const Bullet = styled(motion.div)<{ active: boolean }>`
    width: 20px;
    height: 20px;
    margin-top: -2px;
    position: absolute;
    border-radius: 200px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.paleRed}`
            : props.theme.colors.sea};
`;

const ToggleInput: FC<{
    active: boolean;
    onChange?(): void;
    disabled?: boolean;
}> = ({ active, onChange, disabled }) => {
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleClick = () => {
        if (disabled) return;
        if (voiceEffects) {
            playButton();
        }
        if (onChange) {
            onChange();
        }
    };

    return (
        <Container>
            <Switch active={active} onClick={handleClick} disabled={disabled}>
                <Stick animate active={active} />
                <Bullet animate active={active} />
            </Switch>
        </Container>
    );
};

export default ToggleInput;
