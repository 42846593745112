import React, { FC } from 'react';
import styled from 'styled-components';
import Altimeter from '../Icons/Altimeter';
import { motion } from 'framer-motion';
import { getAltimeterRotationDegree } from './tools';

const StyledMultiplier = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    font-family: 'Mediator Narrow CAPS';
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 45px;
    font-weight: bold;
    line-height: 0.87;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
    user-select: none;
    box-sizing: border-box;
`;

const AltimeterWrapper = styled(motion.div)<{
    rotate?: string;
}>`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const Multiplier: FC<{
    multiplier: number;
    color: string;
    className?: string;
}> = ({ multiplier, color, className }) => {
    return (
        <StyledMultiplier style={{ background: color }} className={className}>
            <AltimeterWrapper
                initial={{ rotate: 0 }}
                animate={{ rotate: getAltimeterRotationDegree(multiplier) }}
            >
                <Altimeter />
            </AltimeterWrapper>
            {`${multiplier.toFixed(2)}x`}
        </StyledMultiplier>
    );
};

export default Multiplier;
