import React, { FC } from 'react';
import { IconProps } from './types';

export const BirdTwo: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 49 19"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        width="100%"
                        height="100%"
                        transform="translate(0 -0.001)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <defs>
                <clipPath id="clip-path">
                    <rect
                        width="100%"
                        height="100%"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g transform="translate(170)">
                <g transform="translate(-170)" clipPath="url(#clip-path)">
                    <path
                        id="Path_3725"
                        data-name="Path 3725"
                        d="M24.593,11.329C17.506,1.4.891.065,0,0,0,0,19.2,6.211,22.729,17.773a1.8,1.8,0,0,0,3.339,0C31.195,4.654,49.184,0,49.184,0c-.891.065-17.5,1.4-24.591,11.329"
                        fill="#39737a"
                    />
                </g>
            </g>
        </svg>
    );
};

export default BirdTwo;
