import React, { FC } from 'react';
import styled from 'styled-components';
import GameConfigurations from './GameConfigurations';

const StyledContainer = styled.div`
    position: absolute;
    min-height: 150px;
    width: 243px;
    box-sizing: border-box;
    background: ${props => props.theme.colors.sea};
    box-shadow: 2px 5px 4px 0px #4444442e;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    top: 50px;
    right: 0px;
    z-index: 99;
    padding: 16px;
`;

const Body = styled.div``;

const ProfileDropdown: FC = () => {
    return (
        <StyledContainer>
            <Body>
                <GameConfigurations />
            </Body>
        </StyledContainer>
    );
};

export default ProfileDropdown;
