import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../../../config/language';
import { KEYWORDS } from '../../../../../../config/language/keywords';

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -145px;
    @media (max-width: ${1000}px) {
        top: -150px;
    }
    @media (max-height: ${800}px) {
        top: -165px;
    }
    @media (max-height: ${680}px) {
        top: -170px;
    }
`;

const Text = styled.div`
    letter-spacing: 0;
    color: ${props => props.theme.colors.white};
    text-align: center;
    font-family: 'Mediator Narrow CAPS';
    font-size: 19px;
    width: fit-content;
    width: -moz-fit-content;
    white-space: nowrap;
`;

const Value = styled.span`
    font-size: 27px;
    font-family: 'Mediator Narrow';
`;

const Countdown: FC<{ value: string }> = ({ value }) => {
    const translate = useTranslate();
    return (
        <Container>
            <Text>
                {translate(KEYWORDS.TAKING_BETS_IN_PROGRESS)}{' '}
                <Value>{value}</Value>
            </Text>
        </Container>
    );
};
export default Countdown;
