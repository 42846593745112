import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

export interface MessengerState {
    open: boolean;
    messages: any;
}

export type MessengerStateSelector = (state: any) => MessengerState;

export const MessengerStateSelectorContext = createContext<
    MessengerStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useMessengerState(): MessengerState {
    return useSelector(useContext(MessengerStateSelectorContext));
}
