import React, { FC, useState } from 'react';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import ArrowUp from '../../components/Icons/ArrowUp';
import ArrowDown from '../../components/Icons/ArrowDown';
import { useProfile } from './configs/store/state';
import ClickAwayListener from '../../helpers/hooks/clickawayListener';
import ProfileDropdown from './components/ProfileDropdown';

const Container = styled.div`
    display: flex;
    position: relative;
`;

const ProfileContainer = styled.div<{ open?: boolean }>`
    display: flex;
    width: 243px;
    height: 54px;
    background: ${props => props.theme.colors.sea};
    border-bottom-left-radius: ${props => (props.open ? 0 : '8px')};
    border-bottom-right-radius: ${props => (props.open ? 0 : '8px')};
    align-items: center;
    justify-content: center;
`;

const InfoSection = styled.div`
    margin-left: 8px;
`;

const UserName = styled.div`
    font-size: 12px;
    letter-spacing: 0;
    color: ${props => props.theme.colors.whiteGrayish};
    font-family: 'Mediator Narrow';
    font-weight: bold;
`;

const UserBalance = styled.div`
    font-size: 12px;
    letter-spacing: 0;
    color: ${props => props.theme.colors.lightOrange};
    font-family: 'Mediator Narrow';
    font-weight: bold;
`;

const IconHandler = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    margin-left: 8px;
    &:active {
        background: ${props => props.theme.colors.transparent2};
    }
`;

const IconContainer = styled.div`
    width: 11px;
    height: 100%;
    color: ${props => props.theme.colors.white};
`;

const ProfileSection: FC = () => {
    const [open, setOpen] = useState(false);
    const profile = useProfile();

    return (
        <Container>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <ProfileContainer open={open}>
                    <UserAvatar />
                    <InfoSection>
                        <UserName>
                            {profile.Player && profile.Player.N}
                        </UserName>
                        <UserBalance>
                            {profile.B && profile.B
                                ? profile.B.WalletBalance
                                : 0}
                            ₾
                        </UserBalance>
                    </InfoSection>
                    <IconHandler
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <IconContainer>
                            {open ? <ArrowUp /> : <ArrowDown />}
                        </IconContainer>
                    </IconHandler>
                </ProfileContainer>
                {open && <ProfileDropdown />}
            </ClickAwayListener>
        </Container>
    );
};

export default ProfileSection;
