import React, { FC } from 'react';
import styled from 'styled-components';
import ProfileSection from '../features/profile';
import ClosedChat from '../features/chat/ClosedChat/ClosedChat';
import { useMessengerState } from '../features/chat/store/state';

const StyledHeader = styled.div`
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    box-sizing: border-box;
    flex-shrink: 0;
`;

const Header: FC = () => {
    const messenger = useMessengerState();

    return (
        <StyledHeader>
            <ProfileSection />
            {!messenger.open && <ClosedChat />}
        </StyledHeader>
    );
};

export default Header;
