import React from 'react';
import styled from 'styled-components';
import ChatBody from './ChatBody/ChatBody';
import ChatHeader from './ChatHeader/ChatHeader';
import { useMessengerState } from './store/state';
import { useToggleOpenChatService } from './store/services';

const Container = styled.div`
    z-index: 1;
    margin-left: 8px;
    width: 288px;
`;

const ChatContainer = styled.div`
    height: 100%;
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media (max-width: ${768}px) {
        position: fixed;
        background: ${props => props.theme.colors.charcoalGreyTwo};
    }
`;

const Chat = () => {
    const messenger = useMessengerState();
    const handleOpen = useToggleOpenChatService();

    if (!messenger.open) return <span />;

    return (
        <Container>
            <ChatContainer>
                <ChatHeader handleOpen={handleOpen} />
                <ChatBody />
            </ChatContainer>
        </Container>
    );
};

export default Chat;
