import React, { FC } from 'react';
import { IconProps } from './types';

export const BirdOne: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 39 14"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        width="100%"
                        height="100%"
                        transform="translate(0 -0.001)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g transform="translate(0 0)" clipPath="url(#clip-path)">
                <path
                    d="M18.466,9.559C15.156.195,0,0,0,0,.167.064,13.66,4.294,16,12.646a1.914,1.914,0,0,0,3,.942c7.112-6.664,20.006-2.352,20.179-2.316,0,0-12.938-7.888-20.716-1.713"
                    fill="#39737a"
                />
            </g>
        </svg>
    );
};

export default BirdOne;
