import React, { FC } from 'react';
import { IconProps } from './types';

export const Minus: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 -192 469.33333 469"
            className={className}
            width="100%"
            height="100%"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
            />
        </svg>
    );
};

export default Minus;
