import React from 'react';
import styled from 'styled-components';
import Moon from './items/Moon';
import FirstCloud from './items/FirstCloud';
import SecondCloud from './items/SecondCloud';
import ThirdCloud from './items/ThirdCloud';
import FourthCloud from './items/FourthCloud';
import BirdsGroupOne from './items/BirdsGroupOne';
import BirdsGroupTwo from './items/BirdsGroupTwo';
import {
    useGameConfigs,
    useGameStatusState,
} from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const StyledBackground = styled.div`
    background: linear-gradient(
        180deg,
        rgba(90, 186, 158, 1) 0%,
        rgba(90, 186, 158, 1) 46%,
        rgba(193, 202, 142, 1) 100%
    );
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
`;

const BackgroundContainer = () => {
    const animate = useGameConfigs().animate;
    const running = useGameStatusState().state === GAME_STATE.RUNNING;
    return (
        <StyledBackground>
            <Moon />
            <FirstCloud animate={animate} running={running} />
            <SecondCloud animate={animate} running={running} />
            <ThirdCloud animate={animate} running={running} />
            <FourthCloud animate={animate} running={running} />
            <BirdsGroupOne animate={animate} />
            <BirdsGroupTwo animate={animate} />
        </StyledBackground>
    );
};

export default BackgroundContainer;
