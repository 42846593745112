import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BirdThree from '../../Icons/BirdThree';
import BirdFour from '../../Icons/BirdFour';

const BirdsContainer = styled.div`
    left: 65%;
    top: 509px;
    position: absolute;
    width: 108px;
    height: 64px;
    pointer-events: none;
    user-select: none;
`;

const BirdOneWrapper = styled(motion.div)`
    display: flex;
    width: 54px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
`;

const BirdTwoWrapper = styled(motion.div)`
    display: flex;
    width: 61px;
    height: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const BirdOneStaticWrapper = styled.div`
    display: flex;
    width: 54px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
`;

const BirdTwoStaticWrapper = styled.div`
    display: flex;
    width: 61px;
    height: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const BirdsGroupTwo: FC<{ animate?: boolean }> = ({ animate }) => {
    if (animate) {
        return (
            <BirdsContainer>
                <BirdOneWrapper
                    initial={{ x: 0, y: 0 }}
                    animate={{
                        x: [1, 2, 1, 0, -1, -2, -1, 0],
                        y: [1, 2, 1, 0, -1, -2, -1, 0],
                        scale: [1, 0.9, 0.85, 1, 1.2, 1],
                    }}
                    transition={{ repeat: Infinity, duration: 1.5 }}
                >
                    <BirdThree />
                </BirdOneWrapper>
                <BirdTwoWrapper
                    initial={{ x: 0, y: 0 }}
                    animate={{
                        x: [1, 2, 1, 0, -1, -2, -1, 0],
                        y: [1, 2, 1, 0, -1, -2, -1, 0],
                        scale: [1, 0.9, 0.85, 1, 1.1, 1],
                    }}
                    transition={{ repeat: Infinity, duration: 1.3 }}
                >
                    <BirdFour />
                </BirdTwoWrapper>
            </BirdsContainer>
        );
    } else {
        return (
            <BirdsContainer>
                <BirdOneStaticWrapper>
                    <BirdThree />
                </BirdOneStaticWrapper>
                <BirdTwoStaticWrapper>
                    <BirdFour />
                </BirdTwoStaticWrapper>
            </BirdsContainer>
        );
    }
};

export default BirdsGroupTwo;
