import React, { FC } from 'react';
import { MyList } from '../../../../constants/interfaces/MyList';
import styled from 'styled-components';
import RenderMyBetsTableRows from './RenderMyBetsTableRows';
import Table from '../../../../components/Table/Table';
import { TableHead } from '../../../../components/Table/TableHead';
import { TableHeaderRow } from '../../../../components/Table/TableHeaderRow';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell';
import { TableBody } from '../../../../components/Table/TableBody';
import { formatDate } from '../../../../helpers/functions/format-date';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';

const Container = styled.div``;
const StyledDate = styled.div`
    background: ${props => props.theme.colors.wheat};
    color: ${props => props.theme.colors.marine};
    font-size: 12px;
    font-weight: bold;
    height: 27px;
    font-family: 'Mediator Narrow';
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 12px;
`;

const RenderMyBetsTable: FC<{ items: MyList[] }> = ({ items }): any => {
    const translate = useTranslate();

    return (
        items &&
        items.map((item, index) => {
            return (
                <Container key={index}>
                    <StyledDate>{formatDate(item.date)}</StyledDate>
                    <div>
                        <Table>
                            <TableHead>
                                <TableHeaderRow>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.TIME)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.BUY_IN)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.CRUSHED_AT)}
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        {translate(KEYWORDS.WINNING_AMOUNT)}
                                    </TableHeaderCell>
                                </TableHeaderRow>
                            </TableHead>
                        </Table>
                        <Table>
                            <TableBody>
                                <RenderMyBetsTableRows items={item.games} />
                            </TableBody>
                        </Table>
                    </div>
                </Container>
            );
        })
    );
};

export default RenderMyBetsTable;
