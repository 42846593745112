import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../config/language';

export enum snackbarVariants {
    success = 'success',
    error = 'error',
    info = 'info',
}

const SnackbarWrapper = styled(motion.div)<{
    open?: string;
}>`
    width: fit-content;
    min-width: 280px;
    max-width: 550px;
    height: 56px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 19px;
    font-weight: bold;
    font-family: 'Mediator Narrow CAPS';
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
    transition: visibility 1s linear;
    z-index: 1000;
    padding: 8px 16px;
    margin-bottom: 16px;
`;

const StyledMessageContainer = styled.div<{ variant: snackbarVariants }>`
    font-size: 15px;
    color: ${props =>
        props.variant === snackbarVariants.error
            ? props.theme.colors.paleRed
            : props.theme.colors.grassGreen}};
`;

export const InfoSnackbar: FC<{
    className?: string;
    message: string;
    keyword?: string;
    handleClose: () => void;
    open: any;
    variant: snackbarVariants;
}> = ({ message, keyword, variant, open }) => {
    const translate = useTranslate();

    return (
        <>
            <SnackbarWrapper initial={{ y: 50 }} animate={{ y: 0 }} open={open}>
                <StyledMessageContainer variant={variant}>
                    {translate(keyword)} {message}
                </StyledMessageContainer>
            </SnackbarWrapper>
        </>
    );
};
