import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslate from '../../config/language';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const StyledContainer = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.9;
    backdrop-filter: blur(30px);
    border-radius: 4px;
    overflow: hidden;
`;

const DropDownItem = styled.div`
    cursor: pointer;
    padding: 10px;
    font-family: 'Mediator Narrow';
    text-align: center;
    text-align: center;
    backdrop-filter: blur(70px);
    font-size: 12px;
    font-weight: bold;
    font-family: 'Mediator Narrow';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active {
        opacity: 0.7;
    }
`;

const SelectDropDownItems: FC<{
    items: { text: string; index: any }[];
    selectItem(selected: any): void;
    handleClose(): void;
}> = ({ items, selectItem, handleClose }) => {
    const translate = useTranslate();
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleClick = (id: number) => {
        if (voiceEffects) {
            playButton();
        }
        selectItem(id);
        handleClose();
    };

    return (
        <StyledContainer
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {items.map(item => {
                return (
                    <DropDownItem
                        key={item.index}
                        onClick={() => {
                            handleClick(item.index);
                        }}
                    >
                        {translate(item.text)}
                    </DropDownItem>
                );
            })}
        </StyledContainer>
    );
};

export default SelectDropDownItems;
