import { KEYWORDS } from '../../../../config/language/keywords';

export enum TOP_LIST_INDEXES {
    TOP_ODDS = 0,
    TOP_WINNINGS = 1,
    TOP_CASH_OUT = 2,
}

export enum TOP_LIST_FILTER_INDEXES {
    DAY = 'Day',
    MONTH = 'Month',
    YEAR = 'Year',
}

export const TopListItems = [
    { text: KEYWORDS.TOP_MULTIPLIER, index: TOP_LIST_INDEXES.TOP_ODDS },
    { text: KEYWORDS.TOP_WINNINGS, index: TOP_LIST_INDEXES.TOP_WINNINGS },
    {
        text: KEYWORDS.MULTIPLIER_WINNINGS,
        index: TOP_LIST_INDEXES.TOP_CASH_OUT,
    },
];

export const TopListFilters = [
    { text: KEYWORDS.DAY, index: TOP_LIST_FILTER_INDEXES.DAY },
    { text: KEYWORDS.MONTH, index: TOP_LIST_FILTER_INDEXES.MONTH },
    { text: KEYWORDS.YEAR, index: TOP_LIST_FILTER_INDEXES.YEAR },
];
