import NavigationItem from '../../../components/Navigation/NavigationItem';
import Navigation from '../../../components/Navigation/Navigation';
import React, { FC } from 'react';
import styled from 'styled-components';

const StyledNavigation = styled(Navigation)`
    margin-bottom: 8px;
    background: ${props => props.theme.colors.bgMarine};
`;

const StyledNavigationItem = styled(NavigationItem)<{ active: boolean }>`
    background: ${props => (props.active ? props.theme.colors.wheat : 'unset')};
    color: ${props =>
        props.active ? props.theme.colors.marine : props.theme.colors.white};
    opacity: ${props => (props.active ? 1 : 0.3)};
    font-size: 14px;
`;

const TableNavigation: FC<{
    items: { text: string; index: number }[];
    activeIndex: number;
    setActive(index: number): void;
}> = ({ items, activeIndex, setActive }) => {
    return (
        <StyledNavigation>
            {items.map(item => {
                return (
                    <StyledNavigationItem
                        key={item.index}
                        text={item.text}
                        index={item.index}
                        active={item.index === activeIndex}
                        onClick={setActive}
                    />
                );
            })}
        </StyledNavigation>
    );
};

export default TableNavigation;
