import {
    boardAddedAction,
    boardRemovedAction,
    cashOutAction,
    pendingAction,
    registerAction,
    registerFailAction,
    registerSuccessAction,
    unregisterAction,
    unregisterActionFail,
    unregisterActionSuccess,
} from '../actions/app.actions';
import { Dispatch, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RegisterState } from '../state/app.state';
import { useShowMessage } from '../../../features/errorMessages/store/services';
import { Message } from '../../../constants/interfaces/Message';
import { Player } from '../../../constants/interfaces/Player';
import { KEYWORDS } from '../../language/keywords';
import { snackbarVariants } from '../../../components/InfoSnackbar/InfoSnackbar';

export type PlayerRegisterParams = {
    Message: 'Register';
    BuyIn: number;
    AutoCashoutOn: null | number;
    IsAutoBet: boolean;
    PanelIndex: number;
    BalanceType: number;
    registerState: RegisterState;
};

export const usePendingService = (): any => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    return useCallback(
        (data: PlayerRegisterParams) => {
            dispatch(pendingAction(data));
            showMessage(KEYWORDS.WAITING_NEXT_ROUND, snackbarVariants.error);
        },
        [dispatch, showMessage]
    );
};

export const useRegisterService = (): any => {
    const dispatch = useDispatch();
    return useCallback(
        (data: PlayerRegisterParams) => {
            dispatch(registerAction(data));
        },
        [dispatch]
    );
};

export interface RegisterSuccessActionParams extends Message {
    PanelIndex: number;
    GameId: number;
    Player: Player;
}

export const registerSuccessService = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(registerSuccessAction(data));
};

export const registerFailService = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(registerFailAction(data));
};

export type PlayerUnregisterParams = {
    Message?: 'UnRegister';
    PanelIndex: number;
};

export const useUnregisterService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: PlayerUnregisterParams) => {
            dispatch(
                unregisterAction({
                    Message: 'UnRegister',
                    PanelIndex: data.PanelIndex,
                })
            );
        },
        [dispatch]
    );
};

export const unregisterServiceSuccess = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(unregisterActionSuccess(data));
};

export const unregisterServiceFail = (
    dispatch: Dispatch<any>,
    data: RegisterSuccessActionParams
): any => {
    dispatch(unregisterActionFail(data));
};

export type PlayerCashOutParams = {
    Message: 'CashOut';
    PanelIndex?: number;
    Multiplier?: number;
    Won?: number;
};

export const useCashOutService = () => {
    const dispatch = useDispatch();

    return useCallback(
        (data: Partial<PlayerCashOutParams>) => {
            dispatch(
                cashOutAction({
                    Message: 'CashOut',
                    PanelIndex: data.PanelIndex,
                    Multiplier: data.Multiplier,
                })
            );
        },
        [dispatch]
    );
};

export type BoardActionParams = {
    PanelIndex: number;
    registerState: RegisterState;
};

export const useBoardAddedService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (PanelIndex: number) => {
            dispatch(
                boardAddedAction({
                    PanelIndex: PanelIndex,
                    registerState: RegisterState.UNREGISTERED,
                })
            );
        },
        [dispatch]
    );
};

export const useBoardRemovedService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (PanelIndex: number) => {
            dispatch(
                boardRemovedAction({
                    PanelIndex: PanelIndex,
                    registerState: RegisterState.UNREGISTERED,
                })
            );
        },
        [dispatch]
    );
};
