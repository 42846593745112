import styled from 'styled-components';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell';

export const StyledTableHeaderCell = styled(TableHeaderCell)`
    color: ${props=> props.theme.colors.whiteGrayish};
    @media (max-width: ${700}px) {
        font-size: 11px;
        padding-left: 0px;
        padding: 0 4px;
        &:nth-child(1) {
            padding-left: 4px;
        }
    }
`;
