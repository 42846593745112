export const georgian = {
    LANG_ACC_BANNED: 'თქვენი ანგარიში დაბლოკილია!',
    ALL_BETS: 'სულ ფსონი:',
    USER_ALREADY_REGISTERED: 'თქვენ უკვე დადებული გაქვთ ფსონი',
    ANIMATIONS: 'ანიმაცია',
    AUTOMATIC_BET_PANEL: 'ავტომატური',
    MANUAL_BET_PANEL: 'ძირითადი',
    AUTOMATIC_BETTING: 'ავტო ფსონი',
    AUTOMATIC_CASH_OUT: 'ავტო ქეშაუთი',
    AUTO_CASH_OUT: 'ავტო განაღდება',
    USER_BALANCE: 'ბალანსი',
    USER_BALANCE_ABBR: 'ბალ',
    ROUND: 'რაუნდი',
    RESULT_HASH_CODE: 'შედეგის Hash კოდი',
    CHECK_RESULT: 'შედეგის შემოწმება',
    USER_SUCCESSFULLY_REGISTERED: 'ფსონი მიღებულია',
    USER_SUCCESSFULLY_UNREGISTERED: 'ფსონი გაუქმებულია',
    USER_SUCCESSFULLY_CASHED_OUT: 'მოგება',
    WAITING_NEXT_ROUND: 'დაელოდეთ რაუნდის დასრულებას',
    NOT_ENOUGH_BALANCE: 'არასაკმარისი თანხა',
    REGISTER: 'ფსონის დადება',
    CASH_OUT: 'ქეშაუთი',
    INITIAL_BET: 'საწყისი ფსონი',
    BET: 'ფსონი',
    CANCEL_REGISTER: 'ფსონის გაუქმება',
    PREVIOUS_GAME: 'ისტორია',
    PLAYER: 'მოთამაშე',
    STOP_WHEN: 'გაჩერდი თუ ფსონი >',
    WINNING_AMOUNT: 'მოგება',
    BUY_IN: 'ფსონი',
    IF_LOST: 'წაგებისას',
    IF_WON: 'მოგებისას',
    CHAT: 'ჩათი',
    SOUND: 'ხმა',
    MUSIC: 'მუსიკა',
    TAKING_BETS_IN_PROGRESS: 'მიმდინარეობს ფსონების მიღება',
    CRUSHED_AT: 'კოეფ.',
    GAME_IS_STARTING: 'თამაში მალე დაიწყება',
    KEY: 'გასაღები',
    HASH_CODE: 'Hash კოდი',
    KEY_FOR_GAME: '1. კოეფიციენტის შემოწმება',
    CHECK_FAIR_QUESTION: '1. კოეფიციენტის შემოწმება',
    CHECK_FAIR_ANSWER:
        "თითოეულ თამაშში, თამაშის კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განსაზღვრულია წინასწარ, სანამ მოთამაშეები დარეგისტრირდებიან. წინასწარ განსაზღვრულია 5 თამაშის შედეგი. ქვემოთ მოყვანილია 5 გასული და 5 მომავალი თამაშის სანდოობის Hash კოდი.",
    CHECK_GAME_KEY_QUESTION: '2. თამაშის გასაღები და სანდოობის კოდი',
    CHECK_GAME_KEY_ANSWER:
        "თამაშების კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განისაზღვრება წინასწარ, 5 თამაშით ადრე. თამაშის სერვერზე გენერირდება თამაშის გასაღები, რომელიც შედგება შემდეგი პარამეტრებისგან: [თამაშის ნომერი]_[თამაშის კოეფიციენტი]_[Balloon]_[გენერირების დრო]_[თამაშის უნიკალური ნომერი]",
    CHECK_HASH_QUESTION: '3. სანდოობის კოდის შემოწმება',
    CHECK_HASH_ANSWER:
        'იმისათვის, რომ შეამოწმოთ სანდოობის კოდი (წინასწარ არის მოცემული მომდევნო 5 თამაშზე), საჭიროა თამაშის გასაღები დაშიფროთ ნებისმიერი ონლაინ SHA256 კალკულატორით. თამაშის გასაღები შეგიძლიათ იხილოთ ყოველი თამაშის დასრულების შემდეგ.',
    EXAMPLE: 'მაგალითად',
    EXAMPLE_RULE:
        'თამაშის ნომერი -  2751018 თამაშის კოეფიციენტი - 7.15 გენერირების დრო - 02/02/2021_13:34 თამაშის უნიკალური ნომერი - jTiIw5BXMuYcYm0b8g== შედეგად, თამაშის გასაღები იქნება: 2751018_7.15_Balloon_02/02/2021_13:34_jTiIw5BXMuYcYm0b8g== ხოლო მისგან SHA256 ალგორითმით გენერირებული სანდოობის კოდი: 3ae472c6d08b102a934be9d21801fa69c9f4145dd2c3df6323c4d57e9b0c7793',
    TIME: 'დრო',
    BETS: 'ფსონები',
    MY: 'ჩემი',
    RATINGS: 'რეიტინგი',
    WINNINGS_ON_MULTIPLIER: 'მოგ. კოეფ.',
    TOP_MULTIPLIER: 'ტოპ კოეფ.',
    TOP_WINNINGS: 'მოგებები',
    MULTIPLIER_WINNINGS: 'კოეფ. მოგება',
    DAY: 'დღის',
    MONTH: 'თვის',
    YEAR: 'წლის',
    ENTER_TEXT: 'შეიყვანეთ ტექსტი',
    SEND_MESSAGE: 'გაგზავნა',
    GAME_RULES: 'თამაშის წესები',
    RULE_1: 'ფსონის მინიმალური ოდენობა - 0.10₾; მაქსიმალური - 500₾.',
    RULE_2: 'საჰაერო ბურთის სიმაღლე მერყეობს 1-დან უსასრულობამდე.',
    RULE_3:
        'მოგება ითვლება: მიმდინარე სიმაღლის კოეფიციენტის და დადებული ფსონის ნამრავლით. მაქსიმალური მოგება ფსონზე შეადგენს 50 000₾-ს',
    RULE_4:
        'თუ აფეთქების მომენტში ფსონი არ იქნება დაქეშაუთებული, მაშინ ეს ფსონი წაგებულად ჩაითვლება.',
    RULE_5:
        'მოთამაშეს შეუძლია ერთდროულად ორი ფსონის დადება და მათი ცალ-ცალკე დაქეშაუთება.',
    RULE_6: 'მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.',
    RULE_7:
        'ერთი რაუნდის განმავლობაში მოთამაშეების მაქსიმალური ჯამური მოგება არის 100 000₾. იმ შემთხვევაში, როდესაც მოთამაშეთა მოგების ჯამი ერთ რაუნდში მიაღწევს 100 000₾-ს, სისტემა ავტომატურად დააქეშაუთებს ყველა ფსონს.',
};
