import { Context } from 'react';
import {
    GameStateSelectorContext,
    GameStatusStateSelectorContext,
    CurrentBetsSelectorContext,
    GameConfigsSelectorContext,
    PanelStateSelectorContext,
    PreviousBetsSelectorContext,
    GameHistorySelectorContext,
    NextGamesSelectorContext,
    BoardsSelectorContext,
} from './store/state/app.state';
import {
    SelectAudioPlayerState,
    SelectBoardsState,
    SelectCurrentBetsState,
    SelectGameConfigsState,
    SelectGameHistoryState,
    SelectGameState,
    SelectGameStatusState,
    SelectMessagesState,
    SelectMessengerState,
    SelectMyBetsState,
    SelectNextGamesState,
    SelectPanelState,
    SelectPreviousBetsState,
    SelectProfileState,
    SelectTopBetsState,
} from './store/selectors';
import { ErrorMessagesStateSelectorContext } from '../features/errorMessages/store/state';
import { MyBetsSelectorContext } from '../features/topBoards/myBets/configs/store/state';
import { TopBetsSelectorContext } from '../features/topBoards/topBets/configs/store/state';
import { ProfileSelectorContext } from '../features/profile/configs/store/state';
import { AudioPlayerStateSelectorContext } from '../features/audioPlayer/store/state';
import { MessengerStateSelectorContext } from '../features/chat/store/state';

export interface ContextItem<TValue> {
    context: Context<TValue>;
    value: TValue;
}

export function contextItem<TValue>(
    context: Context<TValue>,
    value: TValue
): ContextItem<TValue> {
    return {
        context,
        value,
    };
}

export function createContexts(): ContextItem<any>[] {
    return [
        contextItem(GameStateSelectorContext, SelectGameState),
        contextItem(GameStatusStateSelectorContext, SelectGameStatusState),
        contextItem(CurrentBetsSelectorContext, SelectCurrentBetsState),
        contextItem(PreviousBetsSelectorContext, SelectPreviousBetsState),
        contextItem(MyBetsSelectorContext, SelectMyBetsState),
        contextItem(TopBetsSelectorContext, SelectTopBetsState),
        contextItem(GameConfigsSelectorContext, SelectGameConfigsState),
        contextItem(PanelStateSelectorContext, SelectPanelState),
        contextItem(ErrorMessagesStateSelectorContext, SelectMessagesState),
        contextItem(AudioPlayerStateSelectorContext, SelectAudioPlayerState),
        contextItem(MessengerStateSelectorContext, SelectMessengerState),
        contextItem(ProfileSelectorContext, SelectProfileState),
        contextItem(GameHistorySelectorContext, SelectGameHistoryState),
        contextItem(NextGamesSelectorContext, SelectNextGamesState),
        contextItem(BoardsSelectorContext, SelectBoardsState),
    ];
}
