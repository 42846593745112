import React, { FC } from 'react';
import { IconProps } from './types';

export const Balloon: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 205.786 298.679"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="clipPath">
                    <path id="Rectangle_3041" d="M0 0H205.786V298.679H0z" fill="none" />
                </clipPath>
                <clipPath id="clipPath-2">
                    <path id="Path_3224" d="M74.147 253.069v16.865a28.745 28.745 0 0 0 57.491 0v-16.865z"
                          fill="none" />
                </clipPath>
                <clipPath id="clipPath-3">
                    <path id="Rectangle_3044"
                          d="M1.823 0h60.26a1.823 1.823 0 0 1 1.823 1.823v9.868a1.822 1.822 0 0 1-1.822 1.822H1.822A1.822 1.822 0 0 1 0 11.691V1.823A1.823 1.823 0 0 1 1.823 0z"
                          fill="none" transform="translate(70.94 210.846)" />
                </clipPath>
                <clipPath id="clipPath-5">
                    <path id="Path_3403"
                          d="M102.893 0a29.046 29.046 0 0 0-29.046 29.047c0 18.882 9.449 83.044 23.806 181.8h10.412c14.24-98.987 23.876-162.766 23.876-181.8A29.047 29.047 0 0 0 102.893 0z"
                          fill="none" />
                </clipPath>
                <clipPath id="clipPath-6">
                    <path id="Path_3404"
                          d="M102.893 0a29.047 29.047 0 0 0-29.047 29.047c0 18.882 9.449 83.044 23.807 181.8h10.411C122.3 111.859 131.94 48.08 131.94 29.047A29.047 29.047 0 0 0 102.893 0z"
                          fill="none" />
                </clipPath>
                <clipPath id="clipPath-8">
                    <path id="Path_3507"
                          d="M80.336 89.464c2.631 19.5 5.993 43.037 9.935 70.282h25.21c3.937-27.276 7.316-50.806 9.97-70.282z"
                          fill="none" />
                </clipPath>
                <linearGradient id="linear-gradient" x1="-1.29" x2="-1.272" y1="1" y2="1"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#c5453c" />
                    <stop offset=".001" stopColor="#c5453c" />
                    <stop offset=".472" stopColor="#d25e41" />
                    <stop offset="1" stopColor="#e38049" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="-1.11" x2="-1.094" y1="6.5" y2="6.5"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f7d385" />
                    <stop offset=".001" stopColor="#f7d385" />
                    <stop offset=".5" stopColor="#f5e8c6" />
                    <stop offset="1" stopColor="#f7d385" />
                </linearGradient>
                <linearGradient id="linear-gradient-3" x1="-1.271" x2="-1.254" y1="1.417" y2="1.417"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#7d4880" />
                    <stop offset=".012" stopColor="#7e477f" />
                    <stop offset=".178" stopColor="#934573" />
                    <stop offset=".342" stopColor="#9f446c" />
                    <stop offset=".5" stopColor="#a4446a" />
                    <stop offset=".658" stopColor="#9f446c" />
                    <stop offset=".822" stopColor="#934573" />
                    <stop offset=".988" stopColor="#7e477f" />
                    <stop offset="1" stopColor="#7d4880" />
                </linearGradient>
                <linearGradient id="linear-gradient-4" x1="-1.271" x2="-1.254" y1="1.417" y2="1.417"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#d5554c" />
                    <stop offset=".048" stopColor="#d8584d" />
                    <stop offset=".275" stopColor="#e46452" />
                    <stop offset=".498" stopColor="#e86954" />
                    <stop offset=".722" stopColor="#e46452" />
                    <stop offset=".952" stopColor="#d8584d" />
                    <stop offset="1" stopColor="#d5554c" />
                </linearGradient>
                <linearGradient id="linear-gradient-5" x1="-1.781" x2="-1.759" y1="2.977" y2="2.977"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#8ebe93" />
                    <stop offset=".118" stopColor="#99c796" />
                    <stop offset=".311" stopColor="#a5d299" />
                    <stop offset=".498" stopColor="#aad69b" />
                    <stop offset=".686" stopColor="#a5d299" />
                    <stop offset=".881" stopColor="#99c796" />
                    <stop offset="1" stopColor="#8ebe93" />
                </linearGradient>
            </defs>
            <path id="Rectangle_3037" d="M0 0H2.324V28.71H0z" fill="#01426a" transform="translate(74.147 224.359)" />
            <path id="Rectangle_3038" d="M0 0H2.324V28.71H0z" fill="#1d5b72" transform="translate(89.362 224.359)" />
            <path id="Rectangle_3039" d="M0 0H2.324V28.71H0z" fill="#1d5b72"
                  transform="translate(115.169 224.359)" />
            <path id="Rectangle_3040" d="M0 0H2.324V28.71H0z" fill="#01426a"
                  transform="translate(129.315 224.359)" />
            <g id="Group_4600">
                <g id="Group_4599" clipPath="url(#clipPath)">
                    <path id="Path_3222"
                          d="M131.639 275.3a9.3 9.3 0 0 0 9.295-9.295 10.3 10.3 0 0 0-2.951-6.992c-1.613-1.8-6.344-5.945-6.344-5.945s-4.731 4.141-6.344 5.945a10.3 10.3 0 0 0-2.951 6.992 9.3 9.3 0 0 0 9.3 9.295"
                          fill="#f7d385" />
                    <path id="Path_3223"
                          d="M74.147 275.3a9.3 9.3 0 0 0 9.295-9.295 10.3 10.3 0 0 0-2.951-6.992c-1.614-1.8-6.344-5.945-6.344-5.945s-4.731 4.141-6.344 5.945a10.3 10.3 0 0 0-2.951 6.992 9.3 9.3 0 0 0 9.3 9.295"
                          fill="#f7d385" />
                </g>
            </g>
            <g id="Group_4602">
                <g id="Group_4601" clipPath="url(#clipPath-2)">
                    <path id="Rectangle_3042" fill="url(#linear-gradient)" d="M0 0H57.491V45.61H0z"
                          transform="translate(74.147 253.069)" />
                </g>
            </g>
            <g id="Group_4604">
                <g id="Group_4603" clipPath="url(#clipPath-3)">
                    <path id="Rectangle_3043" fill="url(#linear-gradient-2)" d="M0 0H63.906V13.513H0z"
                          transform="translate(70.94 210.846)" />
                </g>
            </g>
            <g id="Group_4606">
                <g id="Group_4605" clipPath="url(#clipPath)">
                    <path id="Path_3225"
                          d="M102.893 275.3a9.3 9.3 0 0 0 9.3-9.295 10.3 10.3 0 0 0-2.951-6.992c-1.614-1.8-6.344-5.945-6.344-5.945s-4.731 4.141-6.344 5.945a10.3 10.3 0 0 0-2.951 6.992 9.3 9.3 0 0 0 9.295 9.295"
                          fill="#f7d385" />
                    <path id="Path_3226" fill="#f7d386"
                          d="M102.893 253.21c.015 0 4.69 4.088 6.289 5.875a10.2 10.2 0 0 1 2.921 6.925 9.21 9.21 0 0 1-18.42 0 10.207 10.207 0 0 1 2.92-6.925c1.6-1.787 6.275-5.875 6.29-5.875" />
                    <path id="Path_3227" fill="#f7d487"
                          d="M102.893 253.353c.03 0 4.649 4.033 6.234 5.8a10.1 10.1 0 0 1 2.891 6.856 9.125 9.125 0 1 1-18.25 0 10.1 10.1 0 0 1 2.891-6.856c1.585-1.771 6.2-5.8 6.234-5.8" />
                    <path id="Path_3228" fill="#f7d488"
                          d="M102.893 253.494c.045 0 4.608 3.979 6.179 5.733a10 10 0 0 1 2.862 6.789 9.041 9.041 0 1 1-18.082 0 10 10 0 0 1 2.862-6.789c1.571-1.754 6.134-5.733 6.179-5.733" />
                    <path id="Path_3229" fill="#f7d489"
                          d="M102.893 253.636c.061 0 4.568 3.925 6.125 5.664a9.9 9.9 0 0 1 2.831 6.721 8.957 8.957 0 0 1-17.913 0 9.906 9.906 0 0 1 2.832-6.721c1.557-1.739 6.064-5.664 6.125-5.664" />
                    <path id="Path_3230" fill="#f7d58a"
                          d="M102.893 253.779a79.387 79.387 0 0 1 6.069 5.593 9.8 9.8 0 0 1 2.8 6.653 8.872 8.872 0 0 1-17.744 0 9.8 9.8 0 0 1 2.8-6.653 79.389 79.389 0 0 1 6.069-5.593" />
                    <path id="Path_3231" fill="#f7d58b"
                          d="M102.893 253.92a74.676 74.676 0 0 1 6.014 5.522 9.7 9.7 0 0 1 2.773 6.585 8.787 8.787 0 1 1-17.574 0 9.707 9.707 0 0 1 2.772-6.585 74.818 74.818 0 0 1 6.015-5.522" />
                    <path id="Path_3232" fill="#f7d58c"
                          d="M102.893 254.062a70.407 70.407 0 0 1 5.959 5.452 9.6 9.6 0 0 1 2.743 6.518 8.7 8.7 0 0 1-17.4 0 9.6 9.6 0 0 1 2.743-6.518 70.251 70.251 0 0 1 5.959-5.452" />
                    <path id="Path_3233" fill="#f7d68d"
                          d="M102.893 254.2a65.951 65.951 0 0 1 5.9 5.381 9.5 9.5 0 0 1 2.713 6.45 8.618 8.618 0 0 1-17.235 0 9.5 9.5 0 0 1 2.713-6.45 65.926 65.926 0 0 1 5.9-5.381" />
                    <path id="Path_3234" fill="#f7d68e"
                          d="M102.893 254.346a62.3 62.3 0 0 1 5.85 5.311 9.4 9.4 0 0 1 2.683 6.382 8.533 8.533 0 0 1-17.066 0 9.4 9.4 0 0 1 2.683-6.382 62.174 62.174 0 0 1 5.85-5.311" />
                    <path id="Path_3235" fill="#f7d68f"
                          d="M102.893 254.488a58.7 58.7 0 0 1 5.794 5.24 9.3 9.3 0 0 1 2.654 6.315 8.448 8.448 0 0 1-16.9 0 9.306 9.306 0 0 1 2.653-6.315 58.8 58.8 0 0 1 5.795-5.24" />
                    <path id="Path_3236" fill="#f7d790"
                          d="M102.893 254.63a55.435 55.435 0 0 1 5.74 5.169 9.2 9.2 0 0 1 2.623 6.247 8.363 8.363 0 0 1-16.727 0 9.206 9.206 0 0 1 2.624-6.247 55.435 55.435 0 0 1 5.74-5.169" />
                    <path id="Path_3237" fill="#f7d791"
                          d="M102.893 254.772a52.457 52.457 0 0 1 5.685 5.1 9.1 9.1 0 0 1 2.594 6.179 8.279 8.279 0 1 1-16.558 0 9.1 9.1 0 0 1 2.594-6.179 52.457 52.457 0 0 1 5.685-5.1" />
                    <path id="Path_3238" fill="#f7d792"
                          d="M102.893 254.913a49.677 49.677 0 0 1 5.629 5.028 9 9 0 0 1 2.565 6.113 8.194 8.194 0 0 1-16.388 0 9.008 9.008 0 0 1 2.564-6.113 49.752 49.752 0 0 1 5.63-5.028" />
                    <path id="Path_3239" fill="#f7d893"
                          d="M102.893 255.056a47.129 47.129 0 0 1 5.575 4.957 8.9 8.9 0 0 1 2.532 6.044 8.109 8.109 0 0 1-16.218 0 8.9 8.9 0 0 1 2.534-6.044 47.049 47.049 0 0 1 5.575-4.957" />
                    <path id="Path_3240" fill="#f7d894"
                          d="M102.893 255.2a44.475 44.475 0 0 1 5.52 4.887 8.806 8.806 0 0 1 2.5 5.976 8.025 8.025 0 1 1-16.049 0 8.8 8.8 0 0 1 2.505-5.976 44.476 44.476 0 0 1 5.52-4.887" />
                    <path id="Path_3241" fill="#f7d895"
                          d="M102.893 255.339a42.279 42.279 0 0 1 5.465 4.816 8.709 8.709 0 0 1 2.475 5.91 7.94 7.94 0 1 1-15.88 0 8.7 8.7 0 0 1 2.475-5.91 42.28 42.28 0 0 1 5.465-4.816" />
                    <path id="Path_3242" fill="#f7d996"
                          d="M102.893 255.482a40.217 40.217 0 0 1 5.41 4.745 8.606 8.606 0 0 1 2.445 5.842 7.855 7.855 0 1 1-15.71 0 8.606 8.606 0 0 1 2.445-5.842 40.218 40.218 0 0 1 5.41-4.745" />
                    <path id="Path_3243" fill="#f7d997"
                          d="M102.893 255.623a38.161 38.161 0 0 1 5.355 4.675 8.5 8.5 0 0 1 2.416 5.773 7.771 7.771 0 1 1-15.542 0 8.5 8.5 0 0 1 2.416-5.773 38.1 38.1 0 0 1 5.355-4.675" />
                    <path id="Path_3244" fill="#f7d998"
                          d="M102.893 255.765a36.284 36.284 0 0 1 5.3 4.6 8.411 8.411 0 0 1 2.386 5.707 7.686 7.686 0 1 1-15.372 0 8.411 8.411 0 0 1 2.386-5.707 36.231 36.231 0 0 1 5.3-4.6" />
                    <path id="Path_3245" fill="#f7da99"
                          d="M102.893 255.907a34.508 34.508 0 0 1 5.245 4.533 8.308 8.308 0 0 1 2.356 5.639 7.6 7.6 0 0 1-15.2 0 8.309 8.309 0 0 1 2.357-5.639 34.458 34.458 0 0 1 5.245-4.533" />
                    <path id="Path_3246" fill="#f7da9a"
                          d="M102.893 256.049a32.819 32.819 0 0 1 5.19 4.464 8.2 8.2 0 0 1 2.327 5.57 7.517 7.517 0 0 1-15.034 0 8.2 8.2 0 0 1 2.327-5.57 32.774 32.774 0 0 1 5.19-4.464" />
                    <path id="Path_3247" fill="#f7da9b"
                          d="M102.893 256.191a31.26 31.26 0 0 1 5.135 4.394 8.1 8.1 0 0 1 2.3 5.5 7.432 7.432 0 1 1-14.864 0 8.1 8.1 0 0 1 2.3-5.5 31.218 31.218 0 0 1 5.135-4.394" />
                    <path id="Path_3248" fill="#f7db9c"
                          d="M102.893 256.333a29.76 29.76 0 0 1 5.08 4.322 8.005 8.005 0 0 1 2.267 5.435 7.347 7.347 0 1 1-14.694 0 8.005 8.005 0 0 1 2.267-5.435 29.721 29.721 0 0 1 5.08-4.322" />
                    <path id="Path_3249" fill="#f7db9d"
                          d="M102.893 256.475c.364 0 3.753 2.836 5.025 4.252a7.9 7.9 0 0 1 2.238 5.367 7.263 7.263 0 0 1-14.526 0 7.9 7.9 0 0 1 2.238-5.367c1.272-1.416 4.661-4.252 5.025-4.252" />
                    <path id="Path_3250" fill="#f7db9e"
                          d="M102.893 256.616c.379 0 3.712 2.781 4.97 4.182a7.8 7.8 0 0 1 2.208 5.3 7.178 7.178 0 1 1-14.356 0 7.8 7.8 0 0 1 2.208-5.3c1.258-1.4 4.591-4.182 4.97-4.182" />
                    <path id="Path_3251" fill="#f7dc9f"
                          d="M102.893 256.759c.4 0 3.671 2.727 4.915 4.11a7.7 7.7 0 0 1 2.178 5.233 7.093 7.093 0 1 1-14.186 0 7.707 7.707 0 0 1 2.177-5.233c1.245-1.383 4.521-4.11 4.916-4.11" />
                    <path id="Path_3252" fill="#f7dca0"
                          d="M102.893 256.9c.41 0 3.631 2.673 4.86 4.04a7.609 7.609 0 0 1 2.148 5.164 7.008 7.008 0 1 1-14.017 0 7.605 7.605 0 0 1 2.149-5.164c1.229-1.367 4.45-4.04 4.86-4.04" />
                    <path id="Path_3253" fill="#f7dca1"
                          d="M102.893 257.042c.425 0 3.59 2.618 4.805 3.97a7.505 7.505 0 0 1 2.119 5.1 6.924 6.924 0 1 1-13.848 0 7.505 7.505 0 0 1 2.119-5.1c1.215-1.352 4.38-3.97 4.805-3.97" />
                    <path id="Path_3254" fill="#f7dda2"
                          d="M102.893 257.185c.44 0 3.549 2.563 4.75 3.9a7.407 7.407 0 0 1 2.089 5.03 6.839 6.839 0 0 1-13.678 0 7.407 7.407 0 0 1 2.089-5.03c1.2-1.335 4.31-3.9 4.75-3.9" />
                    <path id="Path_3255" fill="#f7dda3"
                          d="M102.893 257.326c.455 0 3.508 2.51 4.695 3.828a7.3 7.3 0 0 1 2.059 4.962 6.754 6.754 0 0 1-13.508 0 7.3 7.3 0 0 1 2.059-4.962c1.186-1.318 4.24-3.828 4.695-3.828" />
                    <path id="Path_3256" fill="#f7dda4"
                          d="M102.893 257.468c.47 0 3.468 2.455 4.64 3.758a7.2 7.2 0 0 1 2.029 4.893 6.669 6.669 0 1 1-13.339 0 7.208 7.208 0 0 1 2.029-4.893c1.173-1.3 4.171-3.758 4.641-3.758" />
                    <path id="Path_3257" fill="#f7dea5"
                          d="M102.893 257.61c.485 0 3.427 2.4 4.585 3.688a7.1 7.1 0 0 1 2 4.826 6.585 6.585 0 1 1-13.17 0 7.1 7.1 0 0 1 2-4.826c1.158-1.287 4.1-3.688 4.585-3.688" />
                    <path id="Path_3258"
                          d="M102.893 257.752c.5 0 3.386 2.346 4.53 3.616a7.011 7.011 0 0 1 1.97 4.759 6.5 6.5 0 0 1-13 0 7.014 7.014 0 0 1 1.969-4.759c1.145-1.27 4.03-3.616 4.531-3.616"
                          fill="#f7dea6" />
                    <path id="Path_3259"
                          d="M102.893 257.894c.516 0 3.345 2.292 4.476 3.546a6.913 6.913 0 0 1 1.94 4.691 6.416 6.416 0 0 1-12.832 0 6.913 6.913 0 0 1 1.94-4.691c1.13-1.254 3.96-3.546 4.476-3.546"
                          fill="#f7dea6" />
                    <path id="Path_3260" fill="#f7dfa7"
                          d="M102.893 258.035c.531 0 3.305 2.238 4.42 3.476a6.806 6.806 0 0 1 1.911 4.623 6.331 6.331 0 0 1-12.662 0 6.806 6.806 0 0 1 1.911-4.623c1.115-1.238 3.889-3.476 4.42-3.476" />
                    <path id="Path_3261" fill="#f7dfa8"
                          d="M102.893 258.178c.546 0 3.264 2.183 4.365 3.4a6.706 6.706 0 0 1 1.881 4.556 6.246 6.246 0 0 1-12.493 0 6.711 6.711 0 0 1 1.881-4.556c1.1-1.221 3.82-3.4 4.366-3.4" />
                    <path id="Path_3262" fill="#f7e0a9"
                          d="M102.893 258.32c.562 0 3.223 2.129 4.311 3.334a6.613 6.613 0 0 1 1.851 4.488 6.162 6.162 0 0 1-12.324 0 6.608 6.608 0 0 1 1.851-4.488c1.087-1.2 3.749-3.334 4.311-3.334" />
                    <path id="Path_3263" fill="#f7e0aa"
                          d="M102.893 258.461c.577 0 3.183 2.074 4.255 3.265a6.5 6.5 0 0 1 1.822 4.42 6.077 6.077 0 0 1-12.154 0 6.506 6.506 0 0 1 1.821-4.42c1.073-1.191 3.679-3.265 4.256-3.265" />
                    <path id="Path_3264" fill="#f7e0ab"
                          d="M102.893 258.6c.592 0 3.142 2.02 4.2 3.193a6.409 6.409 0 0 1 1.791 4.352 5.992 5.992 0 1 1-11.984 0 6.409 6.409 0 0 1 1.791-4.352c1.059-1.173 3.609-3.193 4.2-3.193" />
                    <path id="Path_3265" fill="#f7e1ac"
                          d="M102.893 258.745c.607 0 3.1 1.966 4.146 3.123a6.312 6.312 0 0 1 1.762 4.284 5.908 5.908 0 0 1-11.816 0 6.312 6.312 0 0 1 1.762-4.284c1.045-1.157 3.539-3.123 4.146-3.123" />
                    <path id="Path_3266" fill="#f7e1ad"
                          d="M102.893 258.887c.622 0 3.06 1.911 4.091 3.053a6.206 6.206 0 0 1 1.732 4.217 5.823 5.823 0 0 1-11.646 0 6.206 6.206 0 0 1 1.73-4.217c1.03-1.142 3.469-3.053 4.091-3.053" />
                    <path id="Path_3267" fill="#f7e1ae"
                          d="M102.893 259.03c.637 0 3.02 1.856 4.036 2.981a6.113 6.113 0 0 1 1.7 4.15 5.738 5.738 0 0 1-11.476 0 6.108 6.108 0 0 1 1.7-4.15c1.016-1.125 3.4-2.981 4.036-2.981" />
                    <path id="Path_3268" fill="#f7e2af"
                          d="M102.893 259.171c.652 0 2.979 1.8 3.981 2.911a6.013 6.013 0 0 1 1.672 4.081 5.653 5.653 0 1 1-11.307 0 6.009 6.009 0 0 1 1.673-4.081c1-1.109 3.329-2.911 3.981-2.911" />
                    <path id="Path_3269" fill="#f7e2b0"
                          d="M102.893 259.313c.667 0 2.938 1.748 3.926 2.841a5.91 5.91 0 0 1 1.643 4.013 5.569 5.569 0 1 1-11.138 0 5.91 5.91 0 0 1 1.643-4.013c.988-1.093 3.259-2.841 3.926-2.841" />
                    <path id="Path_3270" fill="#f7e2b1"
                          d="M102.893 259.455c.683 0 2.9 1.693 3.871 2.77a5.807 5.807 0 0 1 1.613 3.946 5.484 5.484 0 1 1-10.968 0 5.807 5.807 0 0 1 1.613-3.946c.974-1.077 3.188-2.77 3.871-2.77" />
                    <path id="Path_3271" fill="#f7e3b2"
                          d="M102.893 259.6c.7 0 2.856 1.639 3.816 2.7a5.712 5.712 0 0 1 1.583 3.879 5.4 5.4 0 1 1-10.8 0 5.712 5.712 0 0 1 1.583-3.879c.959-1.06 3.118-2.7 3.816-2.7" />
                    <path id="Path_3272" fill="#f7e3b3"
                          d="M102.893 259.738c.713 0 2.816 1.585 3.761 2.629a5.612 5.612 0 0 1 1.554 3.811 5.315 5.315 0 1 1-10.63 0 5.612 5.612 0 0 1 1.554-3.811c.945-1.044 3.048-2.629 3.761-2.629" />
                    <path id="Path_3273" fill="#f7e3b4"
                          d="M102.893 259.881c.728 0 2.775 1.529 3.706 2.558a5.514 5.514 0 0 1 1.524 3.743 5.23 5.23 0 0 1-10.46 0 5.51 5.51 0 0 1 1.524-3.743c.931-1.029 2.977-2.558 3.706-2.558" />
                    <path id="Path_3274" fill="#f7e4b5"
                          d="M102.893 260.023c.743 0 2.734 1.476 3.651 2.487a5.417 5.417 0 0 1 1.5 3.676 5.146 5.146 0 0 1-10.292 0 5.412 5.412 0 0 1 1.495-3.676c.917-1.011 2.907-2.487 3.651-2.487" />
                    <path id="Path_3275" fill="#f7e4b6"
                          d="M102.893 260.164c.758 0 2.693 1.422 3.6 2.417a5.314 5.314 0 0 1 1.465 3.608 5.061 5.061 0 1 1-10.122 0 5.314 5.314 0 0 1 1.465-3.608c.9-.995 2.837-2.417 3.6-2.417" />
                    <path id="Path_3276" fill="#f7e4b7"
                          d="M102.893 260.307c.774 0 2.653 1.366 3.541 2.346a5.21 5.21 0 0 1 1.435 3.541 4.976 4.976 0 0 1-9.952 0 5.21 5.21 0 0 1 1.435-3.541c.888-.98 2.767-2.346 3.541-2.346" />
                    <path id="Path_3277" fill="#f7e5b8"
                          d="M102.893 260.448c.789 0 2.612 1.312 3.486 2.275a5.116 5.116 0 0 1 1.4 3.473 4.891 4.891 0 1 1-9.783 0 5.117 5.117 0 0 1 1.406-3.473c.874-.963 2.7-2.275 3.486-2.275" />
                    <path id="Path_3278" fill="#f7e5b9"
                          d="M102.893 260.59c.8 0 2.571 1.258 3.431 2.205a5.015 5.015 0 0 1 1.376 3.4 4.807 4.807 0 0 1-9.614 0 5.015 5.015 0 0 1 1.376-3.4c.86-.947 2.627-2.205 3.431-2.205" />
                    <path id="Path_3279" fill="#f7e5ba"
                          d="M102.893 260.733c.819 0 2.531 1.2 3.376 2.134a4.917 4.917 0 0 1 1.346 3.338 4.722 4.722 0 0 1-9.444 0 4.913 4.913 0 0 1 1.346-3.338c.845-.931 2.557-2.134 3.376-2.134" />
                    <path id="Path_3280" fill="#f7e6bb"
                          d="M102.893 260.874c.834 0 2.49 1.149 3.321 2.064a4.815 4.815 0 0 1 1.316 3.27 4.637 4.637 0 0 1-9.274 0 4.815 4.815 0 0 1 1.316-3.27c.831-.915 2.487-2.064 3.321-2.064" />
                    <path id="Path_3281" fill="#f7e6bc"
                          d="M102.893 261.016c.85 0 2.449 1.1 3.266 1.994a4.713 4.713 0 0 1 1.287 3.2 4.553 4.553 0 1 1-9.106 0 4.717 4.717 0 0 1 1.286-3.2c.818-.9 2.417-1.994 3.267-1.994" />
                    <path id="Path_3282" fill="#f7e6bd"
                          d="M102.893 261.158a6.027 6.027 0 0 1 3.211 1.923 4.611 4.611 0 0 1 1.257 3.134 4.468 4.468 0 0 1-8.936 0 4.611 4.611 0 0 1 1.257-3.134 6.024 6.024 0 0 1 3.211-1.923" />
                    <path id="Path_3283" fill="#f7e7be"
                          d="M102.893 261.3a5.741 5.741 0 0 1 3.156 1.853 4.511 4.511 0 0 1 1.227 3.066 4.383 4.383 0 0 1-8.766 0 4.511 4.511 0 0 1 1.227-3.066 5.741 5.741 0 0 1 3.156-1.853" />
                    <path id="Path_3284" fill="#f7e7bf"
                          d="M102.893 261.442a5.48 5.48 0 0 1 3.1 1.782 4.421 4.421 0 0 1 1.2 3 4.3 4.3 0 0 1-8.6 0 4.422 4.422 0 0 1 1.2-3 5.477 5.477 0 0 1 3.1-1.782" />
                    <path id="Path_3285" fill="#f7e7c0"
                          d="M102.893 261.584a5.215 5.215 0 0 1 3.046 1.711 4.314 4.314 0 0 1 1.168 2.931 4.214 4.214 0 0 1-8.428 0 4.314 4.314 0 0 1 1.168-2.931 5.212 5.212 0 0 1 3.046-1.711" />
                    <path id="Path_3286" fill="#f7e8c1"
                          d="M102.893 261.726a4.96 4.96 0 0 1 2.991 1.641 4.214 4.214 0 0 1 1.138 2.863 4.129 4.129 0 0 1-8.258 0 4.218 4.218 0 0 1 1.137-2.863 4.963 4.963 0 0 1 2.992-1.641" />
                    <path id="Path_3287" fill="#f7e8c2"
                          d="M102.893 261.867a4.738 4.738 0 0 1 2.937 1.57 4.12 4.12 0 0 1 1.107 2.8 4.044 4.044 0 1 1-8.088 0 4.12 4.12 0 0 1 1.107-2.8 4.736 4.736 0 0 1 2.937-1.57" />
                    <path id="Path_3288" fill="#f7e8c3"
                          d="M102.893 262.01a4.5 4.5 0 0 1 2.881 1.5 4.017 4.017 0 0 1 1.079 2.729 3.96 3.96 0 0 1-7.92 0 4.021 4.021 0 0 1 1.078-2.729 4.509 4.509 0 0 1 2.882-1.5" />
                    <path id="Path_3289" fill="#f7e9c4"
                          d="M102.893 262.152a4.29 4.29 0 0 1 2.827 1.429 3.923 3.923 0 0 1 1.048 2.66 3.875 3.875 0 1 1-7.75 0 3.918 3.918 0 0 1 1.048-2.66 4.288 4.288 0 0 1 2.827-1.429" />
                    <path id="Path_3290" fill="#f7e9c5"
                          d="M102.893 262.293a4.082 4.082 0 0 1 2.771 1.358 3.791 3.791 0 1 1-5.543 0 4.087 4.087 0 0 1 2.772-1.358" />
                    <path id="Path_3291" fill="#f7e9c6"
                          d="M102.893 262.436a3.891 3.891 0 0 1 2.717 1.287 3.706 3.706 0 1 1-5.434 0 3.891 3.891 0 0 1 2.717-1.287" />
                    <path id="Path_3292" fill="#f7eac7"
                          d="M102.893 262.577a3.7 3.7 0 0 1 2.662 1.217 3.621 3.621 0 1 1-5.324 0 3.7 3.7 0 0 1 2.662-1.217" />
                    <path id="Path_3293" fill="#f7eac8"
                          d="M106.429 266.255a3.536 3.536 0 1 1-3.536-3.536 3.537 3.537 0 0 1 3.536 3.536" />
                    <path id="Path_3294" fill="#00002d"
                          d="M205.786 92.735q0-1.2-.04-2.383c.024-.761.04-1.523.04-2.29a72.629 72.629 0 0 0-63.421-72.039 43.341 43.341 0 0 0-21.065-5.707 29.04 29.04 0 0 0-36.812 0 43.352 43.352 0 0 0-21.066 5.707A72.629 72.629 0 0 0 0 88.062c0 .769.016 1.533.04 2.3-.025.785-.04 1.578-.04 2.373 0 27.907 19.52 53.32 35.369 72.617 8.718 10.614 23.852 28.911 37.585 45.494h59.878c13.734-16.583 28.868-34.88 37.585-45.494 15.849-19.3 35.369-44.71 35.369-72.617" />
                    <path id="Path_3295"
                          d="M205.786 92.735q0-1.2-.04-2.383c.024-.761.04-1.523.04-2.29a72.629 72.629 0 0 0-63.421-72.039 43.341 43.341 0 0 0-21.065-5.707 29.039 29.039 0 0 0-36.811 0 43.346 43.346 0 0 0-21.066 5.707A72.629 72.629 0 0 0 0 88.062c0 .769.016 1.533.04 2.3-.025.785-.04 1.578-.04 2.373 0 27.907 19.519 53.32 35.368 72.617 8.718 10.614 23.852 28.911 37.585 45.494h59.878c13.734-16.583 28.868-34.88 37.585-45.494 15.849-19.3 35.369-44.71 35.369-72.617"
                          fill="#614087" />
                    <path id="Path_3296" fill="#603f86"
                          d="M62.35 17.224l-.049.007A71.731 71.731 0 0 0 .844 89.09c0 .76.026 1.517.054 2.272q-.03 1.173-.022 2.354c.16 27.567 19.429 52.74 35.158 72.008 7.843 9.608 20.847 25.4 33.326 40.542 1.273 1.536 2.631 3.065 3.885 4.58h59.388c13.578-16.472 28.507-34.59 37.155-45.182 15.728-19.265 34.99-44.428 35.144-71.983q.007-1.185-.022-2.359c.028-.753.048-1.509.054-2.267a71.727 71.727 0 0 0-61.528-71.831 42.463 42.463 0 0 0-21.01-6.566 29.585 29.585 0 0 0-18.894-6.783 29.041 29.041 0 0 0-8.443 1.247 29.137 29.137 0 0 0-7.858 3.055 28.832 28.832 0 0 0-3.258 2.156 40.589 40.589 0 0 0-21.623 6.891" />
                    <path id="Path_3297" fill="#5f3f85"
                          d="M61.278 18.426l-.048.007C27.153 23.712 1.085 53.682 1.686 90.117c.01.753.036 1.5.07 2.25q-.021 1.16-.006 2.33c.32 27.227 19.338 52.16 34.947 71.4 7.784 9.6 20.618 25.244 32.952 40.28 1.241 1.5 2.661 2.992 3.885 4.47h58.9c13.421-16.359 28.146-34.3 36.725-44.87 15.605-19.233 34.611-44.147 34.918-71.349.011-.782.008-1.559-.006-2.335.034-.746.06-1.493.07-2.244.592-36.433-25.533-66.358-59.634-71.622A41.668 41.668 0 0 0 123.552 11a30.253 30.253 0 0 0-19.383-6.988 29.143 29.143 0 0 0-8.894 1.382 29.317 29.317 0 0 0-8.326 2.818 28.617 28.617 0 0 0-3.491 2.14 38.559 38.559 0 0 0-22.18 8.075" />
                    <path id="Path_3298" fill="#5e3e84"
                          d="M60.207 19.627l-.046.008C26.928 25.36 1.706 54.977 2.531 91.145c.017.745.046 1.486.083 2.226q-.012 1.149.012 2.306c.48 26.887 19.247 51.58 34.736 70.791 7.725 9.582 20.388 25.087 32.576 40.016 1.212 1.463 2.692 2.92 3.887 4.362h58.411c13.264-16.248 27.785-34.01 36.3-44.558 15.483-19.2 34.232-43.866 34.693-70.715q.024-1.161.011-2.311c.038-.739.069-1.479.084-2.222.888-36.154-24.471-65.777-57.739-71.413a40.976 40.976 0 0 0-20.9-8.286 30.92 30.92 0 0 0-19.871-7.194 29.418 29.418 0 0 0-9.347 1.516 29.578 29.578 0 0 0-8.792 2.581 28.528 28.528 0 0 0-3.725 2.125 37.089 37.089 0 0 0-22.737 9.258" />
                    <path id="Path_3299" fill="#5d3e84"
                          d="M59.135 20.828l-.045.008C26.695 26.955 2.271 56.27 3.373 92.171c.023.738.056 1.471.1 2.2q0 1.137.03 2.282c.64 26.546 19.155 51 34.525 70.183 7.665 9.568 20.158 24.93 32.2 39.753 1.181 1.426 2.724 2.846 3.888 4.253h57.922c13.109-16.136 27.425-33.72 35.865-44.247 15.361-19.17 33.785-43.586 34.468-70.081.019-.765.03-1.527.028-2.287q.064-1.1.1-2.2c1.1-35.877-23.409-65.2-55.846-71.2a40.357 40.357 0 0 0-20.844-9.146 31.584 31.584 0 0 0-20.36-7.4 29.8 29.8 0 0 0-9.8 1.649 29.882 29.882 0 0 0-9.258 2.345 28.6 28.6 0 0 0-3.96 2.109 36.034 36.034 0 0 0-23.296 10.444" />
                    <path id="Path_3300" fill="#5c3d83"
                          d="M58.064 22.03l-.044.009C26.463 28.551 2.838 57.566 4.217 93.2q.042 1.1.113 2.181.006 1.125.046 2.258c.8 26.206 19.065 50.421 34.314 69.575 7.607 9.554 19.929 24.774 31.826 39.49 1.151 1.389 2.754 2.773 3.889 4.143h57.432c12.953-16.024 27.065-33.429 35.435-43.935 15.239-19.137 33.391-43.3 34.243-69.446q.037-1.137.046-2.263c.047-.724.086-1.449.113-2.178 1.378-35.6-22.347-64.615-53.952-70.994a39.866 39.866 0 0 0-20.789-10.006 32.252 32.252 0 0 0-20.848-7.605A30.2 30.2 0 0 0 95.832 6.2a30.156 30.156 0 0 0-9.724 2.108 28.822 28.822 0 0 0-4.194 2.092 35.319 35.319 0 0 0-23.85 11.63" />
                    <path id="Path_3301" fill="#5b3c82"
                          d="M56.992 23.231l-.042.01C26.231 30.145 3.4 58.859 5.06 94.226q.051 1.082.128 2.158.015 1.113.063 2.234c.961 25.865 18.974 49.841 34.1 68.966 7.547 9.541 19.7 24.617 31.449 39.227 1.121 1.353 2.786 2.7 3.891 4.035h56.943c12.8-15.913 26.705-33.14 35.005-43.624 15.117-19.106 33-43.025 34.017-68.812.03-.749.053-1.5.063-2.238q.078-1.075.129-2.156c1.656-35.323-21.285-64.034-52.059-70.785a39.448 39.448 0 0 0-20.729-10.866 32.919 32.919 0 0 0-21.337-7.81 30.655 30.655 0 0 0-10.705 1.919 30.5 30.5 0 0 0-10.191 1.871A29.285 29.285 0 0 0 81.4 10.42a34.863 34.863 0 0 0-24.408 12.811" />
                    <path id="Path_3302" fill="#5a3c81"
                          d="M55.921 24.432l-.042.011C26 31.739 3.967 60.153 5.9 95.253c.039.714.085 1.425.142 2.135q.024 1.1.081 2.21c1.12 25.525 18.882 49.261 33.892 68.358 7.488 9.528 19.469 24.461 31.074 38.964 1.091 1.316 2.817 2.627 3.892 3.926h56.459c12.64-15.8 26.343-32.85 34.574-43.312 15-19.074 32.6-42.746 33.793-68.179.034-.741.063-1.478.079-2.214q.085-1.063.144-2.133c1.934-35.046-20.223-63.452-50.165-70.576a39.139 39.139 0 0 0-20.678-11.725 33.582 33.582 0 0 0-21.825-8.016A31.181 31.181 0 0 0 96.2 6.744a30.917 30.917 0 0 0-10.654 1.634 29.669 29.669 0 0 0-4.661 2.06c-10.61 1.039-19.754 6.528-24.964 13.994" />
                    <path id="Path_3303" fill="#593b80"
                          d="M54.85 25.634l-.041.011C25.772 33.333 4.531 61.447 6.747 96.28c.045.707.1 1.41.157 2.112.023.726.054 1.455.1 2.187 1.281 25.185 18.792 48.681 33.682 67.749 7.428 9.515 19.239 24.3 30.7 38.7 1.06 1.28 2.848 2.553 3.894 3.816h55.965c12.484-15.69 25.983-32.56 34.145-43 14.869-19.044 32.21-42.464 33.563-67.544.04-.732.075-1.462.1-2.189q.093-1.053.159-2.111c2.213-34.769-19.161-62.872-48.271-70.367a38.9 38.9 0 0 0-20.623-12.585A34.256 34.256 0 0 0 108 4.827a31.744 31.744 0 0 0-11.61 2.187 31.361 31.361 0 0 0-11.124 1.4 30.046 30.046 0 0 0-4.895 2.044c-11.032 1.173-20.456 7.151-25.521 15.176" />
                    <path id="Path_3304" fill="#583b7f"
                          d="M53.778 26.835l-.039.012C25.543 34.926 5.092 62.739 7.591 97.308c.05.7.105 1.394.171 2.089q.042 1.077.115 2.162c1.441 24.845 18.7 48.1 33.471 67.141 7.369 9.5 19.01 24.149 30.323 38.438 1.03 1.244 2.879 2.481 3.9 3.708h55.476c12.327-15.577 25.623-32.27 33.715-42.689 14.751-19.01 31.818-42.186 33.341-66.91.045-.725.086-1.446.114-2.165q.1-1.042.174-2.089c2.493-34.493-18.1-62.291-46.378-70.158a38.755 38.755 0 0 0-20.572-13.445 34.927 34.927 0 0 0-22.8-8.427 32.345 32.345 0 0 0-12.066 2.321 31.808 31.808 0 0 0-11.59 1.162 30.638 30.638 0 0 0-5.129 2.027C68.4 11.785 58.7 18.252 53.778 26.835" />
                    <path id="Path_3305" fill="#573a7f"
                          d="M52.707 28.037l-.038.012C25.316 36.518 5.654 64.033 8.435 98.335c.055.691.115 1.379.186 2.066q.049 1.065.131 2.139c1.6 24.5 18.61 47.521 33.261 66.533 7.309 9.487 18.779 23.992 29.947 38.174 1 1.207 2.911 2.409 3.9 3.6h54.987c12.172-15.466 25.262-31.979 33.285-42.377 14.629-18.978 31.427-41.906 33.117-66.276.049-.717.1-1.43.13-2.141.072-.687.133-1.376.189-2.067 2.773-34.216-17.038-61.709-44.484-69.949a38.685 38.685 0 0 0-20.512-14.3A35.6 35.6 0 0 0 109.277 5.1a32.935 32.935 0 0 0-12.515 2.455 32.351 32.351 0 0 0-12.057.925 31.116 31.116 0 0 0-5.364 2.011c-11.874 1.447-21.858 8.4-26.634 17.546" />
                    <path id="Path_3306" fill="#56397e"
                          d="M51.635 29.238l-.037.013C25.089 38.109 6.214 65.325 9.277 99.363c.062.682.126 1.363.2 2.042q.06 1.055.149 2.115c1.76 24.165 18.518 46.942 33.049 65.925 7.251 9.473 18.55 23.835 29.572 37.912.97 1.17 2.942 2.335 3.9 3.489h54.5c12.015-15.355 24.9-31.69 32.854-42.066 14.508-18.946 31.037-41.626 32.892-65.641.055-.709.108-1.414.147-2.117.077-.68.142-1.362.2-2.044 3.055-33.941-15.976-61.129-42.59-69.74a38.687 38.687 0 0 0-20.45-15.165 36.274 36.274 0 0 0-23.78-8.837 33.576 33.576 0 0 0-12.973 2.589 32.884 32.884 0 0 0-12.523.688 31.813 31.813 0 0 0-5.6 2c-12.3 1.583-22.56 9.028-27.191 18.729" />
                    <path id="Path_3307" fill="#55397d"
                          d="M50.564 30.44l-.036.013C24.864 39.7 6.773 66.617 10.121 100.39c.067.675.136 1.348.215 2.02q.069 1.042.167 2.091c1.921 23.824 18.427 46.361 32.838 65.316 7.191 9.461 18.321 23.679 29.2 37.648.939 1.134 2.973 2.263 3.9 3.381h54.009c11.858-15.242 24.541-31.4 32.424-41.754 14.385-18.914 30.647-41.347 32.666-65.007.06-.7.12-1.4.165-2.092.081-.674.152-1.347.218-2.023 3.338-33.665-14.913-60.547-40.7-69.531a38.746 38.746 0 0 0-20.4-16.023 36.939 36.939 0 0 0-24.268-9.044A34.243 34.243 0 0 0 97.133 8.1a33.448 33.448 0 0 0-12.989.451 32.511 32.511 0 0 0-5.832 1.979C65.6 12.246 55.051 20.18 50.564 30.44" />
                    <path id="Path_3308" fill="#54387c"
                          d="M49.492 31.641l-.035.014C24.638 41.287 7.331 67.91 10.964 101.417c.072.667.145 1.333.23 2q.078 1.029.183 2.067c2.081 23.484 18.337 45.782 32.628 64.708 7.132 9.447 18.091 23.522 28.821 37.386.909 1.1 3 2.189 3.9 3.271h53.52c11.7-15.131 24.181-31.109 31.995-41.441 14.263-18.884 30.256-41.07 32.44-64.374.065-.693.131-1.382.182-2.069.086-.665.161-1.332.233-2 3.622-33.389-13.851-59.966-38.8-69.321a38.872 38.872 0 0 0-20.344-16.884 37.612 37.612 0 0 0-24.757-9.249 34.916 34.916 0 0 0-13.877 2.855 34.083 34.083 0 0 0-13.456.214 33.152 33.152 0 0 0-6.062 1.964c-13.139 1.855-23.963 10.278-28.3 21.1" />
                    <path id="Path_3309" fill="#53387b"
                          d="M48.421 32.842l-.033.016c-23.972 10.016-40.5 36.342-36.58 69.587.078.659.155 1.317.245 1.974q.085 1.016.2 2.043c2.24 23.143 18.245 45.2 32.415 64.1 7.073 9.433 17.861 23.365 28.446 37.123.878 1.06 3.034 2.116 3.9 3.162h53.031c11.545-15.019 23.819-30.819 31.563-41.13 14.142-18.851 29.869-40.79 32.216-63.739.071-.685.142-1.366.2-2.045.09-.657.17-1.317.248-1.978 3.9-33.114-12.791-59.384-36.91-69.112A39.066 39.066 0 0 0 137.076 15.1a38.287 38.287 0 0 0-25.246-9.456A35.6 35.6 0 0 0 97.5 8.635a34.745 34.745 0 0 0-13.924-.021 33.839 33.839 0 0 0-6.3 1.948c-13.561 1.99-24.665 10.9-28.862 22.28" />
                    <path id="Path_3310" fill="#52377a"
                          d="M47.349 34.044l-.032.015c-23.125 10.4-38.874 36.434-34.666 69.413.083.651.165 1.3.259 1.951q.1 1.005.218 2.019c2.4 22.8 18.154 44.622 32.2 63.491 7.014 9.42 17.632 23.209 28.071 36.86.848 1.024 3.066 2.043 3.9 3.053h52.543c11.389-14.907 23.459-30.529 31.134-40.818 14.019-18.819 29.481-40.512 31.99-63.106.075-.676.153-1.349.216-2.019.1-.651.18-1.3.263-1.956 4.191-32.839-11.728-58.8-35.016-68.9A39.306 39.306 0 0 0 138.2 15.44a38.956 38.956 0 0 0-25.734-9.66A36.288 36.288 0 0 0 97.69 8.907a35.325 35.325 0 0 0-14.39-.259 34.547 34.547 0 0 0-6.533 1.931C62.786 12.706 51.4 22.107 47.349 34.044" />
                    <path id="Path_3311" fill="#51367a"
                          d="M46.278 35.245l-.031.016C23.97 46.042 9 71.784 13.495 104.5c.088.643.175 1.286.273 1.928q.105.993.235 1.995C16.564 130.885 32.067 152.464 46 171.3c6.954 9.406 17.4 23.052 27.695 36.6.817.988 3.1 1.971 3.9 2.945h52.055c11.233-14.8 23.1-30.24 30.7-40.507 13.9-18.787 29.094-40.233 31.765-62.471.081-.669.164-1.333.233-2 .1-.644.19-1.289.279-1.933 4.477-32.564-10.666-58.223-33.123-68.7a39.6 39.6 0 0 0-20.174-19.453 39.635 39.635 0 0 0-26.223-9.866 36.983 36.983 0 0 0-15.231 3.262 35.989 35.989 0 0 0-14.856-.5 35.252 35.252 0 0 0-6.766 1.923c-14.4 2.263-26.068 12.153-29.976 24.649" />
                    <path id="Path_3312" fill="#503679"
                          d="M45.206 36.446l-.03.018C23.749 47.623 9.55 73.075 14.338 105.526c.094.635.185 1.271.288 1.9.076.655.16 1.311.252 1.972 2.721 22.122 17.973 43.461 31.784 62.273 6.9 9.394 17.172 22.9 27.319 36.334.787.951 3.129 1.9 3.906 2.835h51.564c11.077-14.683 22.738-29.949 30.274-40.2 13.775-18.755 28.708-39.954 31.54-61.837.086-.66.175-1.317.25-1.971.1-.637.2-1.274.293-1.911 4.765-32.289-9.6-57.642-31.228-68.486a39.937 39.937 0 0 0-20.124-20.322 40.3 40.3 0 0 0-26.71-10.072 37.718 37.718 0 0 0-15.684 3.405 36.7 36.7 0 0 0-15.322-.731 35.972 35.972 0 0 0-7 1.9c-14.825 2.4-26.77 12.779-30.533 25.833" />
                    <path id="Path_3313" fill="#4f3578"
                          d="M44.135 37.648l-.029.017c-20.577 11.537-34 36.7-28.925 68.889l.3 1.882q.122.969.269 1.947c2.881 21.783 17.881 42.883 31.573 61.666 6.835 9.38 16.942 22.74 26.943 36.07.758.915 3.16 1.825 3.907 2.727h51.075c10.921-14.573 22.378-29.659 29.844-39.884 13.654-18.723 28.323-39.676 31.315-61.2.091-.653.186-1.3.267-1.947.109-.629.208-1.259.308-1.889 5.054-32.013-8.542-57.061-29.335-68.276a40.324 40.324 0 0 0-20.067-21.183 40.98 40.98 0 0 0-27.2-10.276 38.446 38.446 0 0 0-16.132 3.526 37.4 37.4 0 0 0-15.789-.968 36.72 36.72 0 0 0-7.235 1.882c-15.246 2.535-27.471 13.4-31.089 27.017" />
                    <path id="Path_3314" fill="#4e3577"
                          d="M43.064 38.85l-.028.017c-19.728 11.911-32.383 36.792-27.011 68.714.1.62.2 1.24.318 1.859q.13.958.286 1.924c3.041 21.442 17.79 42.3 31.362 61.057C54.767 181.787 64.7 195 74.56 208.229c.726.878 3.191 1.752 3.907 2.617h50.587c10.764-14.46 22.017-29.369 29.413-39.571 13.533-18.693 27.94-39.4 31.09-60.57.1-.645.2-1.284.284-1.922.115-.622.218-1.245.323-1.867 5.343-31.738-7.48-56.48-27.441-68.067a40.737 40.737 0 0 0-20.012-22.042 41.649 41.649 0 0 0-27.688-10.483 39.166 39.166 0 0 0-16.589 3.664 38.114 38.114 0 0 0-16.255-1.2 37.464 37.464 0 0 0-7.469 1.866C59.042 13.32 46.538 24.677 43.064 38.85" />
                    <path id="Path_3315" fill="#4d3476"
                          d="M41.992 40.051l-.026.018c-18.878 12.283-30.765 36.88-25.1 68.54.11.611.215 1.224.332 1.835q.141.946.3 1.9c3.2 21.1 17.7 41.723 31.152 60.449 6.716 9.353 16.482 22.427 26.192 35.545.7.841 3.222 1.679 3.909 2.508h50.1c10.609-14.349 21.657-29.079 28.984-39.26 13.41-18.66 27.556-39.119 30.865-59.935.1-.637.208-1.268.3-1.9.119-.615.228-1.23.338-1.845 5.634-31.463-6.418-55.9-25.548-67.858a41.2 41.2 0 0 0-19.956-22.9A42.324 42.324 0 0 0 115.661 6.46a39.915 39.915 0 0 0-17.041 3.8A38.838 38.838 0 0 0 81.9 8.816a38.373 38.373 0 0 0-7.7 1.85C58.111 13.473 45.326 25.32 42 40.051" />
                    <path id="Path_3316"
                          d="M40.921 41.253a.164.164 0 0 0-.025.019c-18.028 12.651-29.147 36.969-23.184 68.364.115.6.225 1.209.347 1.813q.149.935.32 1.876c3.362 20.762 17.607 41.143 30.94 59.841 6.658 9.339 16.254 22.27 25.817 35.281.666.8 3.255 1.6 3.911 2.4h49.609c10.451-14.237 21.295-28.789 28.554-38.948 13.287-18.628 27.173-38.841 30.638-59.3.108-.629.22-1.252.319-1.874.123-.607.237-1.215.353-1.822 5.925-31.189-5.357-55.318-23.655-67.649a41.7 41.7 0 0 0-19.9-23.762A42.994 42.994 0 0 0 116.3 6.6a40.645 40.645 0 0 0-17.494 3.932A39.6 39.6 0 0 0 81.617 8.85a39.155 39.155 0 0 0-7.937 1.834C57.17 13.627 44.1 25.962 40.921 41.253"
                          fill="#4c3375" />
                    <path id="Path_3317"
                          d="M39.849 42.454l-.024.02c-17.179 13.017-27.531 37.059-21.27 68.189.12.6.234 1.194.361 1.79q.159.922.338 1.853c3.521 20.421 17.517 40.562 30.73 59.231 6.6 9.327 16.023 22.115 25.441 35.019.636.768 3.285 1.532 3.912 2.29h49.119c10.3-14.125 20.936-28.5 28.124-38.637 13.166-18.6 26.793-38.562 30.414-58.666.112-.621.23-1.236.335-1.85.129-.6.247-1.2.368-1.8 6.219-30.913-4.294-54.737-21.761-67.44a42.217 42.217 0 0 0-19.845-24.622 43.675 43.675 0 0 0-29.154-11.1A41.409 41.409 0 0 0 98.991 10.8a40.353 40.353 0 0 0-17.655-1.916 39.867 39.867 0 0 0-8.171 1.816c-16.932 3.08-30.277 15.9-33.316 31.754"
                          fill="#4c3375" />
                    <path id="Path_3318" fill="#4b3274"
                          d="M38.777 43.655l-.023.02C22.424 57.054 12.838 80.824 19.4 111.69c.125.588.245 1.178.376 1.767q.167.911.355 1.828c3.681 20.081 17.426 39.983 30.519 58.624 6.538 9.312 15.793 21.957 25.066 34.756.605.731 3.315 1.459 3.913 2.18h48.63c10.14-14.013 20.575-28.209 27.694-38.325 13.044-18.564 26.413-38.283 30.189-58.032.117-.613.241-1.22.351-1.826.134-.592.258-1.186.383-1.778 6.515-30.637-3.232-54.155-19.866-67.231a42.782 42.782 0 0 0-19.79-25.48 44.348 44.348 0 0 0-29.645-11.305 42.174 42.174 0 0 0-18.4 4.2 41.021 41.021 0 0 0-26.525-.35C55.3 13.934 41.672 27.247 38.777 43.655" />
                    <path id="Path_3319" fill="#4a3173"
                          d="M37.706 44.857l-.022.021C22.2 58.616 13.38 82.117 20.242 112.718c.13.58.255 1.162.39 1.744q.177.9.372 1.805c3.842 19.74 17.335 39.4 30.308 58.015 6.479 9.3 15.564 21.8 24.691 34.492.576.695 3.347 1.387 3.914 2.072h48.142c9.983-13.9 20.214-27.919 27.263-38.012 12.922-18.534 26.034-38.006 29.964-57.4.122-.6.253-1.2.369-1.8.138-.585.266-1.171.4-1.755 6.811-30.363-2.17-53.575-17.972-67.022a43.36 43.36 0 0 0-19.735-26.341 45.022 45.022 0 0 0-30.131-11.51 42.921 42.921 0 0 0-18.852 4.334 41.833 41.833 0 0 0-27.226-.6c-17.775 3.351-31.68 17.154-34.43 34.121" />
                    <path id="Path_3320" fill="#493172"
                          d="M36.634 46.058l-.021.021c-14.637 14.1-22.692 37.33-15.528 67.666.135.572.264 1.147.405 1.721q.185.886.39 1.78c4 19.4 17.243 38.822 30.1 57.406 6.42 9.285 15.335 21.645 24.316 34.23.545.658 3.378 1.313 3.915 1.963h47.649c9.827-13.79 19.854-27.629 26.833-37.7 12.8-18.5 25.656-37.727 29.738-56.764.128-.6.264-1.188.386-1.778.144-.578.277-1.156.413-1.732 7.108-30.087-1.108-52.994-16.079-66.814a43.973 43.973 0 0 0-19.679-27.2 45.7 45.7 0 0 0-30.62-11.717 43.7 43.7 0 0 0-19.3 4.469 42.652 42.652 0 0 0-27.927-.856c-18.2 3.487-32.382 17.779-34.987 35.3" />
                    <path id="Path_3321" fill="#483071"
                          d="M35.563 47.259a.209.209 0 0 1-.02.022C21.75 61.729 14.46 84.7 21.929 114.772c.14.565.274 1.132.42 1.7q.193.875.406 1.757c4.162 19.06 17.153 38.243 29.886 56.8 6.36 9.273 15.105 21.488 23.94 33.967.515.621 3.409 1.24 3.917 1.853h47.163c9.671-13.678 19.493-27.338 26.4-37.389 12.679-18.469 25.28-37.448 29.514-56.13.133-.589.275-1.172.4-1.754.148-.57.287-1.142.428-1.71 7.406-29.811-.046-52.413-14.185-66.6A44.606 44.606 0 0 0 150.6 19.2a46.378 46.378 0 0 0-31.109-11.924 44.445 44.445 0 0 0-19.757 4.6 43.461 43.461 0 0 0-28.627-1.108C52.489 14.4 38.023 29.176 35.563 47.259" />
                    <path id="Path_3322" fill="#473070"
                          d="M34.492 48.461l-.019.022C21.52 63.281 15 86 22.772 115.8c.145.557.285 1.117.435 1.675q.2.864.423 1.734c4.321 18.719 17.061 37.663 29.674 56.19 6.3 9.258 14.875 21.331 23.565 33.7.484.585 3.44 1.168 3.918 1.745h46.674c9.515-13.567 19.133-27.049 25.974-37.078 12.556-18.437 24.9-37.169 29.287-55.5.14-.581.287-1.156.421-1.729.152-.564.3-1.127.442-1.689 7.707-29.534 1.016-51.831-12.292-66.395a45.259 45.259 0 0 0-19.568-28.92 47.051 47.051 0 0 0-31.6-12.126A45.219 45.219 0 0 0 99.92 12.15a44.293 44.293 0 0 0-29.327-1.361c-19.04 3.758-33.786 19.028-36.1 37.672" />
                    <path id="Path_3323" fill="#462f70"
                          d="M33.42 49.662a.2.2 0 0 1-.017.023c-12.115 15.146-17.87 37.6-9.788 67.142.151.548.3 1.1.45 1.651q.211.852.44 1.71c4.482 18.379 16.971 37.083 29.465 55.581 6.242 9.246 14.645 21.175 23.189 33.441.454.549 3.471 1.095 3.919 1.636h46.185c9.358-13.455 18.773-26.759 25.543-36.767 12.435-18.4 24.531-36.889 29.063-54.862.145-.573.3-1.139.437-1.7.158-.557.306-1.113.458-1.666 8.008-29.258 2.077-51.251-10.4-66.187a45.942 45.942 0 0 0-19.513-29.779 47.723 47.723 0 0 0-32.083-12.336 45.988 45.988 0 0 0-20.661 4.871 45.152 45.152 0 0 0-30.028-1.614C50.617 14.7 35.591 30.46 33.42 49.662" />
                    <path id="Path_3324" fill="#452e6f"
                          d="M32.349 50.864a.188.188 0 0 1-.016.023c-11.281 15.491-16.266 37.7-7.874 66.967.155.541.305 1.086.464 1.629q.22.84.458 1.686c4.641 18.039 16.88 36.5 29.253 54.973 6.183 9.231 14.416 21.018 22.814 33.178.423.511 3.5 1.021 3.921 1.526h45.7c9.2-13.343 18.411-26.469 25.113-36.455 12.312-18.373 24.157-36.609 28.838-54.228.149-.565.308-1.123.454-1.68.162-.549.315-1.1.472-1.644 8.311-28.979 3.139-50.669-8.505-65.977a46.634 46.634 0 0 0-19.457-30.64 48.4 48.4 0 0 0-32.577-12.537 46.755 46.755 0 0 0-21.114 5.006 46 46 0 0 0-30.729-1.867C49.681 14.855 34.375 31.1 32.349 50.864" />
                    <path id="Path_3325" fill="#442e6e"
                          d="M31.277 52.065l-.015.024C20.811 67.924 16.6 89.878 25.3 118.881c.16.534.314 1.07.478 1.606q.229.828.475 1.662c4.8 17.7 16.788 35.923 29.042 54.365 6.124 9.218 14.186 20.862 22.438 32.914.394.475 3.534.949 3.922 1.418h45.207c9.046-13.232 18.051-26.179 24.683-36.143 12.19-18.342 23.786-36.329 28.612-53.594.156-.557.32-1.106.471-1.656.167-.542.326-1.083.488-1.622 8.616-28.7 4.2-50.088-6.611-65.768a47.357 47.357 0 0 0-19.4-31.5 49.082 49.082 0 0 0-33.061-12.742 47.537 47.537 0 0 0-21.567 5.14 46.847 46.847 0 0 0-31.429-2.12c-20.3 4.167-35.89 20.9-37.771 41.224" />
                    <path id="Path_3326" fill="#432d6d"
                          d="M30.206 53.267l-.014.024c-9.624 16.177-13.063 37.883-4.046 66.618.165.525.324 1.055.493 1.583q.239.816.492 1.638c4.962 17.359 16.7 35.343 28.832 53.756 6.064 9.205 13.956 20.705 22.062 32.652.364.438 3.565.875 3.924 1.308h44.718c8.889-13.12 17.69-25.889 24.253-35.831 12.069-18.31 23.415-36.049 28.387-52.96.161-.549.331-1.09.488-1.632s.336-1.068.5-1.6c8.922-28.423 5.264-49.508-4.716-65.559a48.09 48.09 0 0 0-19.347-32.359 49.755 49.755 0 0 0-33.55-12.949 48.32 48.32 0 0 0-22.019 5.274 47.706 47.706 0 0 0-32.129-2.372c-20.726 4.3-36.592 21.529-38.329 42.408" />
                    <path id="Path_3327" fill="#422d6c"
                          d="M29.134 54.468c0 .008-.008.017-.013.025-8.8 16.518-11.465 37.978-2.133 66.443.17.518.335 1.039.508 1.56s.335 1.074.51 1.615c5.122 17.018 16.606 34.762 28.621 53.147 6.005 9.191 13.726 20.549 21.687 32.389.333.4 3.6.8 3.924 1.2h44.229c8.733-13.008 17.331-25.6 23.823-35.52 11.947-18.277 23.047-35.767 28.162-52.325.167-.541.342-1.075.505-1.608s.346-1.054.517-1.577c9.23-28.143 6.326-48.927-2.823-65.35a48.843 48.843 0 0 0-19.29-33.219 50.437 50.437 0 0 0-34.04-13.154 49.1 49.1 0 0 0-22.473 5.406 48.566 48.566 0 0 0-32.829-2.625c-21.147 4.44-37.293 22.156-38.885 43.593" />
                    <path id="Path_3328" fill="#412c6b"
                          d="M28.063 55.67c0 .008-.007.017-.012.026-7.984 16.86-9.868 38.073-.218 66.268.174.51.344 1.023.522 1.537s.346 1.058.526 1.59c5.282 16.678 16.515 34.184 28.41 52.539 5.946 9.178 13.5 20.393 21.312 32.125.3.367 3.627.731 3.926 1.091h43.74c8.577-12.9 16.969-25.308 23.393-35.208 11.824-18.246 22.679-35.486 27.936-51.691.173-.532.353-1.058.523-1.584s.355-1.039.531-1.554c9.539-27.864 7.388-48.346-.929-65.141a49.607 49.607 0 0 0-19.235-34.079A51.116 51.116 0 0 0 123.96 8.23a49.87 49.87 0 0 0-22.924 5.543A49.431 49.431 0 0 0 67.5 10.9c-21.564 4.569-37.99 22.773-39.437 44.77" />
                    <path id="Path_3329" fill="#402b6b"
                          d="M26.991 56.871a.2.2 0 0 1-.01.026c-7.172 17.2-8.275 38.171 1.695 66.094.179.5.354 1.008.537 1.514s.358 1.042.543 1.567c5.442 16.337 16.424 33.6 28.2 51.93 5.887 9.164 13.267 20.237 20.936 31.863.273.329 3.659.657 3.928.981h43.25c8.42-12.784 16.609-25.018 22.963-34.9 11.7-18.214 22.312-35.2 27.711-51.058.179-.524.364-1.042.539-1.559s.366-1.024.547-1.532c9.849-27.582 8.45-47.764.964-64.932a50.386 50.386 0 0 0-19.179-34.938A51.79 51.79 0 0 0 124.6 8.366a50.678 50.678 0 0 0-23.377 5.676 50.322 50.322 0 0 0-34.23-3.13c-21.99 4.71-38.7 23.4-40 45.959" />
                    <path id="Path_3330" fill="#3f2b6a"
                          d="M25.92 58.072l-.009.027c-6.363 17.545-6.682 38.268 3.608 65.918.184.5.364.993.552 1.492s.37 1.026.56 1.542c5.6 16 16.333 33.024 27.988 51.323 5.827 9.151 13.037 20.079 20.56 31.6.242.292 3.69.583 3.929.872h42.762c8.264-12.672 16.249-24.728 22.533-34.585 11.58-18.181 21.948-34.919 27.486-50.422.184-.516.375-1.026.556-1.536s.376-1.009.562-1.51c10.162-27.3 9.512-47.183 2.858-64.722a51.182 51.182 0 0 0-19.124-35.8A52.466 52.466 0 0 0 125.236 8.5a51.459 51.459 0 0 0-23.83 5.812 51.217 51.217 0 0 0-34.93-3.385c-22.412 4.847-39.4 24.029-40.556 47.144" />
                    <path id="Path_3331" fill="#3e2a69"
                          d="M24.848 59.274l-.007.028c-5.558 17.89-5.093 38.368 5.522 65.743.188.487.374.978.566 1.469s.381 1.01.578 1.519c5.762 15.657 16.242 32.443 27.777 50.714 5.768 9.137 12.807 19.923 20.185 31.336.212.255 3.721.51 3.93.763h42.273c8.108-12.561 15.888-24.438 22.1-34.272 11.458-18.151 21.584-34.636 27.26-49.79.191-.508.387-1.01.574-1.51s.385-1 .577-1.489c10.475-27.016 10.573-46.6 4.751-64.513a51.989 51.989 0 0 0-19.068-36.658 53.143 53.143 0 0 0-35.991-13.976 52.263 52.263 0 0 0-24.282 5.945 52.07 52.07 0 0 0-35.631-3.636C43.127 15.929 25.861 35.6 24.848 59.274" />
                    <path id="Path_3332" fill="#3d2a68"
                          d="M23.777 60.475l-.006.028c-4.756 18.238-3.5 38.469 7.435 65.569.194.479.384.963.581 1.445q.292.746.595 1.5c5.922 15.316 16.151 31.863 27.566 50.1 5.709 9.124 12.578 19.767 19.81 31.073.182.22 3.752.438 3.931.655h41.784c7.952-12.445 15.527-24.145 21.673-33.96 11.337-18.119 21.222-34.351 27.036-49.156.2-.5.4-.993.59-1.486s.4-.98.592-1.466c10.791-26.732 11.635-46.021 6.645-64.3A52.808 52.808 0 0 0 163 22.955a53.82 53.82 0 0 0-36.487-14.182 53.047 53.047 0 0 0-24.735 6.08 52.95 52.95 0 0 0-36.332-3.889c-23.254 5.118-40.8 25.279-41.669 49.511" />
                    <path id="Path_3333" fill="#3c2967"
                          d="M22.706 61.676v.029c-3.958 18.588-1.918 38.572 9.349 65.394.2.472.394.947.6 1.423s.4.978.612 1.471c6.082 14.976 16.06 31.284 27.355 49.5 5.649 9.11 12.348 19.61 19.434 30.811.152.182 3.784.364 3.933.544h41.295c7.8-12.337 15.167-23.858 21.243-33.649 11.214-18.087 20.861-34.064 26.81-48.521.2-.491.408-.977.607-1.462s.406-.965.607-1.444c11.108-26.447 12.7-45.44 8.539-64.095A53.638 53.638 0 0 0 164.123 23.3a54.5 54.5 0 0 0-36.971-14.391 53.846 53.846 0 0 0-25.187 6.215 53.831 53.831 0 0 0-37.032-4.142 53.794 53.794 0 0 0-42.227 50.694" />
                    <path id="Path_3334" fill="#3b2866"
                          d="M21.634 62.878a.254.254 0 0 1 0 .029c-3.162 18.942-.334 38.676 11.263 65.22.2.464.4.931.61 1.4s.417.963.63 1.448c6.242 14.635 15.969 30.7 27.144 48.888 5.59 9.1 12.118 19.454 19.059 30.547.121.147 3.814.292 3.934.437h40.806c7.639-12.226 14.806-23.569 20.812-33.338 11.093-18.055 20.5-33.777 26.585-47.887.209-.483.42-.961.625-1.438s.415-.95.621-1.421c11.427-26.16 13.76-44.859 10.433-63.887a54.477 54.477 0 0 0-18.9-39.236 55.208 55.208 0 0 0-63.1-8.245A54.717 54.717 0 0 0 64.417 11a54.651 54.651 0 0 0-42.783 51.878" />
                    <path id="Path_3335" fill="#3a2866"
                          d="M20.563 64.079v.03c-2.369 19.3 1.248 38.782 13.177 65.045.207.456.413.916.624 1.376s.429.947.647 1.424c6.4 14.295 15.878 30.124 26.934 48.28 5.53 9.084 11.888 19.3 18.683 30.284.091.11 3.845.219 3.935.328h40.317c7.483-12.115 14.446-23.279 20.382-33.027 10.968-18.019 20.138-33.488 26.358-47.252.214-.475.43-.945.641-1.414s.426-.935.636-1.4c11.748-25.872 14.822-44.278 12.327-63.677a55.329 55.329 0 0 0-18.847-40.1 55.85 55.85 0 0 0-37.948-14.8 55.43 55.43 0 0 0-26.092 6.482A55.621 55.621 0 0 0 63.9 11.016a55.512 55.512 0 0 0-43.34 53.063" />
                    <path id="Path_3336" fill="#392765"
                          d="M19.491 65.281v.03c-1.577 19.659 2.828 38.89 15.09 64.871.212.449.424.9.64 1.353s.439.931.664 1.4c6.56 13.955 15.785 29.544 26.715 47.672 5.471 9.07 11.659 19.14 18.308 30.021.06.072 3.877.146 3.936.218h39.829c7.326-12 14.084-22.988 19.952-32.715 10.849-17.991 19.788-33.2 26.135-46.618l.658-1.39.651-1.376c12.07-25.582 15.884-43.7 14.22-63.469a57.58 57.58 0 0 0-57.227-55.96 56.242 56.242 0 0 0-26.545 6.616 56.849 56.849 0 0 0-83.03 49.347" />
                    <path id="Path_3337" fill="#382764"
                          d="M18.42 66.483v.031c-.826 20.02 4.368 39.018 17 64.7l.654 1.33q.336.684.68 1.376c6.723 13.615 15.7 28.963 26.514 47.063 5.41 9.057 11.427 18.984 17.931 29.758.03.037 3.907.073 3.937.109h39.34c7.169-11.89 13.724-22.7 19.522-32.4 10.728-17.96 19.457-32.9 25.909-45.985q.342-.686.677-1.365t.665-1.355c12.422-25.276 16.945-43.115 16.113-63.259a57.827 57.827 0 0 0-57.66-57.026 57.04 57.04 0 0 0-27 6.751A57.465 57.465 0 0 0 18.42 66.483" />
                    <path id="Path_3338" fill="#372663"
                          d="M188.438 67.681a58.109 58.109 0 0 0-85.545-51.206 58.108 58.108 0 0 0-85.544 51.209v.032c.011 36.784 19.451 68.148 64.142 143.13h42.79C171.233 132 188.438 104.678 188.438 67.684z" />
                    <path id="Path_3339" fill="#412d6c"
                          d="M188.438 67.681a58.108 58.108 0 0 0-85.544-51.206 58.109 58.109 0 0 0-85.545 51.209v.032c.01 36.785 19.453 68.148 64.142 143.13h42.789C171.233 132 188.438 104.678 188.438 67.684" />
                    <path id="Path_3340" fill="#834279"
                          d="M188.438 67.681a58.109 58.109 0 0 0-85.545-51.206 58.108 58.108 0 0 0-85.544 51.209v.032c.011 36.784 19.451 68.148 64.142 143.13h42.79C171.233 132 188.438 104.678 188.438 67.684z" />
                    <path id="Path_3341" fill="#824279"
                          d="M187.75 67.128a57.689 57.689 0 0 0-84.857-50.878 57.688 57.688 0 0 0-84.856 50.881v.039c.015 36.508 19.424 68.455 63.694 143.676h42.308c46.467-78.987 63.711-107.006 63.711-143.715" />
                    <path id="Path_3342" fill="#81427a"
                          d="M187.062 66.574a57.267 57.267 0 0 0-84.169-50.549 57.266 57.266 0 0 0-84.168 50.552v.047c.018 36.231 19.4 68.761 63.246 144.221H123.8C169.779 131.721 187.062 103 187.062 66.577" />
                    <path id="Path_3343" fill="#80427a"
                          d="M186.374 66.021A56.849 56.849 0 0 0 102.893 15.8a56.849 56.849 0 0 0-83.481 50.224v.055c.022 35.954 19.366 69.067 62.8 144.767h41.344c45.5-79.262 62.819-108.684 62.819-144.822" />
                    <path id="Path_3344" fill="#7f427b"
                          d="M185.687 65.468a56.43 56.43 0 0 0-82.793-49.894A56.43 56.43 0 0 0 20.1 65.471v.063c.025 35.677 19.337 69.374 62.35 145.312h40.861c45.012-79.4 62.374-109.522 62.374-145.375z" />
                    <path id="Path_3345" fill="#7e427b"
                          d="M185 64.915a56.01 56.01 0 0 0-82.1-49.566 56.01 56.01 0 0 0-82.1 49.569v.071c.028 35.4 19.308 69.68 61.9 145.857h40.38C167.6 131.31 185 100.485 185 64.918z" />
                    <path id="Path_3346" fill="#7d427b"
                          d="M184.311 64.362a55.591 55.591 0 0 0-81.417-49.238 55.591 55.591 0 0 0-81.417 49.241v.079c.032 35.123 19.279 69.986 61.453 146.4h39.9c44.042-79.673 61.482-111.2 61.482-146.481" />
                    <path id="Path_3347" fill="#7c427c"
                          d="M183.623 63.808A55.174 55.174 0 0 0 102.894 14.9a55.174 55.174 0 0 0-80.729 48.911v.089c.036 34.846 19.251 70.293 61.006 146.948h39.416c43.557-79.811 61.036-112.038 61.036-147.035" />
                    <path id="Path_3348" fill="#7b427c"
                          d="M182.935 63.256a54.756 54.756 0 0 0-80.042-48.583 54.755 54.755 0 0 0-80.041 48.586v.094c.04 34.569 19.222 70.6 60.558 147.493h38.934c43.071-79.948 60.59-112.876 60.59-147.587" />
                    <path id="Path_3349" fill="#7a417d"
                          d="M182.247 62.7a54.338 54.338 0 0 0-79.354-48.255 54.338 54.338 0 0 0-79.354 48.26v.1c.114 34.295 19.194 70.908 60.111 148.041h38.45c42.587-80.086 60.145-113.715 60.145-148.141z" />
                    <path id="Path_3350" fill="#79417d"
                          d="M181.559 62.149a53.92 53.92 0 0 0-78.666-47.927 53.92 53.92 0 0 0-78.666 47.93v.11c.116 34.014 19.163 71.212 59.661 148.584h37.97c42.1-80.223 59.7-114.553 59.7-148.694z" />
                    <path id="Path_3351" fill="#78417d"
                          d="M180.871 61.6A53.5 53.5 0 0 0 102.893 14a53.5 53.5 0 0 0-77.978 47.6v.118c.12 33.737 19.134 71.519 59.213 149.129h37.489c41.616-80.36 59.252-115.392 59.252-149.247" />
                    <path id="Path_3352" fill="#77417e"
                          d="M180.183 61.043a53.084 53.084 0 0 0-77.29-47.271A53.084 53.084 0 0 0 25.6 61.046v.126c.127 33.46 19.11 71.828 58.77 149.674h37.006c41.132-80.5 58.807-116.23 58.807-149.8" />
                    <path id="Path_3353" fill="#76417e"
                          d="M179.495 60.489a52.667 52.667 0 0 0-76.6-46.943 52.667 52.667 0 0 0-76.6 46.946v.134c.125 33.183 19.076 72.132 58.317 150.219h36.524c40.647-80.634 58.361-117.068 58.361-150.353" />
                    <path id="Path_3354" fill="#75417f"
                          d="M178.808 59.936a52.252 52.252 0 0 0-75.915-46.616 52.251 52.251 0 0 0-75.914 46.62v.141c.129 32.906 19.048 72.438 57.869 150.765h36.043c40.161-80.772 57.915-117.908 57.915-150.906z" />
                    <path id="Path_3355" fill="#74417f"
                          d="M178.12 59.383A51.835 51.835 0 0 0 102.893 13.1a51.835 51.835 0 0 0-75.226 46.287v.148c.133 32.63 19.021 72.745 57.423 151.311h35.561C160.327 129.937 178.12 92.1 178.12 59.387z" />
                    <path id="Path_3356" fill="#73417f"
                          d="M177.432 58.83a51.419 51.419 0 0 0-74.539-45.96 51.418 51.418 0 0 0-74.538 45.964v.157c.133 32.351 18.99 73.05 56.973 151.855h35.079C159.6 129.8 177.432 91.262 177.432 58.834" />
                    <path id="Path_3357"
                          d="M176.744 58.277a51 51 0 0 0-73.851-45.632 51 51 0 0 0-73.85 45.636v.164c.072 32.076 18.961 73.358 56.525 152.4h34.6c38.7-81.183 56.577-120.423 56.577-152.565"
                          fill="#724180" />
                    <path id="Path_3358"
                          d="M176.056 57.724a50.589 50.589 0 0 0-73.163-45.3 50.588 50.588 0 0 0-73.162 45.303v.173c.075 31.8 18.931 73.664 56.076 152.946h34.116c38.22-81.32 56.131-121.262 56.131-153.119"
                          fill="#724180" />
                    <path id="Path_3359" fill="#714181"
                          d="M175.368 57.171a50.173 50.173 0 0 0-72.475-44.977 50.173 50.173 0 0 0-72.475 44.981v.18c.078 31.521 18.9 73.97 55.628 153.491h33.633c37.736-81.457 55.686-122.1 55.686-153.671z" />
                    <path id="Path_3360" fill="#704181"
                          d="M174.68 56.617a49.758 49.758 0 0 0-71.787-44.649 49.758 49.758 0 0 0-71.787 44.653v.187c.082 31.245 18.873 74.277 55.18 154.038h33.154c37.251-81.6 55.24-122.939 55.24-154.225z" />
                    <path id="Path_3361" fill="#6f4181"
                          d="M173.992 56.064a49.343 49.343 0 0 0-71.1-44.321 49.343 49.343 0 0 0-71.1 44.325v.195c.085 30.968 18.844 74.584 54.732 154.583H119.2c36.766-81.733 54.794-123.778 54.794-154.778" />
                    <path id="Path_3362" fill="#6e4182"
                          d="M173.3 55.511a48.929 48.929 0 0 0-70.411-43.993 48.929 48.929 0 0 0-70.411 44v.2c.089 30.689 18.815 74.889 54.283 155.127h32.188C155.237 128.976 173.3 86.23 173.3 55.515" />
                    <path id="Path_3363" fill="#6d4182"
                          d="M172.616 54.958a48.515 48.515 0 0 0-69.723-43.665A48.515 48.515 0 0 0 33.17 54.962v.211c.092 30.413 18.786 75.2 53.836 155.673h31.7c35.8-82.007 53.9-125.454 53.9-155.884" />
                    <path id="Path_3364" fill="#6c4183"
                          d="M171.929 54.4a48.1 48.1 0 0 0-69.036-43.337 48.1 48.1 0 0 0-69.035 43.346v.219c.1 30.136 18.757 75.5 53.388 156.218h31.223c35.314-82.146 53.46-126.293 53.46-156.437z" />
                    <path id="Path_3365" fill="#6b4183"
                          d="M171.241 53.851a47.689 47.689 0 0 0-68.348-43.009 47.688 47.688 0 0 0-68.347 43.013v.227c.1 29.859 18.728 75.809 52.94 156.764h30.742c34.824-82.282 53.01-127.132 53.01-156.991z" />
                    <path id="Path_3366" fill="#6a4183"
                          d="M170.553 53.3a47.278 47.278 0 0 0-67.66-42.682A47.277 47.277 0 0 0 35.234 53.3v.235c.1 29.582 18.7 76.115 52.492 157.309h30.26C152.325 128.425 170.55 82.874 170.55 53.3" />
                    <path id="Path_3367" fill="#694084"
                          d="M169.865 52.745a46.868 46.868 0 0 0-66.972-42.354 46.867 46.867 0 0 0-66.971 42.358v.243c.107 29.3 18.67 76.422 52.044 157.854h29.777c33.855-82.556 52.119-128.809 52.119-158.1" />
                    <path id="Path_3368" fill="#684084"
                          d="M169.177 52.192a46.454 46.454 0 0 0-66.284-42.026A46.453 46.453 0 0 0 36.61 52.2v.25c.11 29.028 18.641 76.728 51.6 158.4h29.3c33.364-82.697 51.667-129.65 51.667-158.65" />
                    <path id="Path_3369" fill="#674085"
                          d="M168.489 51.639a46.043 46.043 0 0 0-65.6-41.7 46.043 46.043 0 0 0-65.6 41.7v.258c.114 28.751 18.613 77.035 51.147 158.945h28.815c32.885-82.83 51.227-130.486 51.227-159.2z" />
                    <path id="Path_3370" fill="#664085"
                          d="M167.8 51.086a45.634 45.634 0 0 0-64.907-41.371A45.634 45.634 0 0 0 37.985 51.09v.266c.118 28.474 18.584 77.341 50.7 159.49h28.335c32.4-82.967 50.78-131.324 50.78-159.756z" />
                    <path id="Path_3371" fill="#654085"
                          d="M167.113 50.532a45.22 45.22 0 0 0-64.22-41.041 45.22 45.22 0 0 0-64.22 41.045v.275c.121 28.2 18.555 77.647 50.251 160.034h27.851c31.915-83.1 50.335-132.162 50.335-160.309" />
                    <path id="Path_3372" fill="#644086"
                          d="M166.425 49.979a44.815 44.815 0 0 0-63.532-40.715 44.815 44.815 0 0 0-63.532 40.719v.282c.125 27.919 18.526 77.954 49.8 160.581h27.369c31.43-83.242 49.889-133 49.889-160.863" />
                    <path id="Path_3373" fill="#634086"
                          d="M165.737 49.426a44.406 44.406 0 0 0-62.844-40.387A44.406 44.406 0 0 0 40.049 49.43v.289c.128 27.643 18.5 78.261 49.355 161.127h26.887c30.944-83.379 49.443-133.84 49.443-161.416" />
                    <path id="Path_3374" fill="#624087"
                          d="M165.05 48.873a44 44 0 0 0-62.157-40.059 44 44 0 0 0-62.156 40.063v.3c.132 27.365 18.468 78.567 48.908 161.671h26.4c30.459-83.516 49-134.678 49-161.969z" />
                    <path id="Path_3375"
                          d="M164.362 48.32a43.593 43.593 0 0 0-61.469-39.731 43.592 43.592 0 0 0-61.468 39.736v.305c.136 27.087 18.439 78.873 48.459 162.216h25.926c29.974-83.653 48.552-135.517 48.552-162.521z"
                          fill="#614087" />
                    <path id="Path_3376" fill="#bd4d5b"
                          d="M164.361 48.32a43.592 43.592 0 0 0-61.468-39.731 43.593 43.593 0 0 0-61.469 39.736v.305c.135 27.087 18.438 78.873 48.459 162.216h25.927c29.974-83.653 48.551-135.517 48.551-162.521v-.005" />
                    <path id="Path_3377" fill="#bc4d5c"
                          d="M42.672 47.583v.3c.133 26.746 18.059 78.789 47.427 162.38.028.19 14.838.383 14.866.58h10.545c29.369-84.243 47.6-136.565 47.6-163.267A43.008 43.008 0 0 0 102.59 8.3a42.812 42.812 0 0 0-59.918 39.283" />
                    <path id="Path_3378" fill="#bb4c5c"
                          d="M43.918 46.842v.3c.131 26.4 17.679 78.7 46.394 162.544.056.379 14.3.766 14.361 1.159h10.538c28.764-84.833 46.653-137.612 46.653-164.008a42.427 42.427 0 0 0-59.579-38.825 42.037 42.037 0 0 0-58.367 38.83" />
                    <path id="Path_3379" fill="#ba4c5d"
                          d="M45.166 46.1v.3c.129 26.063 17.3 78.621 45.362 162.708.082.569 13.767 1.148 13.853 1.739h10.533c28.159-85.423 45.7-138.661 45.7-164.75A41.9 41.9 0 0 0 118.727 4.2a41.356 41.356 0 0 0-16.745 3.518A41.271 41.271 0 0 0 45.166 46.1" />
                    <path id="Path_3380" fill="#b94c5d"
                          d="M46.413 45.359v.3c.127 25.721 16.918 78.537 44.329 162.871.11.759 13.232 1.532 13.346 2.319h10.528c27.553-86.012 44.755-139.709 44.755-165.491a41.335 41.335 0 0 0-41.333-41.335 40.676 40.676 0 0 0-16.359 3.412 40.51 40.51 0 0 0-55.266 37.924" />
                    <path id="Path_3381" fill="#b84b5e"
                          d="M47.66 44.617v.295c.124 25.379 16.538 78.453 43.3 163.035.138.948 12.7 1.914 12.84 2.9h10.522c26.948-86.6 43.806-140.758 43.806-166.233A40.778 40.778 0 0 0 117.35 3.839a40.01 40.01 0 0 0-15.975 3.306A39.761 39.761 0 0 0 47.66 44.617" />
                    <path id="Path_3382" fill="#b74b5e"
                          d="M48.906 43.876v.293c.122 25.038 16.158 78.369 42.264 163.2.166 1.137 12.161 2.3 12.332 3.478h10.517c26.343-87.192 42.858-141.8 42.858-166.973a40.219 40.219 0 0 0-40.216-40.217 39.327 39.327 0 0 0-15.59 3.2 39.018 39.018 0 0 0-52.165 37.019" />
                    <path id="Path_3383" fill="#b64b5f"
                          d="M50.153 43.134v.291c.12 24.7 15.778 78.284 41.231 163.363.193 1.327 11.625 2.679 11.826 4.057h10.511c25.739-87.781 41.908-142.853 41.908-167.713a39.659 39.659 0 0 0-39.66-39.657 38.656 38.656 0 0 0-15.205 3.094 38.092 38.092 0 0 0-15.035-1.944 38.495 38.495 0 0 0-35.576 38.509" />
                    <path id="Path_3384" fill="#b54a60"
                          d="M51.4 42.393v.29c.118 24.353 15.4 78.2 40.2 163.525.22 1.517 11.088 3.063 11.318 4.638h10.505c25.133-88.371 40.96-143.9 40.959-168.456a39.1 39.1 0 0 0-39.1-39.1 38 38 0 0 0-14.818 2.99 37.413 37.413 0 0 0-14.631-1.674A37.8 37.8 0 0 0 51.4 42.393" />
                    <path id="Path_3385" fill="#b44a60"
                          d="M52.647 41.652v.288c.116 24.011 15.018 78.115 39.166 163.689.248 1.707 10.554 3.446 10.811 5.217h10.5c24.528-88.961 40.011-144.949 40.01-169.2A38.541 38.541 0 0 0 114.6 3.109a37.343 37.343 0 0 0-14.44 2.883 36.685 36.685 0 0 0-14.222-1.4 37.11 37.11 0 0 0-33.291 37.06" />
                    <path id="Path_3386" fill="#b34a61"
                          d="M53.9 40.91v.286c.114 23.67 14.638 78.031 38.134 163.853.276 1.9 10.018 3.828 10.3 5.8h10.5c23.922-89.551 39.061-146 39.06-169.938a37.984 37.984 0 0 0-37.986-37.985A36.708 36.708 0 0 0 99.857 5.7a36.011 36.011 0 0 0-13.813-1.131A36.434 36.434 0 0 0 53.9 40.91" />
                    <path id="Path_3387" fill="#b24961"
                          d="M55.142 40.169v.284C55.256 63.78 69.4 118.4 92.246 204.469c.3 2.086 9.482 4.212 9.8 6.377h10.491c23.317-90.141 38.111-147.045 38.111-170.68A37.426 37.426 0 0 0 113.22 2.743a36.053 36.053 0 0 0-13.666 2.672 35.342 35.342 0 0 0-13.4-.864 35.766 35.766 0 0 0-31.012 35.618" />
                    <path id="Path_3388" fill="#b14962"
                          d="M56.389 39.427v.282C56.5 62.695 70.268 117.572 92.46 203.89c.331 2.275 8.947 4.594 9.29 6.956h10.486C134.948 120.115 149.4 62.753 149.4 39.425A36.867 36.867 0 0 0 112.531 2.56 35.434 35.434 0 0 0 99.25 5.126a34.731 34.731 0 0 0-13-.594 35.112 35.112 0 0 0-29.861 34.895" />
                    <path id="Path_3389" fill="#b14963"
                          d="M57.635 38.686v.28c.108 22.644 13.5 77.779 35.037 164.344.358 2.465 8.411 4.978 8.783 7.536h10.48c22.107-91.32 36.214-149.141 36.214-172.162a36.309 36.309 0 0 0-36.307-36.307 34.825 34.825 0 0 0-12.9 2.46 34.159 34.159 0 0 0-41.307 33.849" />
                    <path id="Path_3390" fill="#b04863"
                          d="M58.882 37.945v.278c.1 22.3 13.118 77.695 34 164.507.386 2.655 7.875 5.361 8.276 8.116h10.475c21.5-91.91 35.265-150.189 35.264-172.9a35.749 35.749 0 0 0-35.75-35.748 34.212 34.212 0 0 0-12.505 2.351 33.527 33.527 0 0 0-39.76 33.4" />
                    <path id="Path_3391" fill="#af4864"
                          d="M60.13 37.2v.276c.1 21.96 12.736 77.611 32.971 164.672.414 2.844 7.339 5.743 7.768 8.695h10.47c20.9-92.5 34.317-151.237 34.317-173.645a35.193 35.193 0 0 0-35.191-35.186 33.6 33.6 0 0 0-12.126 2.249A32.918 32.918 0 0 0 60.13 37.2" />
                    <path id="Path_3392" fill="#ae4764"
                          d="M61.376 36.462v.274c.1 21.619 12.357 77.526 31.939 164.835.441 3.034 6.8 6.126 7.262 9.275h10.464c20.293-93.09 33.369-152.285 33.369-174.386a34.634 34.634 0 0 0-34.633-34.631 33.033 33.033 0 0 0-11.742 2.143 32.341 32.341 0 0 0-36.659 32.49" />
                    <path id="Path_3393" fill="#ad4765"
                          d="M62.624 35.72v.272c.1 21.277 11.978 77.442 30.907 165 .469 3.224 6.268 6.509 6.755 9.855h10.459c19.687-93.68 32.418-153.334 32.418-175.127a34.075 34.075 0 0 0-34.074-34.074 32.488 32.488 0 0 0-11.357 2.037A31.79 31.79 0 0 0 62.624 35.72" />
                    <path id="Path_3394" fill="#ac4765"
                          d="M63.871 34.979v.271c.1 20.934 11.6 77.357 29.874 165.162.5 3.413 5.733 6.891 6.248 10.434h10.454c19.081-94.269 31.468-154.381 31.468-175.868A33.515 33.515 0 0 0 108.4 1.463a31.937 31.937 0 0 0-10.972 1.932 31.272 31.272 0 0 0-33.557 31.584" />
                    <path id="Path_3395" fill="#ab4666"
                          d="M65.118 34.237v.269c.095 20.592 11.217 77.273 28.842 165.325.524 3.6 5.2 7.275 5.74 11.015h10.45c18.476-94.859 30.519-155.43 30.519-176.61A32.957 32.957 0 0 0 107.712 1.28a31.437 31.437 0 0 0-10.588 1.826 30.791 30.791 0 0 0-32.006 31.131" />
                    <path id="Path_3396" fill="#aa4667"
                          d="M66.365 33.5v.267c.092 20.251 10.837 77.189 27.809 165.489.551 3.792 4.662 7.658 5.234 11.594h10.443c17.872-95.45 29.572-156.481 29.572-177.355a32.4 32.4 0 0 0-32.4-32.4 30.927 30.927 0 0 0-10.2 1.72A30.354 30.354 0 0 0 66.365 33.5" />
                    <path id="Path_3397" fill="#a94667"
                          d="M67.611 32.755v.265c.09 19.908 10.457 77.1 26.777 165.652.579 3.983 4.126 8.041 4.726 12.174h10.439c17.265-96.038 28.621-157.526 28.621-178.092A31.841 31.841 0 0 0 106.335.915a30.5 30.5 0 0 0-9.819 1.614 29.97 29.97 0 0 0-28.9 30.226" />
                    <path id="Path_3398" fill="#a84568"
                          d="M68.858 32.013v.263C68.947 51.843 78.936 109.3 94.6 198.093c.607 4.171 3.59 8.423 4.22 12.753h10.433c16.66-96.628 27.672-158.574 27.672-178.833A31.282 31.282 0 0 0 105.647.732a30.064 30.064 0 0 0-9.434 1.509 29.634 29.634 0 0 0-27.355 29.772" />
                    <path id="Path_3399" fill="#a74568"
                          d="M70.105 31.272v.261c.086 19.225 9.7 76.936 24.711 165.98.635 4.362 3.055 8.806 3.713 13.333h10.428c16.055-97.218 26.723-159.621 26.723-179.574A30.723 30.723 0 0 0 104.958.549a29.7 29.7 0 0 0-9.048 1.4 29.372 29.372 0 0 0-25.805 29.32" />
                    <path id="Path_3400" fill="#a64569"
                          d="M71.353 30.53v.259c.083 18.884 9.316 76.852 23.679 166.144.661 4.551 2.519 9.189 3.205 13.913h10.423c15.45-97.808 25.774-160.67 25.774-180.316A30.164 30.164 0 0 0 104.27.366a29.434 29.434 0 0 0-8.664 1.3A29.179 29.179 0 0 0 71.353 30.53" />
                    <path id="Path_3401" fill="#a54469"
                          d="M72.6 29.789v.257c.082 18.541 8.937 76.768 22.647 166.308.689 4.74 1.983 9.571 2.7 14.492h10.417c14.845-98.4 24.825-161.718 24.825-181.057A29.606 29.606 0 0 0 103.582.184 29.177 29.177 0 0 0 95.3 1.375a29.067 29.067 0 0 0-22.7 28.414" />
                    <path id="Path_3402" fill="#a4446a"
                          d="M131.941 29.047a29.047 29.047 0 1 0-58.094 0c0 18.882 9.449 83.043 23.806 181.8h10.412c14.24-98.987 23.876-162.766 23.876-181.8" />
                </g>
            </g>
            <g id="Group_4608">
                <g id="Group_4607" clipPath="url(#clipPath-5)">
                    <path id="Rectangle_3046" fill="url(#linear-gradient-3)" d="M0 0H58.094V210.845H0z"
                          transform="translate(73.847 .001)" />
                </g>
            </g>
            <g id="Group_4610">
                <g id="Group_4609" clipPath="url(#clipPath-6)">
                    <path id="Rectangle_3047" fill="url(#linear-gradient-4)" d="M0 0H58.094V210.845H0z"
                          transform="translate(73.846 .001)" />
                </g>
            </g>
            <g id="Group_4612">
                <g id="Group_4611" clipPath="url(#clipPath)">
                    <path id="Path_3405"
                          d="M205.747 90.352q.014-.442.022-.888H.018c.006.3.013.6.022.894Q0 91.542 0 92.735c0 25.282 16.019 48.516 30.817 67.012h144.152c14.8-18.5 30.818-41.73 30.818-67.012 0-.8-.015-1.591-.04-2.383"
                          fill="#01426a" />
                    <path id="Path_3406" fill="#014269"
                          d="M.5 89.464c.008.3.016.6.026.892Q.493 91.539.5 92.73c.114 25.123 15.987 48.284 30.818 67.016H174.47c14.846-18.755 30.713-41.9 30.821-67.015q.008-1.2-.025-2.38c.01-.3.018-.591.026-.887z" />
                    <path id="Path_3407" fill="#014168"
                          d="M.979 89.464q.014.446.03.89Q.985 91.536 1 92.725c.227 24.964 15.955 48.053 30.82 67.021h142.151c14.9-19.014 30.61-42.072 30.827-67.019q.014-1.194-.012-2.378c.011-.294.021-.59.029-.885z" />
                    <path id="Path_3408" fill="#014167"
                          d="M1.459 89.464c.01.3.021.593.034.888q-.017 1.181 0 2.369c.341 24.805 15.922 47.82 30.821 67.025h141.157c14.944-19.274 30.507-42.243 30.832-67.023q.02-1.192 0-2.375.02-.441.034-.884z" />
                    <path id="Path_3409" fill="#014066"
                          d="M1.94 89.464c.011.3.023.591.038.887q-.011 1.178.022 2.365c.454 24.645 15.89 47.588 30.822 67.03h140.15c14.993-19.533 30.343-42.415 30.837-67.027q.024-1.191.015-2.372.021-.441.037-.883z" />
                    <path id="Path_3410" fill="#014065"
                          d="M2.421 89.464c.012.3.025.59.041.885 0 .784.008 1.572.031 2.362.568 24.487 15.858 47.357 30.823 67.035h139.156c15.042-19.793 30.227-42.587 30.842-67.031.02-.793.031-1.582.028-2.369.016-.294.029-.588.042-.882z" />
                    <path id="Path_3411" fill="#014064"
                          d="M2.9 89.464c.014.294.029.589.046.883q0 1.176.045 2.36c.681 24.327 15.825 47.124 30.825 67.039h138.157c15.091-20.053 30.112-42.76 30.847-67.035.024-.791.04-1.58.042-2.366q.025-.439.046-.881z" />
                    <path id="Path_3412" fill="#023f63"
                          d="M3.382 89.464q.023.441.049.881c.009.783.027 1.568.059 2.357.795 24.168 15.793 46.892 30.826 67.044h137.158c15.14-20.312 30-42.931 30.852-67.039.028-.791.049-1.578.056-2.363.018-.293.034-.587.049-.88z" />
                    <path id="Path_3413" fill="#023f62"
                          d="M3.862 89.464c.017.293.034.586.054.879q.018 1.173.072 2.354c.909 24.01 15.761 46.661 30.828 67.049h136.158c15.189-20.572 29.883-43.1 30.857-67.043.032-.79.058-1.576.07-2.36.019-.293.036-.586.053-.879z" />
                    <path id="Path_3414" fill="#023e61"
                          d="M4.343 89.464q.027.44.058.877.026 1.171.087 2.352c1.022 23.85 15.727 46.428 30.828 67.053h135.159c15.238-20.831 29.77-43.276 30.862-67.047.036-.789.067-1.574.084-2.358q.03-.438.056-.877z" />
                    <path id="Path_3415" fill="#023e60"
                          d="M4.823 89.464c.02.292.04.584.062.875q.033 1.17.1 2.349c1.135 23.691 15.695 46.2 30.829 67.058h134.162c15.286-21.091 29.657-43.449 30.866-67.051.041-.788.076-1.572.1-2.355q.033-.438.061-.876z" />
                    <path id="Path_3416" fill="#023e5f"
                          d="M5.3 89.464c.021.292.042.583.065.874q.04 1.168.115 2.345c1.25 23.532 15.663 45.965 30.831 67.063h133.165c15.336-21.351 29.546-43.621 30.872-67.054.044-.788.085-1.571.111-2.353q.034-.437.064-.875z" />
                    <path id="Path_3417" fill="#023d5e"
                          d="M5.785 89.464c.022.291.044.582.069.872q.048 1.167.129 2.342c1.363 23.373 15.631 45.733 30.832 67.068h132.163c15.384-21.61 29.434-43.793 30.876-67.058.049-.786.094-1.569.124-2.349.025-.292.047-.583.069-.875z" />
                    <path id="Path_3418" fill="#023d5d"
                          d="M6.265 89.464c.023.29.047.581.073.87q.056 1.166.143 2.339c1.477 23.215 15.6 45.5 30.834 67.073h131.163c15.433-21.87 29.324-43.966 30.881-67.062.053-.785.1-1.567.138-2.347q.039-.435.073-.873z" />
                    <path id="Path_3419" fill="#023d5c"
                          d="M6.746 89.464c.024.29.049.579.077.868q.061 1.166.157 2.337c1.59 23.055 15.565 45.269 30.834 67.077h130.165c15.482-22.13 29.215-44.138 30.886-67.066.057-.785.112-1.565.152-2.344q.041-.435.076-.872z" />
                    <path id="Path_3420" fill="#023c5b"
                          d="M7.227 89.464c.026.289.052.578.081.867q.069 1.163.171 2.333c1.7 22.9 15.533 45.037 30.836 67.082H167.48c15.531-22.389 29.107-44.31 30.891-67.07.062-.784.122-1.563.166-2.341.028-.29.054-.58.08-.871z" />
                    <path id="Path_3421" fill="#023c5a"
                          d="M7.707 89.464q.041.434.085.865.077 1.161.185 2.33c1.817 22.737 15.5 44.805 30.837 67.087H166.98c15.581-22.649 29-44.483 30.9-67.074.066-.783.131-1.562.18-2.338l.084-.87z" />
                    <path id="Path_3422" fill="#023b59"
                          d="M8.188 89.464c.029.288.058.575.089.863q.084 1.159.2 2.327c1.931 22.579 15.468 44.574 30.838 67.092h127.166c15.629-22.908 28.893-44.656 30.9-67.078.07-.782.14-1.559.193-2.335q.047-.434.088-.869z" />
                    <path id="Path_3423" fill="#023b58"
                          d="M8.668 89.464c.03.287.06.574.092.861q.091 1.158.214 2.325c2.044 22.419 15.436 44.341 30.84 67.1h126.167c15.678-23.168 28.787-44.828 30.906-67.082.074-.781.149-1.557.207-2.332.032-.289.062-.578.092-.868z" />
                    <path id="Path_3424" fill="#023b57"
                          d="M9.149 89.464q.047.431.1.859.1 1.157.227 2.322c2.157 22.26 15.4 44.11 30.841 67.1h125.165c15.728-23.428 28.682-45 30.911-67.086.079-.779.158-1.555.22-2.329.034-.289.065-.578.1-.867z" />
                    <path id="Path_3425" fill="#023a56"
                          d="M9.63 89.464c.033.286.064.572.1.857q.1 1.157.241 2.319c2.271 22.1 15.371 43.878 30.841 67.106h124.17c15.775-23.687 28.577-45.173 30.916-67.09.083-.779.166-1.553.233-2.327q.053-.432.1-.865z" />
                    <path id="Path_3426" fill="#033a56"
                          d="M10.11 89.464q.049.428.1.855.112 1.155.255 2.316c2.386 21.941 15.339 43.645 30.844 67.11h123.175C180.308 135.8 192.957 114.4 195.4 92.652c.087-.778.176-1.551.248-2.324q.054-.432.1-.864z" />
                    <path id="Path_3427" fill="#033955"
                          d="M10.591 89.464c.035.285.071.569.108.854q.118 1.152.269 2.313c2.5 21.783 15.307 43.414 30.845 67.115h122.172c15.873-24.206 28.371-45.517 30.925-67.1.092-.777.185-1.549.262-2.321q.056-.431.106-.863z" />
                    <path id="Path_3428" fill="#033954"
                          d="M11.071 89.464q.054.426.112.852.126 1.152.284 2.31c2.612 21.624 15.273 43.182 30.846 67.12h121.172c15.922-24.466 28.269-45.689 30.93-67.1.1-.776.194-1.548.276-2.318.038-.287.074-.574.11-.862z" />
                    <path id="Path_3429" fill="#033953"
                          d="M11.552 89.464c.038.283.076.567.116.85q.133 1.15.3 2.307c2.725 21.464 15.241 42.949 30.847 67.124h120.171c15.972-24.726 28.168-45.861 30.936-67.106.1-.775.2-1.546.288-2.315q.06-.43.115-.861z" />
                    <path id="Path_3430" fill="#033852"
                          d="M12.033 89.464c.039.283.079.565.12.848q.14 1.149.311 2.305c2.84 21.305 15.209 42.718 30.849 67.129h119.174c16.02-24.986 28.068-46.033 30.94-67.11.106-.774.212-1.544.3-2.312.041-.287.08-.573.118-.86z" />
                    <path id="Path_3431" fill="#033851"
                          d="M12.513 89.464q.06.423.124.846.147 1.147.325 2.3c2.953 21.146 15.176 42.486 30.85 67.134h118.175c16.069-25.245 27.969-46.205 30.945-67.114.11-.772.221-1.542.316-2.309.043-.286.083-.573.123-.859z" />
                    <path id="Path_3432" fill="#033750"
                          d="M12.994 89.464c.042.282.084.563.128.845q.155 1.145.339 2.3c3.067 20.991 15.139 42.252 30.851 67.137h117.176c16.119-25.505 27.87-46.376 30.95-67.117.115-.772.23-1.54.33-2.307q.066-.429.127-.858z" />
                    <path id="Path_3433" fill="#03374f"
                          d="M13.475 89.464q.063.422.131.843.162 1.143.354 2.3c3.18 20.828 15.111 42.022 30.852 67.143h116.177c16.167-25.764 27.773-46.548 30.955-67.121.119-.772.239-1.538.343-2.305q.067-.427.131-.856z" />
                    <path id="Path_3434" fill="#03374e"
                          d="M13.955 89.464c.044.28.089.561.135.841q.169 1.143.368 2.293c3.293 20.669 15.079 41.79 30.853 67.148h115.178c16.216-26.024 27.676-46.719 30.96-67.125.124-.771.248-1.536.358-2.3l.134-.855z" />
                    <path id="Path_3435" fill="#03364d"
                          d="M14.436 89.464q.068.42.14.839.175 1.142.381 2.29C18.364 113.1 30 134.152 45.812 159.746h114.179c16.265-26.283 27.579-46.89 30.965-67.129.128-.77.256-1.534.37-2.3q.07-.426.138-.854z" />
                    <path id="Path_3436" fill="#03364c"
                          d="M14.916 89.464c.047.279.094.558.144.837q.183 1.14.395 2.288c3.521 20.351 15.014 41.326 30.856 67.157h113.18c16.309-26.546 27.484-47.061 30.969-67.134.134-.768.267-1.532.385-2.295q.073-.426.142-.853z" />
                    <path id="Path_3437" fill="#03364b"
                          d="M15.4 89.464c.048.278.1.557.147.835q.19 1.14.41 2.285c3.634 20.192 14.982 41.094 30.857 67.162h112.18c16.364-26.8 27.39-47.231 30.975-67.137.139-.768.275-1.531.4-2.293q.075-.426.145-.852z" />
                    <path id="Path_3438" fill="#03354a"
                          d="M15.877 89.464c.049.278.1.556.151.833q.2 1.139.424 2.282C20.2 112.612 31.4 133.442 47.31 159.746h111.182c16.411-27.062 27.3-47.4 30.98-67.141.142-.766.284-1.529.411-2.29.052-.284.1-.567.15-.851z" />
                    <path id="Path_3439" fill="#033549"
                          d="M16.358 89.464c.051.277.1.554.155.832q.205 1.136.438 2.278c3.861 19.874 14.917 40.631 30.86 67.172h110.182c16.461-27.322 27.2-47.572 30.985-67.145.148-.765.293-1.527.425-2.287q.079-.426.154-.85z" />
                    <path id="Path_3440" fill="#043448"
                          d="M16.839 89.464c.052.276.1.553.159.83q.212 1.134.451 2.275c3.975 19.715 14.885 40.4 30.861 67.177h109.184C174 132.164 184.6 112 188.484 92.6c.152-.764.3-1.525.438-2.284q.081-.425.158-.849z" />
                    <path id="Path_3441" fill="#043447"
                          d="M17.319 89.464q.079.414.163.828.219 1.132.465 2.273c4.089 19.556 14.852 40.167 30.863 67.181h108.184c16.558-27.841 27.02-47.911 30.994-67.153.158-.763.312-1.523.454-2.281l.161-.848z" />
                    <path id="Path_3442" fill="#043446"
                          d="M17.8 89.464c.055.275.11.551.167.826q.227 1.132.479 2.27c4.2 19.4 14.82 39.935 30.864 67.186h107.185c16.608-28.1 26.93-48.08 31-67.157.162-.762.32-1.521.466-2.278.057-.283.111-.565.165-.847z" />
                    <path id="Path_3443" fill="#043345"
                          d="M18.28 89.464l.171.825q.234 1.129.494 2.266c4.315 19.238 14.787 39.7 30.864 67.191h106.186c16.657-28.361 26.84-48.246 31.005-67.161.167-.761.329-1.519.48-2.275.058-.283.114-.564.169-.846z" />
                    <path id="Path_3444" fill="#043344"
                          d="M18.761 89.464l.174.823q.242 1.128.508 2.263c4.429 19.08 14.755 39.472 30.866 67.2H155.5c16.705-28.62 26.782-48.41 31.01-67.165q.26-1.14.493-2.273c.059-.281.117-.563.173-.844z" />
                    <path id="Path_3445" fill="#043243"
                          d="M19.242 89.464c.058.273.118.547.178.821q.249 1.126.522 2.261c4.543 18.919 14.722 39.239 30.867 67.2H155c16.755-28.88 26.679-48.581 31.015-67.169q.267-1.139.507-2.27.09-.421.177-.843z" />
                    <path id="Path_3446" fill="#043242"
                          d="M19.722 89.464C24.014 109.1 34.242 130 51.309 159.746H154.5c17.752-30.784 27.656-50.936 31.722-70.282z" />
                    <path id="Path_3447" fill="#39737a"
                          d="M19.645 89.464C23.937 109.1 34.165 130 51.231 159.746H154.42c17.752-30.784 27.656-50.936 31.722-70.282z" />
                    <path id="Path_3448" fill="#38727a"
                          d="M20.647 89.464c4.322 19.61 14.465 40.59 31.285 70.282h101.781c17.482-30.7 27.313-50.955 31.418-70.282z" />
                    <path id="Path_3449" fill="#377179"
                          d="M21.649 89.464c4.352 19.582 14.41 40.643 30.984 70.282h100.374c17.211-30.606 26.97-50.973 31.114-70.282z" />
                    <path id="Path_3450" fill="#367179"
                          d="M22.652 89.464c4.382 19.553 14.355 40.7 30.682 70.282H152.3c16.941-30.517 26.627-50.992 30.81-70.282z" />
                    <path id="Path_3451" fill="#357079"
                          d="M23.654 89.464c4.413 19.524 14.3 40.749 30.38 70.282h97.56c16.671-30.428 26.284-51.011 30.507-70.282z" />
                    <path id="Path_3452" fill="#346f79"
                          d="M24.657 89.464c4.443 19.5 14.245 40.8 30.079 70.282h96.152c16.4-30.339 25.941-51.03 30.2-70.282z" />
                    <path id="Path_3453" fill="#336e78"
                          d="M25.659 89.464c4.473 19.466 14.19 40.855 29.778 70.282h94.745c16.13-30.25 25.6-51.049 29.9-70.282z" />
                    <path id="Path_3454" fill="#326d78"
                          d="M26.661 89.464c4.5 19.437 14.135 40.909 29.476 70.282h93.338c15.86-30.161 25.255-51.067 29.6-70.282z" />
                    <path id="Path_3455" fill="#316c78"
                          d="M27.664 89.464c4.534 19.408 14.08 40.962 29.174 70.282h91.931c15.59-30.072 24.913-51.086 29.293-70.282z" />
                    <path id="Path_3456" fill="#306c78"
                          d="M28.666 89.464c4.563 19.379 14.025 41.015 28.873 70.282h90.524c15.318-29.983 24.568-51.105 28.988-70.282z" />
                    <path id="Path_3457" fill="#2f6b77"
                          d="M29.668 89.464c4.594 19.351 13.97 41.068 28.571 70.282h89.117c15.049-29.894 24.226-51.123 28.686-70.282z" />
                    <path id="Path_3458" fill="#2e6a77"
                          d="M30.67 89.464c4.625 19.322 13.916 41.122 28.27 70.282h87.709c14.779-29.8 23.883-51.142 28.382-70.282z" />
                    <path id="Path_3459" fill="#2d6977"
                          d="M31.673 89.464c4.655 19.293 13.86 41.174 27.968 70.282h86.3c14.507-29.716 23.54-51.161 28.078-70.282z" />
                    <path id="Path_3460" fill="#2c6876"
                          d="M32.675 89.464c4.685 19.264 13.805 41.228 27.667 70.282h84.9c14.237-29.627 23.2-51.179 27.774-70.282z" />
                    <path id="Path_3461"
                          d="M33.677 89.464c4.715 19.235 13.75 41.281 27.365 70.282h83.489c13.967-29.537 22.854-51.2 27.47-70.282z"
                          fill="#2b6776" />
                    <path id="Path_3462"
                          d="M34.68 89.464c4.746 19.207 13.695 41.334 27.064 70.282h82.081c13.7-29.449 22.511-51.217 27.167-70.282z"
                          fill="#2b6776" />
                    <path id="Path_3463" fill="#2a6676"
                          d="M35.682 89.464c4.776 19.178 13.641 41.387 26.762 70.282h80.674c13.426-29.36 22.168-51.236 26.863-70.282z" />
                    <path id="Path_3464" fill="#296575"
                          d="M36.685 89.464c4.806 19.148 13.585 41.44 26.46 70.282h79.267c13.156-29.27 21.825-51.255 26.56-70.282z" />
                    <path id="Path_3465" fill="#286475"
                          d="M37.687 89.464c4.836 19.12 13.53 41.494 26.159 70.282h77.86c12.885-29.181 21.481-51.273 26.255-70.282z" />
                    <path id="Path_3466" fill="#276375"
                          d="M38.689 89.464c4.867 19.091 13.476 41.547 25.858 70.282H141c12.615-29.093 21.139-51.292 25.952-70.282z" />
                    <path id="Path_3467" fill="#266274"
                          d="M39.692 89.464c4.9 19.062 13.419 41.6 25.555 70.282h75.045c12.345-29 20.8-51.311 25.649-70.282z" />
                    <path id="Path_3468" fill="#256274"
                          d="M40.694 89.464c4.927 19.033 13.366 41.653 25.254 70.282h73.639c12.074-28.914 20.452-51.329 25.344-70.282z" />
                    <path id="Path_3469" fill="#246174"
                          d="M41.7 89.464c4.957 19 13.311 41.706 24.953 70.282h72.227c11.8-28.825 20.109-51.348 25.041-70.282z" />
                    <path id="Path_3470" fill="#236074"
                          d="M42.7 89.464c4.988 18.976 13.256 41.759 24.651 70.282h70.824c11.534-28.736 19.767-51.367 24.738-70.282z" />
                    <path id="Path_3471" fill="#225f73"
                          d="M43.7 89.464c5.018 18.947 13.2 41.813 24.35 70.282h69.417c11.264-28.646 19.424-51.386 24.433-70.282z" />
                    <path id="Path_3472" fill="#215e73"
                          d="M44.7 89.464c5.048 18.918 13.146 41.866 24.048 70.282h68.01c10.993-28.558 19.08-51.4 24.13-70.282z" />
                    <path id="Path_3473" fill="#205d73"
                          d="M45.706 89.464c5.078 18.889 13.091 41.919 23.747 70.282h66.6c10.723-28.469 18.737-51.423 23.826-70.282z" />
                    <path id="Path_3474" fill="#1f5d73"
                          d="M46.708 89.464c5.109 18.86 13.036 41.972 23.445 70.282h65.2c10.452-28.38 18.394-51.442 23.523-70.282z" />
                    <path id="Path_3475" fill="#1e5c72"
                          d="M47.71 89.464c5.139 18.832 12.981 42.025 23.144 70.282h63.788c10.182-28.291 18.051-51.461 23.219-70.282z" />
                    <path id="Path_3476"
                          d="M48.713 89.464c5.169 18.8 12.926 42.079 22.841 70.282h62.382c9.912-28.2 17.707-51.479 22.916-70.282z"
                          fill="#1d5b72" />
                    <path id="Path_3477" fill="#72a58b"
                          d="M48.79 89.464c5.169 18.8 12.926 42.079 22.842 70.282h62.381c9.911-28.2 17.708-51.479 22.915-70.282z" />
                    <path id="Path_3478" fill="#71a48b"
                          d="M49.876 89.464c5.082 18.826 12.687 42.111 22.4 70.282h61.1c9.706-28.17 17.351-51.456 22.47-70.282z" />
                    <path id="Path_3479" fill="#70a38a"
                          d="M50.963 89.464c4.994 18.85 12.447 42.145 21.951 70.282h59.817c9.5-28.138 16.992-51.433 22.024-70.282z" />
                    <path id="Path_3480" fill="#6fa28a"
                          d="M52.049 89.464c4.907 18.874 12.209 42.178 21.507 70.282h58.535c9.294-28.106 16.634-51.41 21.577-70.282z" />
                    <path id="Path_3481" fill="#6ea28a"
                          d="M53.135 89.464c4.819 18.9 11.97 42.21 21.062 70.282h57.253c9.088-28.074 16.276-51.386 21.131-70.282z" />
                    <path id="Path_3482" fill="#6da18a"
                          d="M54.222 89.464c4.731 18.922 11.73 42.244 20.617 70.282h55.972c8.882-28.043 15.916-51.364 20.683-70.282z" />
                    <path id="Path_3483" fill="#6ca089"
                          d="M55.308 89.464c4.644 18.946 11.492 42.277 20.172 70.282h54.69c8.676-28.011 15.558-51.34 20.237-70.282z" />
                    <path id="Path_3484" fill="#6b9f89"
                          d="M56.4 89.464c4.557 18.97 11.252 42.31 19.727 70.282h53.403c8.47-27.979 15.2-51.317 19.791-70.282z" />
                    <path id="Path_3485" fill="#6a9e89"
                          d="M57.481 89.464c4.469 18.994 11.013 42.343 19.282 70.282h52.126c8.264-27.947 14.842-51.294 19.345-70.282z" />
                    <path id="Path_3486" fill="#699d89"
                          d="M58.567 89.464c4.382 19.017 10.774 42.376 18.837 70.282h50.845c8.058-27.915 14.483-51.271 18.9-70.282z" />
                    <path id="Path_3487" fill="#689c88"
                          d="M59.653 89.464c4.294 19.041 10.536 42.409 18.392 70.282h49.563c7.852-27.883 14.125-51.247 18.452-70.282z" />
                    <path id="Path_3488" fill="#679c88"
                          d="M60.74 89.464c4.207 19.065 10.3 42.442 17.947 70.282h48.281c7.646-27.851 13.767-51.224 18.005-70.282z" />
                    <path id="Path_3489" fill="#669b88"
                          d="M61.826 89.464c4.119 19.089 10.057 42.475 17.5 70.282h47c7.44-27.819 13.408-51.2 17.559-70.282z" />
                    <path id="Path_3490" fill="#659a87"
                          d="M62.913 89.464c4.032 19.113 9.818 42.508 17.057 70.282h45.717c7.234-27.787 13.05-51.178 17.113-70.282z" />
                    <path id="Path_3491" fill="#649987"
                          d="M64 89.464c3.944 19.137 9.579 42.541 16.611 70.282h44.436c7.028-27.755 12.692-51.155 16.667-70.282z" />
                    <path id="Path_3492" fill="#649887"
                          d="M65.086 89.464c3.856 19.161 9.339 42.574 16.167 70.282h43.153c6.823-27.723 12.332-51.131 16.22-70.282z" />
                    <path id="Path_3493" fill="#639787"
                          d="M66.172 89.464c3.769 19.185 9.1 42.607 15.722 70.282h41.872c6.616-27.692 11.974-51.108 15.773-70.282z" />
                    <path id="Path_3494" fill="#629686"
                          d="M67.258 89.464c3.682 19.208 8.862 42.64 15.276 70.282h40.591c6.41-27.659 11.616-51.085 15.327-70.282z" />
                    <path id="Path_3495" fill="#619586"
                          d="M68.344 89.464c3.6 19.232 8.623 42.673 14.832 70.282h39.308c6.2-27.628 11.258-51.062 14.881-70.282z" />
                    <path id="Path_3496" fill="#609586"
                          d="M69.431 89.464c3.507 19.256 8.384 42.706 14.386 70.282h38.027c6-27.6 10.9-51.038 14.435-70.282z" />
                    <path id="Path_3497" fill="#5f9485"
                          d="M70.517 89.464c3.419 19.28 8.145 42.739 13.941 70.282H121.2c5.792-27.564 10.541-51.015 13.987-70.282z" />
                    <path id="Path_3498" fill="#5e9385"
                          d="M71.6 89.464c3.332 19.3 7.905 42.772 13.5 70.282h35.463c5.587-27.532 10.184-50.992 13.542-70.282z" />
                    <path id="Path_3499" fill="#5d9285"
                          d="M72.69 89.464c3.245 19.328 7.667 42.805 13.051 70.282h34.181c5.381-27.5 9.825-50.969 13.1-70.282z" />
                    <path id="Path_3500" fill="#5c9185"
                          d="M73.776 89.464c3.157 19.352 7.428 42.838 12.606 70.282h32.9c5.175-27.468 9.467-50.946 12.649-70.282z" />
                    <path id="Path_3501" fill="#5b9084"
                          d="M74.862 89.464c3.07 19.375 7.188 42.871 12.161 70.282h31.618c4.969-27.436 9.108-50.923 12.2-70.282z" />
                    <path id="Path_3502" fill="#5a8f84"
                          d="M75.949 89.464c2.982 19.4 6.95 42.9 11.716 70.282H118c4.763-27.4 8.75-50.9 11.757-70.282z" />
                    <path id="Path_3503" fill="#598f84"
                          d="M77.035 89.464c2.895 19.423 6.71 42.938 11.271 70.282h29.055c4.556-27.372 8.391-50.876 11.309-70.282z" />
                    <path id="Path_3504" fill="#588e84"
                          d="M78.122 89.464c2.807 19.447 6.471 42.97 10.826 70.282h27.773c4.35-27.34 8.032-50.853 10.863-70.282z" />
                    <path id="Path_3505" fill="#578d83"
                          d="M79.208 89.464c2.72 19.471 6.232 43 10.381 70.282h26.491c4.144-27.308 7.674-50.829 10.417-70.282z" />
                    <path id="Path_3506" fill="#568c83"
                          d="M80.295 89.464c2.631 19.5 5.992 43.037 9.935 70.282h25.209c3.939-27.277 7.317-50.806 9.971-70.282z" />
                </g>
            </g>
            <g id="Group_4614">
                <g id="Group_4613" clipPath="url(#clipPath-8)">
                    <path id="Rectangle_3049" fill="url(#linear-gradient-5)" d="M0 0H45.115V70.282H0z"
                          transform="translate(80.336 89.464)" />
                </g>
            </g>
            <g id="Group_4616">
                <g id="Group_4615" clipPath="url(#clipPath)">
                    <path id="Path_3508"
                          d="M104.646 253.069h-3.506V250.8a.686.686 0 0 1 .686-.686h2.134a.686.686 0 0 1 .686.686z"
                          fill="#1d5b72" />
                    <path id="Path_3509" fill="#fab81b"
                          d="M105.743 244.761c.072-1.472-.911-3.172-3.126-5.662 0 3.733-2.346 3.1-2.346 5.662a2.763 2.763 0 0 0 1.452 2.434 13.492 13.492 0 0 1 1.668-2.281 3.963 3.963 0 0 0 .666 2.407 2.876 2.876 0 0 0 1.686-2.56" />
                </g>
            </g>
        </svg>
    );
};

export default Balloon;
