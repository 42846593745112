import React, { FC } from 'react';
import styled from 'styled-components';
import useTranslate from '../../config/language';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const StyledNavigationItem = styled.div<{
    active?: boolean;
    disabled?: boolean;
}>`
    height: 45px;
    flex: 1;
    color: ${props => props.theme.colors.white};
    opacity: ${props => (props.active ? 0.9 : 0.5)};
    letter-spacing: 0;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: relative;
    font-family: 'Mediator Narrow CAPS';
    border-top-left-radius: ${props => (props.active ? '8px' : 0)};
    border-top-right-radius: ${props => (props.active ? '8px' : 0)};
    ${props => props.disabled && 'pointer-events: none'};
`;

const NavigationItem: FC<{
    text: string;
    index: number;
    active: boolean;
    onClick(index: number): void;
    className?: string;
    disabled?: boolean;
}> = ({ text, active, onClick, index, className, disabled }) => {
    const translate = useTranslate();
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleClick = (id: number) => {
        if (voiceEffects) {
            playButton();
        }
        onClick(id);
    };

    return (
        <StyledNavigationItem
            className={className}
            active={active}
            onClick={() => handleClick(index)}
            disabled={disabled}
        >
            {translate(text)}
        </StyledNavigationItem>
    );
};

export default NavigationItem;
