import styled from 'styled-components';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from './NeutralTableCell';

export const FailureFirstTableCell = styled(NeutralFirstTableCell)`
    background: rgb(180, 74, 97, 0.53);
`;

export const FailureSecondTableCell = styled(NeutralSecondTableCell)`
    background: rgb(180, 74, 97, 0.53);
`;

export const FailureThirdTableCell = styled(NeutralThirdTableCell)`
    background: rgb(180, 74, 97, 0.53);
`;

export const FailureFourthTableCell = styled(NeutralFourthTableCell)`
    background: rgb(180, 74, 97, 0.53);
`;
