import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { displayMessage, hideMessage } from './actions';
import useTranslate from '../../../config/language';
import { snackbarVariants } from '../../../components/InfoSnackbar/InfoSnackbar';

export function useShowMessage() {
    const dispatch = useDispatch();
    const translate = useTranslate();
    return useCallback(
        (message: string, status: snackbarVariants = snackbarVariants.error) =>
            dispatch(
                displayMessage({ message: translate(message), status: status })
            ),
        [dispatch, translate]
    );
}

export function useHideMessageService() {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(hideMessage());
    }, [dispatch]);
}
