import NavigationItem from '../../../components/Navigation/NavigationItem';
import Navigation from '../../../components/Navigation/Navigation';
import React, { FC } from 'react';
import { ACTION_PANEL_NAVIGATION } from '../tools';
import styled from 'styled-components';

const StyledNavigationItem = styled(NavigationItem)<{ active?: boolean }>`
    background: ${props => (props.active ? props.theme.colors.sea : 'unset')};
    border-bottom: 2px solid ${props => props.theme.colors.white};
    height: 36px;
`;

const ActionPanelNavigation: FC<{
    items: { text: string; index: ACTION_PANEL_NAVIGATION }[];
    activeIndex: ACTION_PANEL_NAVIGATION;
    setActive(index: ACTION_PANEL_NAVIGATION): void;
    disabled?: boolean;
}> = ({ items, activeIndex, setActive, disabled }) => {
    return (
        <Navigation>
            {items.map(item => {
                return (
                    <StyledNavigationItem
                        key={item.index}
                        text={item.text}
                        index={item.index}
                        active={item.index === activeIndex}
                        onClick={setActive}
                        disabled={disabled}
                    />
                );
            })}
        </Navigation>
    );
};

export default ActionPanelNavigation;
