import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import CloudOne from '../../../assets/images/background/cloud-one.png';
import CloudOneX2 from '../../../assets/images/background/cloud-one@2x.png';
import CloudOneX3 from '../../../assets/images/background/cloud-one@3x.png';
import CloudThree from '../../../assets/images/background/cloud-three.png';
import CloudThreeX2 from '../../../assets/images/background/cloud-three@2x.png';
import CloudThreeX3 from '../../../assets/images/background/cloud-three@3x.png';

export const animateCloud = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(60px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const animateCloudOne = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

export const animateCloudTwo = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
`;

const CloudContainer = styled.div<{ animate?: boolean }>`
    right: 19px;
    top: 570px;
    position: absolute;
    width: 527px;
    height: 197px;
    pointer-events: none;
    user-select: none;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloud} 11s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 230px;
        height: 80px;
    }
`;

const StyledCloud = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const CloudOneWrapper = styled.div<{ animate?: boolean }>`
    width: 527px;
    height: 197px;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudOne} 2s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 230px;
        height: 80px;
    }
`;

const CloudTwoWrapper = styled.div<{ animate?: boolean }>`
    width: 392px;
    height: 197px;
    position: absolute;
    bottom: 0;
    right: 16px;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudTwo} 1.5s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 115px;
        height: 80px;
    }
`;

const StyledCloudImage = styled.img`
    width: 100%;
    height: 100%;
`;

const ThirdCloud: FC<{ animate?: boolean; running?: boolean }> = ({
    animate,
    running,
}) => {
    return (
        <CloudContainer animate={animate && running}>
            <StyledCloud>
                <CloudTwoWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudThree}
                        srcSet={`${CloudThreeX2} 2x, ${CloudThreeX3} 3x`}
                    />
                </CloudTwoWrapper>
                <CloudOneWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudOne}
                        srcSet={`${CloudOneX2} 2x, ${CloudOneX3} 3x`}
                    />
                </CloudOneWrapper>
            </StyledCloud>
        </CloudContainer>
    );
};

export default ThirdCloud;
