import React, { FC } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import ProvablyFairTable from './ProvablyFairTable';
import {
    useGameHistory,
    useNextGames,
} from '../../../config/store/state/app.state';
import { GameHistory } from '../../../constants/interfaces/GameHistory';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';

const Container = styled.div`
    background: ${props => props.theme.colors.marine};
    font-family: 'Mediator Narrow CAPS';
    border-radius: 6px;
    box-shadow: none;
    width: 898px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
`;

const Title = styled.div`
    color: ${props => props.theme.colors.wheat};
    font-size: 17px;
    margin-left: 10px;
    font-family: 'Mediator Narrow Caps';
    font-weight: bold;
`;

const Body = styled.div``;
const Content = styled.div``;

const ContentTitle = styled.div`
    letter-spacing: 0;
    color: ${props => props.theme.colors.wheat};
    opacity: 0.5;
    font-family: 'Mediator Narrow';
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const ContentParagraph = styled.div`
    letter-spacing: 0;
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
    font-family: 'Mediator Narrow';
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const ProvablyFairModal: FC<{
    gameId?: number;
    hashCode?: string;
    hashKey?: string;
    bustedAt?: number;
    closeModal(): void;
}> = ({ closeModal }) => {
    const translate = useTranslate();
    let nextGames = useNextGames();
    const gameHistory: Partial<GameHistory>[] = useGameHistory();

    const reversedNextGames: Partial<GameHistory>[] = [...nextGames]
        .reverse()
        .map(game => {
            return {
                ID: game.GameID,
                CrashHash: game.Hash,
            };
        });

    const mergedArrays = reversedNextGames.concat(gameHistory.slice(0, 5));

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <Header>
                    <Title>Provably Fair</Title>
                </Header>
                <Body>
                    <Content>
                        <ContentTitle>
                            {translate(KEYWORDS.CHECK_FAIR_QUESTION)}
                        </ContentTitle>
                        <ContentParagraph>
                            {translate(KEYWORDS.CHECK_FAIR_ANSWER)}
                        </ContentParagraph>
                        <ContentTitle>
                            {translate(KEYWORDS.CHECK_GAME_KEY_QUESTION)}
                        </ContentTitle>
                        <ContentParagraph>
                            {translate(KEYWORDS.CHECK_GAME_KEY_ANSWER)}
                        </ContentParagraph>
                        <ContentParagraph>
                            {translate(KEYWORDS.EXAMPLE)}
                        </ContentParagraph>
                        <ContentParagraph>
                            {translate(KEYWORDS.EXAMPLE_RULE)}
                        </ContentParagraph>
                        <ContentTitle>
                            {translate(KEYWORDS.CHECK_HASH_QUESTION)}
                        </ContentTitle>
                        <ContentParagraph>
                            {translate(KEYWORDS.CHECK_HASH_ANSWER)}
                        </ContentParagraph>
                    </Content>
                    <ProvablyFairTable items={mergedArrays} />
                </Body>
            </Container>
        </Modal>
    );
};

export default ProvablyFairModal;
