import React, { FC } from 'react';
import { TableRow } from '../Table/TableRow';
import { TableBody } from '../Table/TableBody';
import { TableCell } from '../Table/TableCell';
import Table from '../Table/Table';
import styled from 'styled-components';

const StyledFirstTableCell = styled(TableCell)`
    height: 31px;
    width: 80%;
    text-align: left;
    padding: 0 8px;
    color: ${props => props.theme.colors.white};
`;

const StyledSecondTableCell = styled(TableCell)`
    height: 31px;
    padding: 0 8px;
    color: ${props => props.theme.colors.white};
`;

const BetsModalTable: FC<{ hashKey?: string; bustedAt?: number }> = ({
    hashKey,
    bustedAt,
}) => {
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <StyledFirstTableCell>{hashKey}</StyledFirstTableCell>
                    <StyledSecondTableCell>{bustedAt}x</StyledSecondTableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
export default BetsModalTable;
