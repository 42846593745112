import React, { FC } from 'react';
import DropDown from '../../../../components/DropDown/DropDown';
import {
    TOP_LIST_FILTER_INDEXES,
    TOP_LIST_INDEXES,
    TopListFilters,
    TopListItems,
} from '../configs/tools';
import styled from 'styled-components';

const StyledTopBoardActions = styled.div`
    display: flex;
    z-index: 1;
`;

const DropDownWrapper = styled.div`
    width: 50%;
    z-index: 1;
`;

const Divider = styled.div`
    width: 8px;
    flex-shrink: 0;
`;

const TopBetsActions: FC<{
    active: number;
    setActive(value: TOP_LIST_INDEXES): void;
    activeFilter: string;
    setActiveFilter(value: TOP_LIST_FILTER_INDEXES): void;
}> = ({ active, setActive, activeFilter, setActiveFilter }) => {
    return (
        <StyledTopBoardActions>
            <DropDownWrapper>
                <DropDown
                    items={TopListItems}
                    selected={active}
                    selectItem={setActive}
                />
            </DropDownWrapper>
            <Divider />
            <DropDownWrapper>
                <DropDown
                    items={TopListFilters}
                    selected={activeFilter}
                    selectItem={setActiveFilter}
                />
            </DropDownWrapper>
        </StyledTopBoardActions>
    );
};

export default TopBetsActions;
