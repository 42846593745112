import React, { FC } from 'react';
import styled from 'styled-components';

const TBody = styled.tbody`
    color: ${props => props.theme.colors.white};
`;

export const TableBody: FC<{
    className?: string;
}> = ({ children, className }) => {
    return <TBody className={className}>{children}</TBody>;
};
