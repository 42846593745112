import React, { FC } from 'react';
import { TableHead } from '../../../../components/Table/TableHead';
import { TableBody } from '../../../../components/Table/TableBody';
import { TableRow } from '../../../../components/Table/TableRow';
import Table from '../../../../components/Table/Table';
import styled from 'styled-components';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from './NeutralTableCell';
import { GameHistory } from '../../../../constants/interfaces/GameHistory';
import { StyledTableHeaderCell } from './TableHeaderCell.';
import { KEYWORDS } from '../../../../config/language/keywords';
import useTranslate from '../../../../config/language';

const Container = styled.div`
    width: 100%;
`;

const StyledTableRow = styled(TableRow)`
    background: #ffffff05;
`;

const TableBodyContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const StyledTable = styled(Table)`
    min-width: 800px;
`;

const ProvablyFairTable: FC<{ items: Partial<GameHistory>[] }> = ({
    items,
}) => {
    const translate = useTranslate();

    return (
        <Container>
            <Table>
                <TableHead>
                    <tr>
                        <StyledTableHeaderCell width={4}>
                            ID
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell width={9}>
                            {translate(KEYWORDS.CRUSHED_AT)}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell width={42}>
                            {translate(KEYWORDS.KEY)}
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell width={40}>
                            {translate(KEYWORDS.HASH_CODE)}
                        </StyledTableHeaderCell>
                    </tr>
                </TableHead>
            </Table>
            <TableBodyContainer>
                <StyledTable>
                    <TableBody>
                        {items.map((item: Partial<GameHistory>, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <NeutralFirstTableCell>
                                        {item.ID}
                                    </NeutralFirstTableCell>
                                    <NeutralSecondTableCell>
                                        {item.BustedAt || '-'}
                                    </NeutralSecondTableCell>
                                    <NeutralThirdTableCell>
                                        {item.CrashSource || '-'}
                                    </NeutralThirdTableCell>
                                    <NeutralFourthTableCell>
                                        {item.CrashHash || '-'}
                                    </NeutralFourthTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </StyledTable>
            </TableBodyContainer>
        </Container>
    );
};

export default ProvablyFairTable;
