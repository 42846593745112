export const variables = {
    colors: {
        redPink: '#f02f4c',
        warmPink: '#ef4d65',
        darkOrange: '#cb3802',
        vermillion: '#e74e0f',
        vermillionTwo: '#e64d0e',
        dustyOrange: '#f87b2d',
        lightOrange: '#faaa41',
        lighterOrange: '#e6b40e',
        mustard: '#cbb402',
        uglyYellow: '#c3cb02',
        brightLimeGreen: '#73ff34',
        slimeGreen: '#95cb02',
        leafyGreen: '#58bc34',
        frogGreen: '#38cc03',
        grassGreen: '#38a50e',
        turtleGreen: '#75b75d',
        lightNeonGreen: 'rgba(114, 254, 52, 0)',
        brightSkyBlue: '#12c8ff',
        white: '#ffffff',
        whiteGrayish: '#ffffffd6',
        gray: '#707070',
        charcoalGrey: '#413e45',
        charcoalGreyTwo: '#302c34',
        charcoalGreyTree: '#343038',
        charcoalGreyFour: '#383f49',
        dark: '#241e2b',
        transparent: '#ffffff0a',
        transparent2: '#ffffff1f',
        transparent3: '#ffffff21',
        transparent4: '#ffffffd5',
        transparent5: '#ffffff05',
        transparentModal: '#00000050',

        //NEW STYLES
        sea: '#3d9183',
        lightNavyBlue: '#256174',
        wheat: '#f7d385',
        darkRose: '#b44a61',
        paleRed: '#d5554c',
        darkPeach: '#e38049',
        marine: '#033852',
        bgMarine: 'rgb(3, 56, 82, 0.3)',
        bgModal: 'rgb(255, 255, 255, 0.8)',
        greenyBlue: '#5fbb9d',
        greenyBlueTwo: '#5cba9e',
        greenyBlueThree: '#5dba9e',
        paleOlive: '#bdca8e',
    },
    breaks: {
        '1000': 1000,
        a: 800,
    },
};
