import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ArrowDown from '../Icons/ArrowDown';
import { motion } from 'framer-motion';

const StyledBoxContainer = styled(motion.div)<{ open: boolean }>`
    height: ${props => (props.open ? 'auto' : '32px')};
    width: 100%;
    display: flex;
    border-radius: 4px;
    background: ${props =>
        props.open ? 'rgb(255, 255, 255, 0.08)' : 'transparent'};
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 10;
`;

const StyledContent = styled.div`
    flex: 1;
`;

const Actions = styled.div`
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ActionsIconContainer = styled.div`
    cursor: pointer;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-color: #ffffff1f;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ActionsIcon = styled(motion.div)`
    display: flex;
    height: 11px;
    width: 11px;
`;

const BoxContainer: FC<{ className?: string }> = ({ children, className }) => {
    const [open, setOpen] = useState(false);

    return (
        <StyledBoxContainer
            open={open}
            animate={open ? { height: 'auto' } : { height: 32 }}
            className={className}
        >
            <StyledContent>{children}</StyledContent>
            <Actions onClick={() => setOpen(!open)}>
                <ActionsIconContainer>
                    <ActionsIcon
                        animate={open ? { rotate: 180 } : { rotate: 0 }}
                        onClick={() => setOpen(!open)}
                    >
                        <ArrowDown />
                    </ActionsIcon>
                </ActionsIconContainer>
            </Actions>
        </StyledBoxContainer>
    );
};

export default BoxContainer;
