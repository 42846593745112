import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Ship } from '../../components/Ship';
import Countdown from './components/Countdown';

const LoadingContainer = styled(motion.div)`
    position: relative;
    @media (max-height: ${800}px) {
        transform: scale(0.7);
    }
    @media (max-height: ${680}px) {
        transform: scale(0.6);
    }
    @media (max-width: ${1000}px) {
        transform: scale(0.8);
    }
`;

const LoadingShipContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    top: -50px;
    @media (max-width: ${1000}px) {
        top: -80px;
        transform: scale(0.8);
    }
    @media (max-height: ${800}px) {
        top: -100px;
    }
    @media (max-height: ${680}px) {
        top: -120px;
    }
`;

export const Preparing: FC<{ countDown: string }> = ({ countDown }) => {
    return (
        <LoadingContainer>
            <Countdown value={countDown} />
            <LoadingShipContainer>
                <Ship width={100} animateShip={true} />
            </LoadingShipContainer>
        </LoadingContainer>
    );
};
