import React, { FC } from 'react';
import { IconProps } from './types';

export const ArrowUp: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 8.947 8"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g transform="rotate(180 4.12 4)">
                <path
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    d="M0 0l3.059 3.059L6.119 0"
                    transform="translate(.706 2.792)"
                />
            </g>
        </svg>
    );
};

export default ArrowUp;
