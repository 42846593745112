import { TopMultipliers } from '../../../../../constants/interfaces/TopMultipliers';
import { TopWinnings } from '../../../../../constants/interfaces/TopWinnings';

export enum TOP_BETS_ACTIONS {
    INITIALIZE_TOP_ODDS = 'INITIALIZE_TOP_ODDS',
    INITIALIZE_TOP_WINNINGS = 'INITIALIZE_TOP_WINNINGS',
    INITIALIZE_TOP_MULTIPLIER_CASH_OUTS = 'INITIALIZE_TOP_MULTIPLIER_CASH_OUTS',
}

export const initializeTopOddsAction = (payload: TopMultipliers) => {
    return {
        type: TOP_BETS_ACTIONS.INITIALIZE_TOP_ODDS as TOP_BETS_ACTIONS.INITIALIZE_TOP_ODDS,
        payload,
    };
};

export const initializeTopWinningsAction = (payload: TopWinnings) => {
    return {
        type: TOP_BETS_ACTIONS.INITIALIZE_TOP_WINNINGS as TOP_BETS_ACTIONS.INITIALIZE_TOP_WINNINGS,
        payload,
    };
};

export const initializeTopMultiplierCashOutsAction = (payload: TopWinnings) => {
    return {
        type: TOP_BETS_ACTIONS.INITIALIZE_TOP_MULTIPLIER_CASH_OUTS as TOP_BETS_ACTIONS.INITIALIZE_TOP_MULTIPLIER_CASH_OUTS,
        payload,
    };
};
