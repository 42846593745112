import React, { FC } from 'react';
import { IconProps } from './types';

export const Like: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 11.264 10.805"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="like" transform="translate(0 -10.433)">
                <g
                    id="Group_1762"
                    data-name="Group 1762"
                    transform="translate(0 13.883)"
                >
                    <g
                        id="Group_1761"
                        data-name="Group 1761"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_515"
                            data-name="Path 515"
                            d="M1.839,167.256H.368a.368.368,0,0,0-.368.368v6.619a.368.368,0,0,0,.368.368H1.839a.368.368,0,0,0,.368-.368v-6.619A.368.368,0,0,0,1.839,167.256Z"
                            transform="translate(0 -167.256)"
                            fill="currentColor"
                        />
                    </g>
                </g>
                <g
                    id="Group_1764"
                    data-name="Group 1764"
                    transform="translate(2.942 10.433)"
                >
                    <g
                        id="Group_1763"
                        data-name="Group 1763"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_516"
                            data-name="Path 516"
                            d="M141.13,13.883c-.059-.01-2.832,0-2.832,0l.387-1.057a1.906,1.906,0,0,0-.647-2.24,1.271,1.271,0,0,0-.851-.125.614.614,0,0,0-.375.281,1.527,1.527,0,0,0-.118.521,2.251,2.251,0,0,1-.624,1.192c-.571.557-2.345,2.163-2.345,2.163V20.5h6.129a1.114,1.114,0,0,0,.961-1.644,1.113,1.113,0,0,0,.368-1.471,1.113,1.113,0,0,0,.368-1.471A1.122,1.122,0,0,0,141.13,13.883Z"
                            transform="translate(-133.726 -10.433)"
                            fill="currentColor"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Like;
