import React, { FC } from 'react';
import { IconProps } from './types';

export const BirdFour: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 61.035 25.745"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M116.129,45.182c-.316.057-7.431-1.874-18.9,3.817-5.768,2.861-9.582,7.731-12,12.026-8.93-9.575-30.139-2.739-30.139-2.739.26-.008,21.872-2.5,29.316,10.066,1.507,2.545,3.513,3.571,4.453-.034,5.255-20.173,27.267-23.136,27.267-23.136"
                transform="translate(-55.094 -44.911)"
                fill="#3d9183"
            />
        </svg>
    );
};

export default BirdFour;
