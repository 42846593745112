import React, { FC } from 'react';
import styled from 'styled-components';
import ArrowUp from '../Icons/ArrowUp';
import ArrowDown from '../Icons/ArrowDown';
import NumericInput from 'react-numeric-input';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const StyledNumberInput = styled.div<{ fontSize: number; disabled?: boolean }>`
    height: 100%;
    min-width: 70px;
    width: 100%;
    min-height: 30px;
    position: relative;
    & > span {
        height: 100%;
        width: 100%;
        display: block;
        border: none;
        font-family: 'Mediator Narrow';
        background: ${props => props.theme.colors.white};
        border-radius: 4px;
        box-sizing: border-box;
        font-size: ${props => props.fontSize}px;
        & > input {
            color: ${props => props.theme.colors.sea};
            padding: 0 20px 0 16px !important;
            font-weight: bold;
            border: 1px solid ${props => props.theme.colors.sea} !important;
        }
        & > b {
            display: none;
        }
    }
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        & > span {
            font-size: 16px;
        }
    }
`;

const Input = styled(NumericInput)`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: none;
    padding: 0 40px 0 16px;
    outline: none;
    &:disabled {
        opacity: 0.5;
    }
`;

const Arrows = styled.div<{ disabled?: boolean }>`
    position: absolute;
    width: 30px;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props =>
        props.disabled &&
        `
        opacity: 0.5;
        pointer-events: none;
    `}
`;

const ArrowContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 2px 0;
    &:active {
        opacity: 0.9;
    }
`;

const ArrowIcon = styled.div`
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.sea};
`;

function formatDecimalsNumber(value: number | null) {
    if (!value) return 0.0;
    return Number(Math.round(parseFloat(value + 'e' + 2)) + 'e-' + 2).toFixed(
        2
    );
}

function formatNumber(num: number | null, character: string) {
    return formatDecimalsNumber(num) + ` ${character}`;
}

const NumberInput: FC<{
    value: number;
    onChange(value: number | null): void;
    step?: number;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    fontSize?: number;
    className?: string;
    character?: string;
}> = ({
    value,
    onChange,
    step = 0.1,
    disabled,
    minValue = 0.1,
    maxValue,
    fontSize = 24,
    className,
    character = '₾',
}) => {
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleInputChange = (val: number | null) => {
        onChange(val);
    };

    const handleIncrement = () => {
        if (voiceEffects) {
            playButton();
        }
        onChange(Number((value + step).toFixed(2)));
    };

    const handleDecrement = () => {
        if (voiceEffects) {
            playButton();
        }
        onChange(Number((value - step).toFixed(2)));
    };

    return (
        <StyledNumberInput
            disabled={disabled}
            fontSize={fontSize}
            className={className}
        >
            <Input
                type="number"
                pattern="[0-9]*"
                value={value.toFixed(2)}
                step={step}
                min={minValue}
                max={maxValue}
                onChange={value => {
                    handleInputChange(value);
                }}
                disabled={disabled}
                format={val => formatNumber(val, character)}
            />
            <Arrows disabled={disabled}>
                <ArrowContainer
                    onClick={!disabled ? handleIncrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowUp />
                    </ArrowIcon>
                </ArrowContainer>
                <ArrowContainer
                    onClick={!disabled ? handleDecrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowDown />
                    </ArrowIcon>
                </ArrowContainer>
            </Arrows>
        </StyledNumberInput>
    );
};

export default NumberInput;
