import React, { FC } from 'react';
import { IconProps } from './types';

export const BirdThree: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 53.479 15.819"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M25.877,9.838C20.028-1.991,0,.152,0,.152.23.209,18.7,3.653,23.11,14.3a2.543,2.543,0,0,0,4.112.767C35.551,5.15,53.245,8.8,53.479,8.816c0,0-18.32-8.357-27.6,1.021"
                transform="translate(0 0)"
                fill="#3d9183"
            />
        </svg>
    );
};

export default BirdThree;
