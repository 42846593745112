import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import CloudOne from '../../../assets/images/background/cloud-one.png';
import CloudOneX2 from '../../../assets/images/background/cloud-one@2x.png';
import CloudOneX3 from '../../../assets/images/background/cloud-one@3x.png';

export const animateCloud = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const animateCloudOne = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
`;

export const animateCloudTwo = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
`;

const CloudContainer = styled.div<{ animate?: boolean }>`
    right: -152px;
    top: 15px;
    position: absolute;
    width: 489px;
    height: 151px;
    pointer-events: none;
    user-select: none;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloud} 12s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 255px;
        right: -62px;
    }
`;

const StyledCloud = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const CloudOneWrapper = styled.div<{ animate?: boolean }>`
    width: 259px;
    height: 96px;
    position: absolute;
    bottom: 0;
    left: 73px;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudOne} 2s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 160px;
        height: 70px;
    }
`;

const CloudTwoWrapper = styled.div<{ animate?: boolean }>`
    width: 489px;
    height: 151px;
    position: absolute;
    bottom: 0;
    right: 16px;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudTwo} 1.5s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 230px;
        height: 80px;
    }
`;

const StyledCloudImage = styled.img`
    width: 100%;
    height: 100%;
`;

const FourthCloud: FC<{ animate?: boolean; running?: boolean }> = ({
    animate,
    running,
}) => {
    return (
        <CloudContainer animate={animate && running}>
            <StyledCloud>
                <CloudTwoWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudOne}
                        srcSet={`${CloudOneX2} 2x, ${CloudOneX3} 3x`}
                    />
                </CloudTwoWrapper>
                <CloudOneWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudOne}
                        srcSet={`${CloudOneX2} 2x, ${CloudOneX3} 3x`}
                    />
                </CloudOneWrapper>
            </StyledCloud>
        </CloudContainer>
    );
};

export default FourthCloud;
