import { MULTIPLIER_BREAK_POINTS } from '../../features/betColors/configs';

const getRotationDegreeBasedOnMultiplierRange = (multiplier: number) => {
    if (multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_1) {
        return 0;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_1 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_2
    ) {
        return 60;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_2 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_3
    ) {
        return 120;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_3 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_4
    ) {
        return 180;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_4 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_5
    ) {
        return 240;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_5 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_6
    ) {
        return 300;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_6 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_7
    ) {
        return 360;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_7 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_8
    ) {
        return 60;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_8 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_9
    ) {
        return 120;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_9 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_10
    ) {
        return 180;
    } else if (
        multiplier > MULTIPLIER_BREAK_POINTS.RANGE_10 &&
        multiplier <= MULTIPLIER_BREAK_POINTS.RANGE_11
    ) {
        return 240;
    } else {
        return 300;
    }
};

export function getAltimeterRotationDegree(multiplier?: number) {
    if (!multiplier) {
        return 0;
    }
    return getRotationDegreeBasedOnMultiplierRange(multiplier);
}
