import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import CloudOne from '../../../assets/images/background/cloud-one.png';
import CloudOneX2 from '../../../assets/images/background/cloud-one@2x.png';
import CloudOneX3 from '../../../assets/images/background/cloud-one@3x.png';
import CloudTwo from '../../../assets/images/background/cloud-two.png';
import CloudTwoX2 from '../../../assets/images/background/cloud-two@2x.png';
import CloudTwoX3 from '../../../assets/images/background/cloud-two@3x.png';

export const animateCloud = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const animateCloudOne = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

export const animateCloudTwo = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
`;

const CloudContainer = styled.div<{ animate?: boolean }>`
    left: 15%;
    top: 150px;
    position: absolute;
    width: 305px;
    height: 150px;
    pointer-events: none;
    user-select: none;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloud} 10s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 175px;
    }
`;

const StyledCloud = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const CloudOneWrapper = styled.div<{ animate?: boolean }>`
    width: 238px;
    height: 88.7px;
    position: absolute;
    bottom: 0;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudOne} 2s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 150px;
        height: 50px;
    }
`;

const CloudTwoWrapper = styled.div<{ animate?: boolean }>`
    width: 289px;
    height: 145px;
    position: absolute;
    bottom: 0;
    left: 12px;
    animation: ${props =>
        props.animate === true
            ? css`
                  ${animateCloudTwo} 1.5s linear infinite;
              `
            : ''};
    @media (max-width: ${1200}px) {
        width: 160px;
        height: 70px;
    }
`;

const StyledCloudImage = styled.img`
    width: 100%;
    height: 100%;
`;

const FirstCloud: FC<{ animate?: boolean; running?: boolean }> = ({
    animate,
    running,
}) => {
    return (
        <CloudContainer animate={animate && running}>
            <StyledCloud>
                <CloudTwoWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudTwo}
                        srcSet={`${CloudTwoX2} 2x, ${CloudTwoX3} 3x`}
                    />
                </CloudTwoWrapper>
                <CloudOneWrapper animate={animate}>
                    <StyledCloudImage
                        src={CloudOne}
                        srcSet={`${CloudOneX2} 2x, ${CloudOneX3} 3x`}
                    />
                </CloudOneWrapper>
            </StyledCloud>
        </CloudContainer>
    );
};

export default FirstCloud;
