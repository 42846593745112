import React, { useState } from 'react';
import styled from 'styled-components';
import ActionPanelNavigation from './components/ActionPanelNavigation';
import { ACTION_PANEL_NAVIGATION, ActionPanelNavigationItems } from './tools';
import ErrorMessages from '../errorMessages/ErrorMessages';
import { useErrorMessagesState } from '../errorMessages/store/state';
import ActionBoardContainer from './components/ActionBoard';
import AutoBoardContainer from './components/AutoBoard';
import { RegisterState, useBoards } from '../../config/store/state/app.state';

const StyledActionPanel = styled.div`
    position: relative;
`;

const StyledNavigation = styled.div`
    position: relative;
`;

const StyledBody = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px;
    box-sizing: border-box;
    @media (max-width: ${700}px) {
        flex-wrap: wrap;
        height: auto;
        padding: 4px;
    }
    height: 208px;
    padding-bottom: 24px;
    @media (max-height: ${550}px) {
        height: 176px;
    }
    @media (max-width: ${700}px) {
        height: auto;
        flex-direction: column;
    }
`;

const ActionPanel = () => {
    const [active, setActive] = useState<ACTION_PANEL_NAVIGATION>(
        ACTION_PANEL_NAVIGATION.MANUAL
    );
    const messages = useErrorMessagesState();
    const disabled =
        useBoards().filter(
            board => board.registerState !== RegisterState.UNREGISTERED
        ).length > 0;

    return (
        <StyledActionPanel>
            {messages.length > 0 && <ErrorMessages />}
            <StyledNavigation>
                <ActionPanelNavigation
                    items={ActionPanelNavigationItems}
                    activeIndex={active}
                    setActive={setActive}
                    disabled={disabled}
                />
            </StyledNavigation>
            <StyledBody>
                {active === ACTION_PANEL_NAVIGATION.MANUAL && (
                    <ActionBoardContainer />
                )}
                {active === ACTION_PANEL_NAVIGATION.AUTO && (
                    <AutoBoardContainer />
                )}
            </StyledBody>
        </StyledActionPanel>
    );
};

export default ActionPanel;
