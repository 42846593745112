import React, { FC } from 'react';
import styled from 'styled-components';
import History from '../../../../components/Icons/History';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { useGameHistory } from '../../../../config/store/state/app.state';
import CustomResultBox from '../../../../components/ResultBox/CustomResultBox';

const StyledInfoPanel = styled.div`
    display: flex;
    height: 26px;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.wheat};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const IconContainer = styled.div`
    color: ${props => props.theme.colors.marine};
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 4px;
    margin-right: 8px;
    display: flex;
    align-items: center;
`;

const Text = styled.div`
    color: ${props => props.theme.colors.marine};
    font-family: 'Mediator Narrow';
    font-weight: bold;
    font-size: 14px;
    margin-right: 16px;
`;

const ShowHistory = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const ItemContainer = styled.div`
    margin-right: 16px;
`;

const BetsInfoPanel: FC<{
    showLastBets: boolean;
    setShowLastBets(setShow: boolean): void;
    totalBets: number;
}> = ({ showLastBets, setShowLastBets, totalBets }): any => {
    const translate = useTranslate();
    const lastGameStats = useGameHistory()[0];

    return (
        <StyledInfoPanel>
            <ShowHistory>
                <IconContainer>
                    <History />
                </IconContainer>
                <Text onClick={() => setShowLastBets(!showLastBets)}>
                    {showLastBets ? 'Live' : translate(KEYWORDS.PREVIOUS_GAME)}
                </Text>
                {lastGameStats && showLastBets && (
                    <ItemContainer>
                        <CustomResultBox game={lastGameStats} />
                    </ItemContainer>
                )}
            </ShowHistory>
            <Text>
                {translate(KEYWORDS.ALL_BETS)} {totalBets}
            </Text>
        </StyledInfoPanel>
    );
};

export default BetsInfoPanel;
