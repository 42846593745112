import React, { FC } from 'react';
import styled from 'styled-components';
import { variables } from '../../constants/variables';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const StyledButton = styled.button<{
    height?: number;
    width?: number;
    background: string;
}>`
    height: 100%;
    width: 100%;
    outline: 0;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-family: 'Mediator Narrow CAPS';
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    background: ${props => props.background};
    &:active {
        opacity: 0.8;
    }
    &:disabled {
        background: ${props => props.theme.colors.transparent3};
        color: ${props => props.theme.colors.whiteGrayish};
    }
`;

const Button: FC<{
    text?: string;
    height?: number;
    width?: number;
    onClick?(): void;
    className?: string;
    background?: string;
    disabled?: boolean;
}> = React.memo(
    ({ text, height, width, onClick, className, background, disabled }) => {
        const playButton = usePlayButtonSoundService();
        const voiceEffects = useGameConfigs().voiceEffects;

        const handleClick = () => {
            if (voiceEffects) {
                playButton();
            }

            if (onClick) {
                onClick();
            }
        };

        return (
            <StyledButton
                className={className}
                height={height}
                width={width}
                onClick={handleClick}
                background={background ? background : variables.colors.sea}
                disabled={disabled}
            >
                {text}
            </StyledButton>
        );
    }
);

export default Button;
