//TODO: lang
const MONTH_LIST = [
    'იან.',
    'თებ.',
    'მარ.',
    'აპრ.',
    'მაის.',
    'ივნ.',
    'ივლ.',
    'აგვ.',
    'სექ.',
    'ოქტ.',
    'ნოე.',
    'დეკ.',
];

export const formatDate = (date: string) => {
    const formatDate = new Date(date);
    const month = formatDate.getMonth();
    const day = formatDate.getDate();
    return day + ' ' + MONTH_LIST[month];
};

export const formatTime = (date: string) => {
    const formatDate = new Date(date);

    return (
        formatDate.getHours() + ':' + ('0' + formatDate.getMinutes()).slice(-2)
    );
};
