import React, { FC } from 'react';
import styled from 'styled-components';
import MessageItem from './MessageItem';
import { useMessengerState } from '../../store/state';
import {
    ChatMessage,
    SystemChatMessage,
} from '../../../../constants/interfaces/ChatMessage';
import SystemMessageItem from './SystemMessageitem';

const MessagesWrapper = styled.div`
    flex: 1;
    height: calc(100vh - 350px);
`;

const MessagesContainer = styled.div`
    padding: 4px 8px 4px 16px;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
`;

const Messages: FC = () => {
    const messages = useMessengerState().messages.reverse();

    return (
        <MessagesWrapper>
            <MessagesContainer>
                {messages.map((message: ChatMessage | SystemChatMessage) => {
                    if (message.system) {
                        return (
                            <SystemMessageItem
                                key={message.MessageId}
                                message={message}
                            />
                        );
                    } else {
                        return (
                            <MessageItem
                                key={message.MessageId}
                                message={message}
                            />
                        );
                    }
                })}
            </MessagesContainer>
        </MessagesWrapper>
    );
};

export default Messages;
