import { Balance } from '../../../../constants/interfaces/Balance';
import { Player } from '../../../../constants/interfaces/Player';

export enum PROFILE_ACTIONS {
    INITIALIZE_PROFILE = 'INITIALIZE_PROFILE',
    TOGGLE_CONFIG_VOICE = 'TOGGLE_CONFIG_VOICE',
    TOGGLE_CONFIG_BACKGROUND_MUSIC = 'TOGGLE_CONFIG_BACKGROUND_MUSIC',
    TOGGLE_CONFIG_ANIMATION = 'TOGGLE_CONFIG_ANIMATION',
}

export interface InitializeProfileParams {
    B: Balance;
    MyID: number;
    Player: Player;
}

export const initializeProfileAction = (profile: InitializeProfileParams) => {
    return {
        type: PROFILE_ACTIONS.INITIALIZE_PROFILE as PROFILE_ACTIONS.INITIALIZE_PROFILE,
        profile,
    };
};

export const toggleConfigBackgroundMusicAction = (enable: boolean) => {
    return {
        type: PROFILE_ACTIONS.TOGGLE_CONFIG_BACKGROUND_MUSIC as PROFILE_ACTIONS.TOGGLE_CONFIG_BACKGROUND_MUSIC,
        enable,
    };
};

export const toggleConfigVoiceAction = (enable: boolean) => {
    return {
        type: PROFILE_ACTIONS.TOGGLE_CONFIG_VOICE as PROFILE_ACTIONS.TOGGLE_CONFIG_VOICE,
        enable,
    };
};

export const toggleConfigAnimationAction = (enable: boolean) => {
    return {
        type: PROFILE_ACTIONS.TOGGLE_CONFIG_ANIMATION as PROFILE_ACTIONS.TOGGLE_CONFIG_ANIMATION,
        enable,
    };
};
