import React, { FC } from 'react';
import styled from 'styled-components';
import { getMultiplierRGBColor } from '../../features/betColors';

const StyledResultBox = styled.div<{ color?: string }>`
    margin: 4px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    line-height: 14.7px;
    padding: 5px 8px;
    display: inline-block;
    background-color: ${props =>
        props.color ? props.color : props.theme.colors.leafyGreen};
    font-weight: bold;
    user-select: none;
`;

const PlainResultBox: FC<{ value: number }> = ({ value }) => {
    return (
        <div>
            <StyledResultBox color={getMultiplierRGBColor(value)}>
                {value.toFixed(2)}x
            </StyledResultBox>
        </div>
    );
};

export default PlainResultBox;
