import React, { FC } from 'react';
import styled from 'styled-components';
import { Winnings } from '../../../../constants/interfaces/TopWinnings';
import TableAvatar from '../../components/TableAvatar';
import { formatUsername } from '../../../../helpers/functions/format-username';
import {
    formatDate,
    formatTime,
} from '../../../../helpers/functions/format-date';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';

const StyledTopBoardActions = styled.div`
    z-index: 1;
    padding: 4px 16px;
    margin-bottom: 2px;
    font-family: 'Mediator Narrow';
    background: ${props => props.theme.colors.transparent5};
`;

const FirstRow = styled.div`
    flex: 1;
    height: 35px;
    display: flex;
    justify-content: space-between;
`;

const Avatar = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled.div`
    font-size: 15px;
`;

const Date = styled.div`
    display: flex;
    color: ${props => props.theme.colors.transparent4};
    opacity: 0.5;
    font-size: 14px;
`;

const SecondRow = styled.div`
    display: flex;
`;

const Block = styled.div`
    height: 100%;
    flex: 35;
`;

const Block2 = styled.div`
    height: 100%;
    flex: 37;
`;

const Block3 = styled.div`
    height: 100%;
    flex: 24;
`;

const BlockLabel = styled.div`
    height: 20px;
    color: ${props => props.theme.colors.transparent4};
    opacity: 0.5;
    font-size: 14px;
    display: flex;
    align-items: center;
`;

const BlockValue = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
`;

const BetValue = styled(BlockValue)``;

const MultiplierValue = styled(BlockValue)``;

const ProfitValue = styled(BlockValue)`
    font-weight: bold;
`;

const WinningTableItem: FC<{
    game: Winnings;
}> = ({ game }) => {
    const translate = useTranslate();

    return (
        <StyledTopBoardActions>
            <FirstRow>
                <Avatar>
                    <TableAvatar />
                    <Name>{formatUsername(game.Player.N)}</Name>
                </Avatar>
                <Date>
                    {`${formatDate(game.Timestamp)} ${formatTime(
                        game.Timestamp
                    )}`}
                </Date>
            </FirstRow>
            <SecondRow>
                <Block>
                    <BlockLabel>{translate(KEYWORDS.BET)}</BlockLabel>
                    <BetValue>{game.BuyIn}₾</BetValue>
                </Block>
                <Block2>
                    <BlockLabel>
                        {translate(KEYWORDS.WINNINGS_ON_MULTIPLIER)}
                    </BlockLabel>
                    <MultiplierValue>{game.Multiplier}x</MultiplierValue>
                </Block2>
                <Block3>
                    <BlockLabel>
                        {translate(KEYWORDS.WINNING_AMOUNT)}
                    </BlockLabel>
                    <ProfitValue>
                        {(game.BuyIn * game.Multiplier).toFixed(2)}₾
                    </ProfitValue>
                </Block3>
            </SecondRow>
        </StyledTopBoardActions>
    );
};

export default WinningTableItem;
