import React, { FC } from 'react';
import SelectDropDown from '../../../../components/SelectDropDown/SelectDropDown';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    display: flex;
`;

const Separator = styled.div`
    margin: 0 4px;
    height: 100%;
`;

const OnWinContainer = styled.div<{ disabled?: boolean }>`
    flex: 1;
    display: flex;
    background: rgb(255, 255, 255, 0.2);
    border: solid 1px ${props => props.theme.colors.marine};
    border-radius: 4px;
    ${props => props.disabled && 'opacity: 0.5; pointer-events: none'}
`;

const StyledNumberInput = styled(NumberInput)`
    & > span {
        background: unset;
        & > input {
            border: none !important;
        }
    }
`;

const ConditionDropdown: FC<{
    value: number;
    onValueChange(value: number): void;
    selected: 0 | 1;
    selectAction(value: 0 | 1): void;
    items: { text: string; index: number }[];
    disabled?: boolean;
}> = ({ value, onValueChange, selected, selectAction, items, disabled }) => {
    return (
        <StyledWrapper>
            <OnWinContainer>
                <SelectDropDown
                    items={items}
                    selected={selected}
                    selectItem={selectAction}
                    disabled={disabled}
                />
            </OnWinContainer>
            {selected === 1 && (
                <>
                    <Separator />
                    <OnWinContainer>
                        <StyledNumberInput
                            value={value}
                            onChange={onValueChange}
                            character={'x'}
                            disabled={disabled}
                        />
                    </OnWinContainer>
                </>
            )}
        </StyledWrapper>
    );
};

export default ConditionDropdown;
