import Image_7 from '../../assets/images/explosion/7.png';
import Image_8 from '../../assets/images/explosion/8.png';
import Image_9 from '../../assets/images/explosion/9.png';
import Image_10 from '../../assets/images/explosion/10.png';
import Image_11 from '../../assets/images/explosion/11.png';
import Image_12 from '../../assets/images/explosion/12.png';
import Image_13 from '../../assets/images/explosion/13.png';
import Image_14 from '../../assets/images/explosion/14.png';
import Image_15 from '../../assets/images/explosion/15.png';
import Image_16 from '../../assets/images/explosion/16.png';
import Image_17 from '../../assets/images/explosion/17.png';
import Image_18 from '../../assets/images/explosion/18.png';
import Image_19 from '../../assets/images/explosion/19.png';
import Image_20 from '../../assets/images/explosion/20.png';
import Image_21 from '../../assets/images/explosion/21.png';
import Image_22 from '../../assets/images/explosion/22.png';
import Image_23 from '../../assets/images/explosion/23.png';
import Image_24 from '../../assets/images/explosion/24.png';
import Image_25 from '../../assets/images/explosion/25.png';
import Image_26 from '../../assets/images/explosion/26.png';
import Image_27 from '../../assets/images/explosion/27.png';
import Image_28 from '../../assets/images/explosion/28.png';
import Image_29 from '../../assets/images/explosion/29.png';
import Image_30 from '../../assets/images/explosion/30.png';
import Image_31 from '../../assets/images/explosion/31.png';
import Image_32 from '../../assets/images/explosion/32.png';
import Image_33 from '../../assets/images/explosion/33.png';
import Image_34 from '../../assets/images/explosion/34.png';
import Image_35 from '../../assets/images/explosion/35.png';
import Image_36 from '../../assets/images/explosion/36.png';
import Image_37 from '../../assets/images/explosion/37.png';
import Image_38 from '../../assets/images/explosion/38.png';
import Image_39 from '../../assets/images/explosion/39.png';
import Image_40 from '../../assets/images/explosion/40.png';
import Image_41 from '../../assets/images/explosion/41.png';
import Image_42 from '../../assets/images/explosion/42.png';
import Image_43 from '../../assets/images/explosion/43.png';
import Image_44 from '../../assets/images/explosion/44.png';
import Image_45 from '../../assets/images/explosion/45.png';
import Image_46 from '../../assets/images/explosion/46.png';
import Image_47 from '../../assets/images/explosion/47.png';

export const EXPLOSION = [
    Image_7,
    Image_8,
    Image_9,
    Image_10,
    Image_11,
    Image_12,
    Image_13,
    Image_14,
    Image_15,
    Image_16,
    Image_17,
    Image_18,
    Image_19,
    Image_20,
    Image_21,
    Image_22,
    Image_23,
    Image_24,
    Image_25,
    Image_26,
    Image_27,
    Image_28,
    Image_29,
    Image_30,
    Image_31,
    Image_32,
    Image_33,
    Image_34,
    Image_35,
    Image_36,
    Image_37,
    Image_38,
    Image_39,
    Image_40,
    Image_41,
    Image_42,
    Image_43,
    Image_44,
    Image_45,
    Image_46,
    Image_47,
];
