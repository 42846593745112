export enum GAMES_HISTORY_ACTIONS {
    INITIALIZE_GAMES_HISTORY = 'INITIALIZE_GAMES_HISTORY',
}

export interface InitializeGamesHistoryParams {
    ID: number;
    CrashHash: string;
    CrashSource: string;
    BustedAt: number;
    GameConfigs: any;
    CashOutResults: any;
    TimeStamp: string;
}

export const initializeGamesHistoryAction = (
    history: InitializeGamesHistoryParams[]
) => {
    return {
        type: GAMES_HISTORY_ACTIONS.INITIALIZE_GAMES_HISTORY as GAMES_HISTORY_ACTIONS.INITIALIZE_GAMES_HISTORY,
        history,
    };
};
