import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Preparing } from '../../spaceship/states/preparing';

const CoolDownContainer = styled.div`
    display: flex;
    align-self: center;
    margin-top: 60px;
`;

const GameCoolDown: FC = () => {
    const [countDown, setCountDown] = useState(6000);

    useEffect(() => {
        if (countDown <= 0) return;
        const interval = setInterval(() => {
            setCountDown(countDown - 100);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [countDown, setCountDown]);

    return (
        <CoolDownContainer>
            <Preparing countDown={(countDown / 1000).toFixed(1)} />
        </CoolDownContainer>
    );
};

export default GameCoolDown;
