import React from 'react';
import styled from 'styled-components';
import List from './List';
import { useMyBets } from './configs/store/state';

const StyledCurrentList = styled.div``;

const StyledScrollableTable = styled.div`
    height: calc(100vh - 172px);
    overflow: auto;
`;

const MyBetsList = () => {
    const myBets = useMyBets();
    return (
        <StyledCurrentList>
            <StyledScrollableTable>
                {myBets && <List items={myBets} />}
            </StyledScrollableTable>
        </StyledCurrentList>
    );
};

export default MyBetsList;
