import React, { FC } from 'react';
import styled from 'styled-components';

const StyledNavigation = styled.div`
    display: flex;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
`;

const Navigation: FC<{ className?: string }> = ({ children, className }) => {
    return (
        <StyledNavigation className={className}>{children}</StyledNavigation>
    );
};

export default Navigation;
