import { MyList } from '../../../../../constants/interfaces/MyList';
import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

export type MyBetsSelector = (state: any) => MyList[];

export const MyBetsSelectorContext = createContext<MyBetsSelector>(() => {
    throw new Error('Not implemented');
});

export function useMyBets(): MyList[] {
    return useSelector(useContext(MyBetsSelectorContext));
}
