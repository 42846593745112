import React, { FC, useState } from 'react';
import Emoji from '../../../components/Icons/Emoji';
import styled from 'styled-components';
import Picker, { IEmojiData } from 'emoji-picker-react';
import { rgba } from 'polished';

const EmojiArea = styled.div`
    margin-right: 8px;
    padding: 2px 0;
    display: flex;
    align-items: center;
    position: relative;
`;

const EmojiIcon = styled.div`
    color: ${props => rgba(props.theme.colors.white, 0.54)};
    width: 20px;
    height: 20px;
    cursor: pointer;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const Header = styled.div`
    padding: 0 14px 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-family: 'Mediator Narrow CAPS';
    text-transform: uppercase;
`;

const CloseIcon = styled.div`
    height: 28px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: ${props => props.theme.colors.greenyBlue};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.lightNavyBlue};
    }
`;

const EmojisContainer = styled.div`
    position: absolute;
    bottom: 25px;
    border-radius: 4px;
    background: ${props => props.theme.colors.sea};
    border: 1px solid ${props => props.theme.colors.greenyBlue};
    overflow: hidden;
    && > aside.emoji-picker-react {
        background: inherit;
        border: none;
        border-radius: unset;
        width: 250px;
        box-shadow: none;
        .content-wrapper .emoji-scroll-wrapper .emoji-group {
            &::before {
                background: ${props => props.theme.colors.sea};
                color: ${props => props.theme.colors.greenyBlue};
            }
        }

        input.emoji-search {
            background: ${props => props.theme.colors.greenyBlue};
            border: 1px solid ${props => props.theme.colors.lightNavyBlue};
        }
    }
`;

const ChatEmojis: FC<{ value: string; setValue(value: string): void }> = ({
    value,
    setValue,
}) => {
    const [openEmoji, setOpenEmoji] = useState(false);
    const handleSelectEmoji = (event: any, emojiObject: IEmojiData) => {
        setValue(value + emojiObject.emoji);
    };

    return (
        <EmojiArea>
            <EmojiIcon onClick={() => setOpenEmoji(!openEmoji)}>
                <Emoji />
            </EmojiIcon>
            {openEmoji && (
                <EmojisContainer>
                    <Header>
                        <Title>Emoji</Title>
                        <CloseIcon onClick={() => setOpenEmoji(false)}>
                            ×
                        </CloseIcon>
                    </Header>
                    <Picker onEmojiClick={handleSelectEmoji} />
                </EmojisContainer>
            )}
        </EmojiArea>
    );
};

export default ChatEmojis;
