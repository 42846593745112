import React, { useEffect } from 'react';
import { useErrorMessagesState } from './store/state';
import {
    InfoSnackbar,
    snackbarVariants,
} from '../../components/InfoSnackbar/InfoSnackbar';
import { useHideMessageService } from './store/services';
import styled from 'styled-components';

const SnackbarContainer = styled.div<{}>`
    position: absolute;
    width: 100%;
    height: 216px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    top: -195px;
`;

const HIDE_DURATION = 1000;

const ErrorMessages = () => {
    const messages = useErrorMessagesState();
    const hideMessage = useHideMessageService();
    useEffect(() => {
        const timer = setTimeout(() => {
            hideMessage();
        }, HIDE_DURATION);
        return () => {
            clearTimeout(timer);
        };
    }, [hideMessage, messages]);

    return (
        <SnackbarContainer>
            {messages.map((message, index) => {
                return (
                    <InfoSnackbar
                        key={index}
                        open={!!message.status}
                        variant={
                            message.status
                                ? message.status
                                : snackbarVariants.error
                        }
                        message={message.message}
                        keyword={message.keyword}
                        handleClose={() => hideMessage()}
                    />
                );
            })}
        </SnackbarContainer>
    );
};

export default ErrorMessages;
