import { createContext, useContext } from 'react';
import { GameStatus } from '../../../constants/interfaces/Game';
import { NextGame } from '../../../constants/interfaces/NextGame';
import { useSelector, shallowEqual } from 'react-redux';
import { ListUser } from '../../../constants/interfaces/List';
import { MyList } from '../../../constants/interfaces/MyList';
import { TopList } from '../../../constants/interfaces/TopList';
import { GameHistory } from '../../../constants/interfaces/GameHistory';
import { Profile } from '../../../constants/interfaces/Profile';

export interface GameConfigs {
    animate: boolean;
    backgroundMusic: boolean;
    voiceEffects: boolean;
}

export enum RegisterState {
    REGISTER_INITIATED = 'REGISTER_INITIATED',
    REGISTERED = 'REGISTERED',
    UNREGISTER_INITIATED = 'UNREGISTER_INITIATED',
    UNREGISTERED = 'UNREGISTERED',
    CASH_OUT_INITIATED = 'CASH_OUT_INITIATED',
    PENDING = 'PENDING',
}

export interface PanelState {
    PanelIndex: number;
    registerState: RegisterState;
    BuyIn?: number;
    AutoCashoutOn?: null | number;
    IsAutoBet?: boolean;
    BalanceType?: number;
    wonLastGame?: boolean;
}

export interface GameState {
    status: GameStatus;
    nextGames?: NextGame[];
    currentBets: ListUser[];
    gameHistory: GameHistory[];
    lastBets?: ListUser[];
    myBets?: MyList[];
    top: TopList;
    boards: PanelState[];
    configs: GameConfigs;
    profile: Profile;
}

export type GameStateSelector = (state: any) => GameState;

export const GameStateSelectorContext = createContext<GameStateSelector>(() => {
    throw new Error('Not implemented');
});

export function useGameState(): GameState {
    return useSelector(useContext(GameStateSelectorContext), shallowEqual);
}

export type GameStatusStateSelector = (state: any) => GameStatus;

export const GameStatusStateSelectorContext = createContext<
    GameStatusStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useGameStatusState(): GameStatus {
    return useSelector(useContext(GameStatusStateSelectorContext));
}

export type CurrentBetsSelector = (state: any) => ListUser[];

export const CurrentBetsSelectorContext = createContext<CurrentBetsSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useCurrentBets(): ListUser[] {
    return useSelector(useContext(CurrentBetsSelectorContext));
}

export type PreviousBetsSelector = (state: any) => ListUser[];

export const PreviousBetsSelectorContext = createContext<PreviousBetsSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function usePreviousBets(): ListUser[] {
    return useSelector(useContext(PreviousBetsSelectorContext));
}

export type GameConfigsSelector = (state: any) => GameConfigs;

export const GameConfigsSelectorContext = createContext<GameConfigsSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useGameConfigs(): GameConfigs {
    return useSelector(useContext(GameConfigsSelectorContext), shallowEqual);
}

export type PanelStateSelector = (state: any) => PanelState[];

export const PanelStateSelectorContext = createContext<PanelStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function usePanelState(): PanelState[] {
    return useSelector(useContext(PanelStateSelectorContext));
}

export type GameHistorySelector = (state: any) => GameHistory[];

export const GameHistorySelectorContext = createContext<GameHistorySelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useGameHistory(): GameHistory[] {
    return useSelector(useContext(GameHistorySelectorContext), shallowEqual);
}

export type NextGamesSelector = (state: any) => NextGame[];

export const NextGamesSelectorContext = createContext<NextGamesSelector>(() => {
    throw new Error('Not implemented');
});

export function useNextGames(): NextGame[] {
    return useSelector(useContext(NextGamesSelectorContext), shallowEqual);
}

export type BoardsSelector = (state: any) => PanelState[];

export const BoardsSelectorContext = createContext<BoardsSelector>(() => {
    throw new Error('Not implemented');
});

export function useBoards(): PanelState[] {
    return useSelector(useContext(BoardsSelectorContext), shallowEqual);
}
