import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Profile } from '../../../../constants/interfaces/Profile';

export type ProfileSelector = (state: any) => Profile;

export const ProfileSelectorContext = createContext<ProfileSelector>(() => {
    throw new Error('Not implemented');
});

export function useProfile(): Profile {
    return useSelector(useContext(ProfileSelectorContext));
}
