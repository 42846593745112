import {
    ReceiveMessageParams,
    SendMessageParams,
    UpdateMessageLikeParams,
} from './services';

export const TOGGLE_OPEN_CHAT = 'TOGGLE_OPEN_CHAT';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE';
export const RECEIVE_SYSTEM_CHAT_MESSAGE = 'RECEIVE_SYSTEM_CHAT_MESSAGE';
export const LIKE_CHAT_MESSAGE = 'LIKE_CHAT_MESSAGE';
export const UPDATE_CHAT_MESSAGE_LIKES = 'UPDATE_CHAT_MESSAGE_LIKES';

export function toggleOpenChatAction(open: boolean) {
    return {
        type: TOGGLE_OPEN_CHAT,
        open,
    };
}

export function sendChatMessageAction(payload: SendMessageParams) {
    return {
        type: SEND_CHAT_MESSAGE,
        payload,
    };
}

export function receiveChatMessageAction(message: ReceiveMessageParams) {
    return {
        type: RECEIVE_CHAT_MESSAGE,
        message,
    };
}

export function receiveSystemChatMessageAction(
    systemMessage: ReceiveMessageParams
) {
    return {
        type: RECEIVE_SYSTEM_CHAT_MESSAGE,
        systemMessage,
    };
}

export function likeChatMessageAction(MessageId: number) {
    return {
        type: LIKE_CHAT_MESSAGE,
        MessageId,
    };
}

export function updateChatMessageLikesAction(
    messageLike: UpdateMessageLikeParams
) {
    return {
        type: UPDATE_CHAT_MESSAGE_LIKES,
        messageLike,
    };
}
