import React from 'react';
import styled from 'styled-components';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import Content from './layout/Content';
import GameContainer from './features/game';
import SidebarList from './features/topBoards';
import OddsList from './features/oddsList';
import ActionPanel from './features/actionPanel';
import useBackgroundsMusic from './components/BackgroundMusic';
import BackgroundContainer from './components/Background';
import { AudioPlayer } from './features/audioPlayer';
import Title from './features/title';
import Chat from './features/chat';

const AppContainer = styled.div`
    height: 100%;
    display: flex;
    color: white;
    overflow: auto;
`;

const SectionsContainer = styled.div`
    width: 100%;
    display: flex;
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    flex: 80;
`;

const BodyWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const StyledBody = styled.div`
    display: flex;
    flex: 1;
    @media (max-width: ${1000}px) {
        flex-direction: column;
    }
`;

function App() {
    useBackgroundsMusic();

    return (
        <AppContainer>
            <Title />
            <BackgroundContainer />
            <AudioPlayer />
            <SectionsContainer>
                <MainContainer>
                    <Header />
                    <BodyWrapper>
                        <OddsList />
                        <StyledBody>
                            <Sidebar>
                                <SidebarList />
                            </Sidebar>
                            <Content>
                                <GameContainer />
                                <ActionPanel />
                            </Content>
                        </StyledBody>
                    </BodyWrapper>
                </MainContainer>
                <Chat />
            </SectionsContainer>
        </AppContainer>
    );
}

export default App;
