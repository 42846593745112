import { MULTIPLIER_COLORS, MULTIPLIER_RANGE } from './configs';

export const getRGBColor = (n: number[]) => {
    let t = 'rgba(';
    for (let i = 0; i < 3; i++)
        t += parseInt(n[i].toString()) + (i === 2 ? ', 1)' : ',');
    return t;
};

export const generateMultiplierColor = (n: number) => {
    let i = [];
    let t = getMultiplierIndex(n);
    let r = MULTIPLIER_COLORS[t];

    if (t === MULTIPLIER_COLORS.length - 1) {
        i = r;
    } else {
        let u = MULTIPLIER_COLORS[t + 1],
            f = n - MULTIPLIER_RANGE[t][0],
            e = MULTIPLIER_RANGE[t][1] - MULTIPLIER_RANGE[t][0];
        for (let n = 0; n < 3; n++) {
            let t = u[n] - r[n],
                o = r[n] + (t * f) / e;
            i.push(o);
        }
    }
    return i;
};

const getMultiplierIndex = (n: number) => {
    let t = 0;
    return (
        MULTIPLIER_RANGE.forEach((i: any, r: any) => {
            i[0] <= n && n <= i[1] && (t = r);
        }),
        t
    );
};

export const getMultiplierRGBColor = (n: number) => {
    let i = generateMultiplierColor(n);
    return getRGBColor(i);
};
