import React, { FC } from 'react';
import styled from 'styled-components';

const StyledRow = styled.tr``;

export const TableHeaderRow: FC<{
    className?: string;
    innerRef?: any;
}> = ({ children, className }) => {
    return <StyledRow className={className}>{children}</StyledRow>;
};
