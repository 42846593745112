import { Howl } from 'howler';
const FlyingSound = require('../../assets/sounds/baloonflying.mp3');
const ExplosionSound = require('../../assets/sounds/explosion.mp3');
const ButtonSound = require('../../assets/sounds/button.mp3');
const BetSound = require('../../assets/sounds/bet.mp3');
const WinSound = require('../../assets/sounds/cashout.mp3');
const BackgroundMusic = require('../../assets/sounds/background.mp3');
const BirdsSound = require('../../assets/sounds/birds.mp3');

export class SoundPlayer {
    flyingSound: Howl;
    explosionSound: Howl;
    buttonSound: Howl;
    betSound: Howl;
    winSound: Howl;
    backgroundMusic: Howl;
    birdsSound: Howl;
    constructor() {
        this.flyingSound = new Howl({
            src: [FlyingSound],
            volume: 0.1,
            loop: true,
        });
        this.explosionSound = new Howl({
            src: [ExplosionSound],
            volume: 0.4,
        });
        this.buttonSound = new Howl({
            src: [ButtonSound],
            volume: 0.4,
        });
        this.betSound = new Howl({
            src: [BetSound],
            volume: 0.4,
        });
        this.winSound = new Howl({
            src: [WinSound],
            volume: 0.4,
        });
        this.backgroundMusic = new Howl({
            src: [BackgroundMusic],
            volume: 0.1,
            loop: true,
        });
        this.birdsSound = new Howl({
            src: [BirdsSound],
            volume: 0.1,
            loop: true,
        });
    }

    playFlyingSound() {
        this.flyingSound.play();
    }

    stopFlyingSound() {
        this.flyingSound.stop();
    }

    playExplosionSound() {
        this.explosionSound.play();
    }

    stopExplosionSound() {
        this.explosionSound.stop();
    }

    playButtonSound() {
        this.stopButtonSound();
        this.buttonSound.play();
    }

    stopButtonSound() {
        this.buttonSound.stop();
    }

    playBetSound() {
        this.stopBetSound();
        this.betSound.play();
    }

    stopBetSound() {
        this.betSound.stop();
    }

    playWinSound() {
        this.stopWinSound();
        this.winSound.play();
    }

    stopWinSound() {
        this.winSound.stop();
    }

    playBackgroundMusic() {
        this.stopBackgroundMusic();
        this.backgroundMusic.play();
    }

    stopBackgroundMusic() {
        this.backgroundMusic.stop();
    }

    playBirdsSound() {
        this.stopBirdsSound();
        this.birdsSound.play();
    }

    stopBirdsSound() {
        this.birdsSound.stop();
    }
}
