import { KEYWORDS } from '../../config/language/keywords';

export enum ACTION_PANEL_NAVIGATION {
    MANUAL = 0,
    AUTO = 1,
}

export const ActionPanelNavigationItems = [
    { text: KEYWORDS.MANUAL_BET_PANEL, index: ACTION_PANEL_NAVIGATION.MANUAL },
    { text: KEYWORDS.AUTOMATIC_BET_PANEL, index: ACTION_PANEL_NAVIGATION.AUTO },
];
