import React, { FC } from 'react';
import styled from 'styled-components';
import { Bullet, Stick, Switch } from './ToggleInput';
import { usePlayButtonSoundService } from '../../features/audioPlayer/store/services';
import { useGameConfigs } from '../../config/store/state/app.state';

const Container = styled.div``;

const CustomStick = styled(Stick)<{ active: boolean }>`
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.bgMarine}`
            : `${props.theme.colors.bgMarine}`};
`;
const CustomBullet = styled(Bullet)<{ active: boolean }>`
    background-color: ${props =>
        props.active
            ? `${props.theme.colors.wheat}`
            : props.theme.colors.paleRed};
`;

const CustomToggleInput: FC<{
    active: boolean;
    onChange?(): void;
    disabled?: boolean;
}> = ({ active, onChange, disabled }) => {
    const playButton = usePlayButtonSoundService();
    const voiceEffects = useGameConfigs().voiceEffects;

    const handleClick = () => {
        if (disabled) return;
        if (voiceEffects) {
            playButton();
        }

        if (onChange) {
            onChange();
        }
    };

    return (
        <Container>
            <Switch active={active} onClick={handleClick} disabled={disabled}>
                <CustomStick animate active={active} />
                <CustomBullet animate active={active} />
            </Switch>
        </Container>
    );
};

export default CustomToggleInput;
