export enum KEYWORDS {
    ALL_BETS = 'ALL_BETS',
    USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
    ANIMATIONS = 'ANIMATIONS',
    AUTOMATIC_BET_PANEL = 'AUTOMATIC_BET_PANEL',
    MANUAL_BET_PANEL = 'MANUAL_BET_PANEL',
    AUTOMATIC_BETTING = 'AUTOMATIC_BETTING',
    AUTOMATIC_CASH_OUT = 'AUTOMATIC_CASH_OUT',
    AUTO_CASH_OUT = 'AUTO_CASH_OUT',
    USER_BALANCE = 'USER_BALANCE',
    USER_BALANCE_ABBR = 'BalanceShort',
    ROUND = 'ROUND',
    RESULT_HASH_CODE = 'RESULT_HASH_CODE',
    CHECK_RESULT = 'CHECK_RESULT',
    USER_SUCCESSFULLY_REGISTERED = 'USER_SUCCESSFULLY_REGISTERED',
    USER_SUCCESSFULLY_UNREGISTERED = 'USER_SUCCESSFULLY_UNREGISTERED',
    USER_SUCCESSFULLY_CASHED_OUT = 'USER_SUCCESSFULLY_CASHED_OUT',
    WAITING_NEXT_ROUND = 'WAITING_NEXT_ROUND',
    NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
    REGISTER = 'REGISTER',
    CASH_OUT = 'CASH_OUT',
    INITIAL_BET = 'INITIAL_BET',
    BET = 'BET',
    CANCEL_REGISTER = 'CANCEL_REGISTER',
    PREVIOUS_GAME = 'PREVIOUS_GAME',
    PLAYER = 'PLAYER',
    WINNING_AMOUNT = 'WINNING_AMOUNT',
    BUY_IN = 'BUY_IN',
    STOP_WHEN = 'STOP_WHEN',
    IF_LOST = 'IF_LOST',
    IF_WON = 'IF_WON',
    CHAT = 'CHAT',
    SOUND = 'SOUND',
    MUSIC = 'MUSIC',
    TAKING_BETS_IN_PROGRESS = 'TAKING_BETS_IN_PROGRESS',
    GAME_IS_STARTING = 'GAME_IS_STARTING',
    CRUSHED_AT = 'CRUSHED_AT',
    KEY = 'KEY',
    HASH_CODE = 'HASH_CODE',
    CHECK_FAIR_QUESTION = 'CHECK_FAIR_QUESTION',
    CHECK_FAIR_ANSWER = 'CHECK_FAIR_ANSWER',
    CHECK_GAME_KEY_QUESTION = 'CHECK_GAME_KEY_QUESTION',
    CHECK_GAME_KEY_ANSWER = 'CHECK_GAME_KEY_ANSWER',
    CHECK_HASH_QUESTION = 'CHECK_HASH_QUESTION',
    CHECK_HASH_ANSWER = 'CHECK_HASH_ANSWER',
    EXAMPLE = 'EXAMPLE',
    EXAMPLE_RULE = 'EXAMPLE_RULE',
    TIME = 'TIME',
    BETS = 'BETS',
    MY = 'MY',
    RATINGS = 'RATINGS',
    WINNINGS_ON_MULTIPLIER = 'WINNINGS_ON_MULTIPLIER',
    TOP_MULTIPLIER = 'TOP_MULTIPLIER',
    TOP_WINNINGS = 'TOP_WINNINGS',
    MULTIPLIER_WINNINGS = 'MULTIPLIER_WINNINGS',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    ENTER_TEXT = 'ENTER_TEXT',
    SEND_MESSAGE = 'SEND_MESSAGE',
    GAME_RULES = 'GAME_RULES',
    RULE_1 = 'RULE_1',
    RULE_2 = 'RULE_2',
    RULE_3 = 'RULE_3',
    RULE_4 = 'RULE_4',
    RULE_5 = 'RULE_5',
    RULE_6 = 'RULE_6',
    RULE_7 = 'RULE_7',
}
