import React, { FC } from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
    flex: 80;
    transition: all 0.5s;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: ${1000}px) {
        display: block;
    }
`;

const Content: FC = ({ children }) => {
    return <StyledContent>{children}</StyledContent>;
};

export default Content;
