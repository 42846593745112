import React, { FC } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import BetsModalTable from './BetsModalTable';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';

const Container = styled.div`
    background: ${props => props.theme.colors.marine};
    color: #cccccc;
    font-family: 'Mediator Narrow CAPS';
    border-radius: 10px;
    box-shadow: none;
    width: 545px;
    padding: 16px;
    box-sizing: border-box;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
`;

const Title = styled.div`
    color: ${props => props.theme.colors.wheat};
    font-size: 17px;
    margin-left: 10px;
`;

const Body = styled.div`
    display: block;
    text-align: left;
`;

const SectionTitle = styled.div`
    font-size: 16px;
    margin-bottom: 8px;
`;

const Section = styled.div`
    margin-bottom: 16px;
`;

const SectionBody = styled.div`
    display: flex;
    align-items: center;
`;

const TableWrapper = styled.div`
    flex: 1;
`;

const SectionValue = styled.div`
    height: 31px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    line-height: 31px;
    padding: 0 8px;
`;

const BetsModal: FC<{
    gameId?: number;
    hashCode?: string;
    hashKey?: string;
    bustedAt?: number;
    closeModal(): void;
}> = ({ closeModal, gameId, hashCode, hashKey, bustedAt }) => {
    const translate = useTranslate();

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <Header>
                    <Title>
                        {translate(KEYWORDS.ROUND)} {gameId}
                    </Title>
                </Header>
                <Body>
                    <Section>
                        <SectionTitle>
                            {translate(KEYWORDS.RESULT_HASH_CODE)}
                        </SectionTitle>
                        <SectionBody>
                            <SectionValue>{hashCode}</SectionValue>
                        </SectionBody>
                    </Section>
                    <Section>
                        <SectionTitle>
                            {translate(KEYWORDS.CHECK_RESULT)}
                        </SectionTitle>
                        <SectionBody>
                            <TableWrapper>
                                <BetsModalTable
                                    hashKey={hashKey}
                                    bustedAt={bustedAt}
                                />
                            </TableWrapper>
                        </SectionBody>
                    </Section>
                </Body>
            </Container>
        </Modal>
    );
};

export default BetsModal;
