import React, { FC } from 'react';
import styled from 'styled-components';
import RenderMyBetsTable from './components/RenderMyBetsTable';
import { MyList } from '../../../constants/interfaces/MyList';

const MyBetsContainer = styled.div``;

const List: FC<{ items: MyList[] }> = ({ items }) => {
    return (
        <MyBetsContainer>
            <RenderMyBetsTable items={items} />
        </MyBetsContainer>
    );
};

export default List;
