import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import { TableHead } from '../../../components/Table/TableHead';
import { TableHeaderRow } from '../../../components/Table/TableHeaderRow';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell';
import styled from 'styled-components';
import PreviousList from './PreviousList';
import CurrentList from './CurrentList';
import BetsInfoPanel from './components/BetsInfoPanel';
import {
    useCurrentBets,
    usePreviousBets,
} from '../../../config/store/state/app.state';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';

const StyledCurrentList = styled.div``;

const StyledScrollableTable = styled.div`
    height: calc(100vh - 250px);
    overflow: auto;
`;

const BetsList = () => {
    const [showLastBets, setShowLastBets] = useState();
    const translate = useTranslate();
    const currentBets = useCurrentBets();
    const previousList = usePreviousBets();

    return (
        <StyledCurrentList>
            <BetsInfoPanel
                showLastBets={showLastBets}
                setShowLastBets={setShowLastBets}
                totalBets={
                    showLastBets ? previousList.length : currentBets.length
                }
            />
            <Table>
                <TableHead>
                    <TableHeaderRow>
                        <TableHeaderCell>
                            {translate(KEYWORDS.PLAYER)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.BUY_IN)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.CRUSHED_AT)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.WINNING_AMOUNT)}
                        </TableHeaderCell>
                    </TableHeaderRow>
                </TableHead>
            </Table>
            <StyledScrollableTable>
                {showLastBets ? (
                    <PreviousList items={previousList} />
                ) : (
                    <CurrentList items={currentBets} />
                )}
            </StyledScrollableTable>
        </StyledCurrentList>
    );
};

export default BetsList;
