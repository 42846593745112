import styled from 'styled-components';
import { TableCell } from '../../../../components/Table/TableCell';

export const NeutralFirstTableCell = styled(TableCell)`
    width: 30%;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0;
    text-align: left;
    background: rgb(3, 56, 82, 0.02);
`;

export const NeutralSecondTableCell = styled(TableCell)`
    width: 20%;
    background: rgb(3, 56, 82, 0.02);
`;

export const NeutralThirdTableCell = styled(TableCell)`
    width: 20%;
    background: rgb(3, 56, 82, 0.02);
`;

export const NeutralFourthTableCell = styled(TableCell)`
    width: 30%;
    padding-right: 10px;
    background: rgb(3, 56, 82, 0.02);
`;
