import React, { FC, useContext, useEffect } from 'react';
import { GameStatus } from '../../../constants/interfaces/Game';
import { Flying } from '../../spaceship/spaceship';
import styled from 'styled-components';
import { GameContext } from '../index';
import { SoundPlayer } from '../../soundPlayer/soundPlayer';
import { useGameConfigs } from '../../../config/store/state/app.state';

const RunningContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const GameRunning: FC<{ game: GameStatus }> = () => {
    const { gameWidth } = useContext(GameContext);
    const voiceEffects = useGameConfigs().voiceEffects;

    useEffect(() => {
        if (!voiceEffects) {
            return;
        }
        const sound = new SoundPlayer();
        sound.playFlyingSound();

        return () => {
            sound.stopFlyingSound();
        };
    }, [voiceEffects]);

    return (
        <RunningContainer>
            <Flying shipWidth={gameWidth / 1.62} />
        </RunningContainer>
    );
};

export default GameRunning;
