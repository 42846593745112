import React, { FC } from 'react';
import styled from 'styled-components';
import Multiplier from '../../../components/Multiplier/Multiplier';
import { getMultiplierRGBColor } from '../../betColors';
import { motion } from 'framer-motion';

const MultiplierContainer = styled(motion.div)`
    position: absolute;
    left: 100%;
    top: 20px;
    @media (max-width: ${425}px) {
        left: 75%;
        top: 30px;
    }
`;

const StyledMultiplier = styled(Multiplier)`
    width: 116px;
    height: 116px;
    font-size: 25px;
    border-radius: 100%;

    @media (max-width: ${1500}px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: ${1000}px), media (max-height: ${700}px) {
        width: 90px;
        height: 90px;
        font-size: 22px;
    }

    @media (max-width: ${500}px), media (max-height: ${500}px) {
        width: 80px;
        height: 80px;
        font-size: 20px;
    }

    @media (max-width: ${425}px) {
        width: 70px;
        height: 70px;
        font-size: 19px;
    }
`;

const GameMultiplier: FC<{ multiplier?: number | null }> = ({ multiplier }) => {
    if (!multiplier) return null;
    return (
        <MultiplierContainer
            initial={{ x: 200 }}
            animate={{ x: 0 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <StyledMultiplier
                multiplier={multiplier}
                color={getMultiplierRGBColor(multiplier)}
            />
        </MultiplierContainer>
    );
};

export default GameMultiplier;
