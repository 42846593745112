import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import UserAvatar from '../../../../components/UserAvatar';
import { ChatMessage } from '../../../../constants/interfaces/ChatMessage';
import Like from '../../../../components/Icons/Like';
import { useLikeChatMessageService } from '../../store/services';

const MessageItemContainer = styled.div`
    font-family: 'Mediator Narrow';
    margin-bottom: 24px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LikeMessage = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div<{ liked?: boolean }>`
    height: 11.26px;
    width: 10px;
    display: flex;
    color: ${props =>
        props.liked
            ? props.theme.colors.paleRed
            : rgba(props.theme.colors.white, 0.54)};
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const Counter = styled.div`
    font-size: 12px;
    margin-right: 4px;
    width: 5px;
    color: ${props => rgba(props.theme.colors.white, 0.54)};
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 100%;
`;

const Name = styled.div`
    margin-left: 8px;
    font-size: 14px;
    opacity: 0.5;
    font-weight: bold;
    color: ${props => rgba(props.theme.colors.white, 0.84)};
`;

const Text = styled.div`
    color: ${props => rgba(props.theme.colors.white, 0.84)};
    font-size: 12px;
    font-weight: 300;
    word-break: break-word;
`;

const MessageItem: FC<{ message: ChatMessage }> = ({ message }) => {
    const likeChatMessage = useLikeChatMessageService();

    const handleMessageLike = () => {
        if (!message.MessageId) return;
        likeChatMessage(message.MessageId);
    };

    return (
        <MessageItemContainer>
            <Header>
                <UserInfo>
                    <Avatar>
                        <UserAvatar size={24} />
                    </Avatar>
                    <Name>{message.Player.N}</Name>
                </UserInfo>
                <LikeMessage>
                    <Counter>
                        {message.LikeCount > 0 && message.LikeCount}
                    </Counter>
                    <IconWrapper
                        onClick={handleMessageLike}
                        liked={message.Liked}
                    >
                        <Like />
                    </IconWrapper>
                </LikeMessage>
            </Header>
            <Text>{message.Text}</Text>
        </MessageItemContainer>
    );
};

export default MessageItem;
