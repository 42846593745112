import React from 'react';
import ActionBoard from './ActionBoard';
import { useBoards } from '../../../../config/store/state/app.state';
import {
    useBoardAddedService,
    useBoardRemovedService,
} from '../../../../config/store/services';

const ActionBoardContainer = () => {
    const boards = useBoards();
    const handleAddBoard = useBoardAddedService();
    const handleRemoveBoard = useBoardRemovedService();

    const firstBoard = boards.filter(board => board.PanelIndex === 0)[0];
    const secondBoard = boards.filter(board => board.PanelIndex === 1)[0];
    const boardsLength = boards.filter(board => board.PanelIndex !== 2).length;

    return (
        <>
            {firstBoard && (
                <ActionBoard
                    activeBoards={boardsLength}
                    boardIndex={firstBoard.PanelIndex}
                    addBoard={() => handleAddBoard(1)}
                    removeBoard={() => handleRemoveBoard(0)}
                />
            )}
            {secondBoard && (
                <ActionBoard
                    activeBoards={boardsLength}
                    boardIndex={secondBoard.PanelIndex}
                    addBoard={() => handleAddBoard(0)}
                    removeBoard={() => handleRemoveBoard(1)}
                />
            )}
        </>
    );
};

export default ActionBoardContainer;
