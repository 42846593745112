import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { snackbarVariants } from '../../../components/InfoSnackbar/InfoSnackbar';

export interface ErrorMessagesState {
    status: snackbarVariants | null;
    message: string;
    keyword?: string;
}

export type ErrorMessagesStateSelector = (state: any) => ErrorMessagesState[];

export const ErrorMessagesStateSelectorContext = createContext<
    ErrorMessagesStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useErrorMessagesState(): ErrorMessagesState[] {
    return useSelector(useContext(ErrorMessagesStateSelectorContext));
}
