import React, { FC } from 'react';
import NumberInput from '../../../../../components/NumberInput/NumberInput';
import Button from '../../../../../components/Button/Button';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const InputContainer = styled.div`
    display: flex;
    flex: 40;
    margin-right: 8px;
`;

const BetButtonContainer = styled(motion.div)`
    display: flex;
    flex: 48;
    margin-right: 6px;
`;

const StyledButton = styled(Button)`
    font-size: 21px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        font-size: 16px;
    }
`;

const RegisterPanel: FC<{
    amount: number;
    setAmount(amount: number | null): void;
    handleRegister(): void;
    toggleBoard(): void;
    icon: any;
    minValue?: number;
    maxValue?: number;
}> = ({ amount, setAmount, handleRegister, minValue, maxValue }) => {
    const translate = useTranslate();

    return (
        <StyledContainer>
            <InputContainer>
                <NumberInput
                    value={amount}
                    onChange={e => {
                        setAmount(e);
                    }}
                    minValue={minValue}
                    maxValue={maxValue}
                />
            </InputContainer>
            <BetButtonContainer>
                <StyledButton
                    text={`${translate(KEYWORDS.BET)} (${amount.toFixed(2)}₾)`}
                    onClick={handleRegister}
                />
            </BetButtonContainer>
        </StyledContainer>
    );
};

export default RegisterPanel;
