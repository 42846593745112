import React, { useState } from 'react';
import TableNavigation from './components/TableNavigation';
import { LIST_INDEXES, SidebarNavigationItems } from './tools';
import styled from 'styled-components';
import BetsList from './bets';
import MyBetsList from './myBets';
import TopBetsList from './topBets';

const SidebarListContainer = styled.div``;
const SidebarListBody = styled.div``;

const SidebarList = () => {
    const [active, setActive] = useState(LIST_INDEXES.BETS);

    return (
        <SidebarListContainer>
            <TableNavigation
                items={SidebarNavigationItems}
                activeIndex={active}
                setActive={setActive}
            />
            <SidebarListBody>
                {active === LIST_INDEXES.BETS && <BetsList />}
                {active === LIST_INDEXES.MY_BETS && <MyBetsList />}
                {active === LIST_INDEXES.TOP && <TopBetsList />}
            </SidebarListBody>
        </SidebarListContainer>
    );
};

export default SidebarList;
