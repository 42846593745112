import React, { FC } from 'react';
import Button from '../../../../../components/Button/Button';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const BetButtonContainer = styled(motion.div)`
    display: flex;
    flex: 48;
    margin-right: 6px;
`;

const StyledButton = styled(Button)`
    background: ${props => props.theme.colors.paleRed};
`;

const UnregisterPanel: FC<{
    amount: number;
    handleUnregister(): void;
    disabled?: boolean;
}> = ({ amount, handleUnregister, disabled }) => {
    const translate = useTranslate();

    return (
        <StyledContainer>
            <BetButtonContainer
                initial={{ maxWidth: '50%' }}
                animate={{ maxWidth: '100%' }}
                transition={{ duration: 0.2 }}
            >
                <StyledButton
                    text={`${translate(
                        KEYWORDS.CANCEL_REGISTER
                    )} (${amount.toFixed(2)}₾)`}
                    onClick={handleUnregister}
                    disabled={disabled}
                />
            </BetButtonContainer>
        </StyledContainer>
    );
};

export default UnregisterPanel;
