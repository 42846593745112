import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BirdOne from '../../Icons/BirdOne';
import BirdTwo from '../../Icons/BirdTwo';

const BirdsContainer = styled.div`
    left: 35%;
    top: 166px;
    position: absolute;
    width: 60px;
    height: 89px;
    pointer-events: none;
    user-select: none;
`;

const BirdOneWrapper = styled(motion.div)`
    display: flex;
    width: 39px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
`;

const BirdTwoWrapper = styled(motion.div)`
    display: flex;
    width: 49px;
    height: 19px;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const BirdOneStaticWrapper = styled.div`
    display: flex;
    width: 39px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
`;

const BirdTwoStaticWrapper = styled.div`
    display: flex;
    width: 49px;
    height: 19px;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const BirdsGroupOne: FC<{ animate?: boolean }> = ({ animate }) => {
    if (animate) {
        return (
            <BirdsContainer>
                <BirdOneWrapper
                    initial={{ x: 0, y: 0 }}
                    animate={{
                        x: [1, 2, 1, 0, -1, -2, -1, 0],
                        y: [1, 2, 1, 0, -1, -2, -1, 0],
                        scale: [1, 0.9, 0.85, 1, 1.2, 1],
                    }}
                    transition={{ repeat: Infinity, duration: 1.5 }}
                >
                    <BirdOne />
                </BirdOneWrapper>
                <BirdTwoWrapper
                    initial={{ x: 0, y: 0 }}
                    animate={{
                        x: [1, 2, 1, 0, -1, -2, -1, 0],
                        y: [1, 2, 1, 0, -1, -2, -1, 0],
                        scale: [1, 0.9, 0.85, 1, 1.1, 1],
                    }}
                    transition={{ repeat: Infinity, duration: 1.3 }}
                >
                    <BirdTwo />
                </BirdTwoWrapper>
            </BirdsContainer>
        );
    } else {
        return (
            <BirdsContainer>
                <BirdOneStaticWrapper>
                    <BirdOne />
                </BirdOneStaticWrapper>
                <BirdTwoStaticWrapper>
                    <BirdTwo />
                </BirdTwoStaticWrapper>
            </BirdsContainer>
        );
    }
};

export default BirdsGroupOne;
