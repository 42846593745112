import { ErrorMessagesState } from './state';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export function displayMessage(error: ErrorMessagesState) {
    return {
        type: ADD_MESSAGE,
        error,
    };
}
export function hideMessage() {
    return {
        type: REMOVE_MESSAGE,
    };
}
