export enum GAME_STATE {
    INITIAL = 'INITIAL',
    RUNNING = 'RUNNING',
    COUNTDOWN = 'COUNTDOWN',
    CRUSHED = 'CRUSHED',
}

export interface GameStatus {
    id?: number;
    state?: GAME_STATE;
    multiplier?: number | null;
    hashKey?: string;
}
