import React, { FC } from 'react';
import styled from 'styled-components';
import { TOP_LIST_FILTER_INDEXES } from '../configs/tools';
import { TopWinnings } from '../../../../constants/interfaces/TopWinnings';
import WinningTableItem from '../components/WinningTableItem';

const Container = styled.div`
    border-top: 2px solid ${props => props.theme.colors.transparent3};
`;

const MultiplierCashOutList: FC<{
    items?: TopWinnings;
    selectedFilter: TOP_LIST_FILTER_INDEXES;
}> = ({ items, selectedFilter }) => {
    return (
        <Container>
            {items &&
                items[selectedFilter].map((game, index) => {
                    return <WinningTableItem key={index} game={game} />;
                })}
        </Container>
    );
};

export default MultiplierCashOutList;
