import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    toggleConfigAnimationAction,
    toggleConfigBackgroundMusicAction,
    toggleConfigVoiceAction,
} from './actions';

export const useToggleConfigAnimationService = () => {
    const dispatch = useDispatch();

    return useCallback(
        (enable: boolean) => {
            dispatch(toggleConfigAnimationAction(enable));
        },
        [dispatch]
    );
};

export const useToggleConfigBackgroundMusicService = () => {
    const dispatch = useDispatch();

    return useCallback(
        (enable: boolean) => {
            dispatch(toggleConfigBackgroundMusicAction(enable));
        },
        [dispatch]
    );
};

export const useToggleConfigVoiceService = () => {
    const dispatch = useDispatch();

    return useCallback(
        (enable: boolean) => {
            dispatch(toggleConfigVoiceAction(enable));
        },
        [dispatch]
    );
};
