export const english = {
    LANG_ACC_BANNED: '',
    ALL_BETS: '',
    USER_ALREADY_REGISTERED: '',
    ANIMATIONS: '',
    AUTOMATIC_BET_PANEL: '',
    MANUAL_BET_PANEL: '',
    AUTOMATIC_BETTING: '',
    AUTOMATIC_CASH_OUT: '',
    AUTO_CASH_OUT: '',
    USER_BALANCE: '',
    USER_BALANCE_ABBR: '',
    ROUND: '',
    RESULT_HASH_CODE: '',
    CHECK_RESULT: '',
    USER_SUCCESSFULLY_REGISTERED: '',
    USER_SUCCESSFULLY_UNREGISTERED: '',
    USER_SUCCESSFULLY_CASHED_OUT: '',
    WAITING_NEXT_ROUND: '',
    NOT_ENOUGH_BALANCE: '',
    REGISTER: '',
    CASH_OUT: '',
    INITIAL_BET: '',
    BET: '',
    CANCEL_REGISTER: '',
    PREVIOUS_GAME: '',
    PLAYER: '',
    STOP_WHEN: '',
    WINNING_AMOUNT: '',
    BUY_IN: '',
    IF_LOST: '',
    IF_WON: '',
    CHAT: '',
    SOUND: '',
    MUSIC: '',
    TAKING_BETS_IN_PROGRESS: '',
    CRUSHED_AT: '',
    GAME_IS_STARTING: '',
    KEY: '',
    HASH_CODE: '',
    KEY_FOR_GAME: '',
    CHECK_FAIR_QUESTION: '',
    CHECK_FAIR_ANSWER: '',
    CHECK_GAME_KEY_QUESTION: '',
    CHECK_GAME_KEY_ANSWER: '',
    CHECK_HASH_QUESTION: '',
    CHECK_HASH_ANSWER: '',
    EXAMPLE: '',
    EXAMPLE_RULE: '',
    TIME: '',
    BETS: '',
    MY: '',
    RATINGS: '',
    WINNINGS_ON_MULTIPLIER: '',
    TOP_MULTIPLIER: '',
    TOP_WINNINGS: '',
    MULTIPLIER_WINNINGS: '',
    DAY: '',
    MONTH: '',
    YEAR: '',
    ENTER_TEXT: '',
    SEND_MESSAGE: '',
    GAME_RULES: '',
    RULE_1: '',
    RULE_2: '',
    RULE_3: '',
    RULE_4: '',
    RULE_5: '',
    RULE_6: '',
    RULE_7: '',
};
