import React, { FC } from 'react';
import { ListStatus, ListUser } from '../../../../constants/interfaces/List';
import { TableRow } from '../../../../components/Table/TableRow';
import {
    SuccessFirstTableCell,
    SuccessFourthTableCell,
    SuccessSecondTableCell,
    SuccessThirdTableCell,
} from '../../components/BetsTable/TableSuccessCell';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from '../../components/BetsTable/NeutralTableCell';

const RenderMyBetsTableRows: FC<{ items: Partial<ListUser>[] }> = ({
    items,
}): any => {
    return (
        items &&
        items.map((game, index) => {
            switch (game.status) {
                case ListStatus.WON: {
                    return (
                        <TableRow key={JSON.stringify(game + `${index}`)}>
                            <SuccessFirstTableCell>
                                {game.time}
                            </SuccessFirstTableCell>
                            <SuccessSecondTableCell>
                                {game.bet} ₾
                            </SuccessSecondTableCell>
                            <SuccessThirdTableCell>
                                {game.odds ? game.odds : '-'}X
                            </SuccessThirdTableCell>
                            <SuccessFourthTableCell>
                                {game.profit ? `${game.profit} ₾` : '-'}
                            </SuccessFourthTableCell>
                        </TableRow>
                    );
                }
                default: {
                    return (
                        <TableRow key={JSON.stringify(game + `${index}`)}>
                            <NeutralFirstTableCell>
                                {game.time}
                            </NeutralFirstTableCell>
                            <NeutralSecondTableCell>
                                {game.bet} ₾
                            </NeutralSecondTableCell>
                            <NeutralThirdTableCell>-</NeutralThirdTableCell>
                            <NeutralFourthTableCell>
                                {game.profit ? `${game.profit} ₾` : '-'}
                            </NeutralFourthTableCell>
                        </TableRow>
                    );
                }
            }
        })
    );
};

export default RenderMyBetsTableRows;
