import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { rgba } from 'polished';
import { useSendMessageService } from '../../store/services';
import ChatEmojis from '../../ChatEmojis/ChatEmojis';
// import ChatGifs from '../../ChatGifs/ChatGifs';

const ChatInputContainer = styled.div`
    padding: 16px;
    font-family: 'Mediator Narrow';
    margin-bottom: 24px;
`;

const Textarea = styled.textarea`
    border: solid 1px
        ${props => rgba(props.theme.colors.charcoalGreyFour, 0.15)};
    border-radius: 4px;
    background: ${props => rgba(props.theme.colors.white, 0.15)};
    width: 100%;
    font-size: 13px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 8px;
    &::placeholder {
        color: ${props => props.theme.colors.marine};
    }
    &:focus {
        outline: 0;
    }
`;

const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const Button = styled.div`
    width: 117px;
    height: 32px;
    background: ${props => props.theme.colors.darkPeach};
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const ChatTools = styled.div`
    display: flex;
`;

const ChatInput = () => {
    const translate = useTranslate();
    const messageService = useSendMessageService();
    const [value, setValue] = useState('');

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const handleMessageSend = () => {
        if (value.length < 1) return;
        messageService({ Text: value });
        setValue('');
    };

    return (
        <ChatInputContainer>
            <ChatTools>
                <ChatEmojis value={value} setValue={setValue} />
                {/*<ChatGifs value={value} setValue={setValue} />*/}
            </ChatTools>
            <Textarea
                value={value}
                onChange={handleChange}
                placeholder={translate(KEYWORDS.ENTER_TEXT)}
            />
            <ButtonArea>
                <Button onClick={handleMessageSend}>
                    {translate(KEYWORDS.SEND_MESSAGE)}
                </Button>
            </ButtonArea>
        </ChatInputContainer>
    );
};

export default ChatInput;
