import { georgian as ka } from './dictionary/georgian';
import { english as en } from './dictionary/english';

export enum LANGUAGES {
    ka = 'ka',
    en = 'en',
}

export const dictionaryList: any = { ka, en };

export const getLanguage = (): LANGUAGES => {
    const url = window.location.pathname;
    const splitUrl = url.split('/');
    return splitUrl[1] as LANGUAGES;
};
