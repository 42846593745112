import { Player } from '../../../../../constants/interfaces/Player';

export enum BETS_ACTIONS {
    INITIALIZE_CURRENT_BETS = 'INITIALIZE_CURRENT_BETS',
    INITIALIZE_LAST_BETS = 'INITIALIZE_LAST_BETS',
}

export interface InitializeBetsParams {
    BuyIn: number;
    AutoCashOutOn: number;
    IsAutoBet: boolean;
    PanelIndex: number;
    Player: Player;
    BalanceType: number;
    Multiplier?: number;
}

export const initializeLastBetsAction = (
    lastBets: InitializeBetsParams[],
    lastBetsCashedOut: InitializeBetsParams[]
) => {
    return {
        type: BETS_ACTIONS.INITIALIZE_LAST_BETS as BETS_ACTIONS.INITIALIZE_LAST_BETS,
        lastBets,
        lastBetsCashedOut,
    };
};

export const initializeCurrentBetsAction = (
    currentBets: InitializeBetsParams[],
    currentBetsCashedOut: InitializeBetsParams[]
) => {
    return {
        type: BETS_ACTIONS.INITIALIZE_CURRENT_BETS as BETS_ACTIONS.INITIALIZE_CURRENT_BETS,
        currentBets,
        currentBetsCashedOut,
    };
};
