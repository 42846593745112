import { ADD_MESSAGE, REMOVE_MESSAGE } from './actions';
import { ErrorMessagesState } from './state';

const initialState: ErrorMessagesState[] = [];

const messageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_MESSAGE: {
            const updatedState = [...state];
            if (updatedState.length > 2) {
                updatedState.shift();
                updatedState.push({
                    status: action.error.status,
                    message: action.error.message,
                    keyword: action.error.keyword,
                });
            } else {
                updatedState.push({
                    status: action.error.status,
                    message: action.error.message,
                    keyword: action.error.keyword,
                });
            }
            return updatedState;
        }
        case REMOVE_MESSAGE: {
            const updatedState = [...state];
            updatedState.shift();
            return updatedState;
        }

        default:
            return state;
    }
};

export default messageReducer;
