import React, { FC, useState } from 'react';
import BetsModal from '../../../../components/BetsModal';
import Security from '../../../../components/Icons/Security';
import styled from 'styled-components';
import { TopMultipliersItem } from '../../../../constants/interfaces/TopMultipliers';

const GameHistoryContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
`;

const IconContainer = styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const IconWrapper = styled.div`
    height: 14px;
    width: 13px;
    display: flex;
`;
const CheckGameHistory: FC<{ game: TopMultipliersItem }> = ({ game }) => {
    const [open, setOpen] = useState(false);

    return (
        <GameHistoryContainer>
            {open && (
                <BetsModal
                    closeModal={() => setOpen(false)}
                    bustedAt={game.BustedAt}
                    gameId={game.ID}
                    hashCode={game.CrashHash}
                    hashKey={game.CrashSource}
                />
            )}
            <IconContainer onClick={() => setOpen(true)}>
                <IconWrapper>
                    <Security />
                </IconWrapper>
            </IconContainer>
        </GameHistoryContainer>
    );
};

export default CheckGameHistory;
