export enum NEXT_GAMES_ACTIONS {
    INITIALIZE_NEXT_GAMES = 'INITIALIZE_NEXT_GAMES',
}

export interface InitializeNextGamesParams {
    GameID: number;
    Hash: string;
}

export const initializeNextGamesAction = (
    games: InitializeNextGamesParams[]
) => {
    return {
        type: NEXT_GAMES_ACTIONS.INITIALIZE_NEXT_GAMES as NEXT_GAMES_ACTIONS.INITIALIZE_NEXT_GAMES,
        games,
    };
};
