import React, { FC } from 'react';
import Table from '../../../components/Table/Table';
import { TableBody } from '../../../components/Table/TableBody';
import RenderTableRows from './components/RenderTableRows';
import { ListUser } from '../../../constants/interfaces/List';

const PreviousList: FC<{ items: ListUser[] }> = ({ items }) => {
    return (
        <Table>
            <TableBody>
                <RenderTableRows items={items} />
            </TableBody>
        </Table>
    );
};

export default PreviousList;
